import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import TabPanel from 'components/StyledComponents/TabPanel';
import StyledTab from 'components/StyledComponents/StyledTab';
import StyledTabs from 'components/StyledComponents/StyledTabs';
import ThemeButton from 'components/atoms/ThemeButton';
import GenericDialog from 'components/GenericDialog';
import ItemHeader from '../atoms/ItemHeader';
import MyQuotes from './components/MyQuotes';
import useInsuranceQuotes from './hooks/useInsuranceQuotes';
import { CircularProgress } from '@material-ui/core';
import * as S from './styles';

const InsuranceProposalComponent = ({ params }) => {
  const { currentDealer, proposalId } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
    proposalId: state.proposalStore.currentProposal.proposalId,
  }));

  const {
    startInsuranceQuoteMutate,
    startInsuranceQuoteIsLoading,
    openModalFandi,
    setOpenModalFandi,
  } = useInsuranceQuotes();

  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (_, newTab) => {
    setCurrentTab(newTab);
  };

  const quiverPW = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'QUIVER'
  )?.externalIdentification;

  const onCloseModalFandi = () => {
    setOpenModalFandi(false);
  };

  const handleClickOpenFandi = () => {
    startInsuranceQuoteMutate({
      dealerId: currentDealer.id,
      proposalId,
    });
  };

  const Model = () => {
    return (
      <GenericDialog
        open={openModalFandi}
        onClose={onCloseModalFandi}
        title={'Cotar Seguros'}
        maxWidth="md"
        OnCloseButton
      >
        <S.ContainerIframeModal>
          <iframe
            src={`https://www.corretor-online.com.br/canalcliente/index.htm?Pw=${quiverPW}&${params}`}
            width={'100%'}
            style={{ height: 'calc(100vh - 130px)' }}
          />
        </S.ContainerIframeModal>
      </GenericDialog>
    );
  };

  return (
    <>
      <ItemHeader>Seguro</ItemHeader>
      <StyledTabs value={currentTab} onChange={handleChangeTab}>
        <StyledTab value={0} label="Simulação" />
        <StyledTab value={1} label="Minhas cotações" />
      </StyledTabs>

      <TabPanel value={currentTab} index={0} key={'Simulação'}>
        {currentTab === 0 && (
          <S.ContainerButtonQuote>
            <ThemeButton onClick={handleClickOpenFandi}>
              {startInsuranceQuoteIsLoading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                'Cotar Seguros'
              )}
            </ThemeButton>
          </S.ContainerButtonQuote>
        )}
      </TabPanel>

      <TabPanel value={currentTab} index={1} key={'Minhas cotações'}>
        {currentTab === 1 && <MyQuotes />}
      </TabPanel>
      {openModalFandi && <Model />}
    </>
  );
};

export default memo(InsuranceProposalComponent);
