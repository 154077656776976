import React, { createContext, useState, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import socketClient from 'socket.io-client';
import EnvVars from 'helpers/envVars';
import { getToken } from 'auth';

const SocketContext = createContext({});
const socketDomain = EnvVars('API_SOCKET');

const SocketProvider = ({ children }) => {
  const { userStore, dealerStore, proposalStore, callCenter } = useSelector(
    ({ proposalStore, userStore, dealerStore, callCenter }) => ({
      proposalStore,
      dealerStore,
      userStore,
      callCenter,
    })
  );

  const [socket, setSocketClient] = useState(null);
  const [connectedStatus, setConnectedStatus] = useState(false);

  const loginSocketServer = ({ dealerId = null, groupId = null }) => {
    const mobiUserId =
      userStore?.userSite?.id || parseInt(userStore.userSite?.id);
    socket.emit('user:login', {
      email: userStore.email,
      username: userStore.username,
      firstName: userStore.firstName,
      mobiUserId,
      groupId,
      dealerId,
      currentProposalId: proposalStore.currentProposal?.proposalId,
      userType: userStore.userType,
    });
  };

  const connectRetry = () => {
    const dealerId =
      proposalStore.currentProposal?.dealerId || dealerStore.currentDealer.id;
    const groupId = callCenter.currentGroup.id;
    if (dealerId || groupId) {
      loginSocketServer({ dealerId, groupId });
    }
  };

  const setCurrentProposalData = proposalData => {
    socket.emit('user:setCurrentProposalData', {
      proposalData,
    });
  };

  const setCurrentCampaignId = campaignId => {
    socket.emit('user:setCurrentCampaignId', {
      campaignId,
    });
  };

  //CONNECT_SOCKET_SERVER
  useEffect(() => {
    setSocketClient(
      socketClient(socketDomain, {
        path: '/socket',
        auth: {
          token: `Bearer ${getToken()}`,
        },
        transports: ['websocket'],
        reconnectionDelay: 500,
        // upgrade: false,
        // rememberUpgrade: true,
      })
    );
  }, []);

  //SOCKET_EVENTS_RETRY
  useEffect(() => {
    if (socket) {
      socket.on('connect', () => {
        //ON_RECONNET_IF_SERVER_DOWN
        connectRetry();
        //SET_CONNECTED_STATUS
        setConnectedStatus(socket.connected);
      });
      socket.on('disconnect', function() {
        //Your Code Here
        setConnectedStatus(socket.connected);
      });
      return () => {
        socket.off('connect');
      };
    }
  }, [dealerStore, userStore, socket, proposalStore]);

  const contextValues = {
    socket,
    loginSocketServer,
    setCurrentProposalData,
    setCurrentCampaignId,
    connectedStatus,
  };

  return (
    <SocketContext.Provider value={contextValues}>
      {children}
    </SocketContext.Provider>
  );
};

function useSocketContext() {
  const context = useContext(SocketContext);
  return context;
}

export { SocketProvider, useSocketContext };
