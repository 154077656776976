import InsureQuote, {
  GetAllInsuranceQuoteByProposalIdQ,
} from 'services/insureQuoteServices';
import useCustomMutation, { HandleReturn } from 'hooks/useCustomMutation';
import useCustomQuery from 'hooks/useCustomQuery';

export function useGetQuotesByProposalId(
  params: GetAllInsuranceQuoteByProposalIdQ
) {
  return useCustomQuery({
    key: ['get_all_quotes_by_proposal_id', String(params.proposalId)],
    request: InsureQuote.getAllInsuranceQuoteByProposalId,
    params,
    enabled: false,
  });
}

export function useStartInsuranceQuote(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'start_insurance_quote',
    request: InsureQuote.startInsuranceQuote,
    handleReturn,
  });
}

export function useSelectInsuranceQuote(handleReturn?: HandleReturn) {
  return useCustomMutation({
    key: 'select_insurance_quote',
    request: InsureQuote.selectInsuranceQuote,
    handleReturn,
  });
}
