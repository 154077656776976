import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ButtonBase from '@material-ui/core/ButtonBase';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import AutoComplete from 'components/atoms/AutoComplete';
import TextField from 'modules/financing/components/atoms/TextField';
import ThemeButton from 'components/atoms/ThemeButton';
import GenericDialog from 'components/GenericDialog';
import { CPFCNPJMaskCustom } from 'components/MaskTextInput';
import { LineSpaceBetween, Label, LineEnd, Hint } from './styles';

const LoadingComponent = () => {
  return (
    <>
      <div style={{ padding: '8px 0px' }}>
        <Skeleton width="100%" height="56px" variant="rect" animation="wave" />
      </div>
      <div style={{ padding: '8px 0px' }}>
        <Skeleton width="100%" height="56px" variant="rect" animation="wave" />
      </div>
    </>
  );
};

const ConfirmStatusDialog = ({
  isOpen,
  onClose,
  reason,
  statusList,
  orderCpfCnpj,
  cpfCnpjError,
  finishedStatus,
  statusOptionsList,
  statusListIsLoading,
  reasonText,
  reasonError,
  isLoading,
  apiErrorMessage,
  handleChangeInput,
  ownerRequiredError,
  handleConfirmChangeStatus,
  orderId,
  enableOrderId,
  titleModal,
}) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <GenericDialog
        open={isOpen}
        onClose={onClose}
        title={titleModal}
        maxWidth={'xs'}
        DialogAction={() => (
          <LineEnd>
            {isLoading && <Progress small />}
            <ThemeButton
              fullWidth
              onClick={!isLoading && handleConfirmChangeStatus}
            >
              Concluir
            </ThemeButton>
          </LineEnd>
        )}
      >
        <Box>
          {statusListIsLoading ? (
            <LoadingComponent />
          ) : (
            <>
              {apiErrorMessage && (
                <Hint error={apiErrorMessage}>{`${apiErrorMessage}`}</Hint>
              )}
              {statusList?.map(el => {
                const showOrderIdInput =
                  enableOrderId && !el?.dontShowOrderNumber;
                return (
                  <>
                    <ButtonBase
                      key={el?.id}
                      onClick={() =>
                        handleChangeInput({
                          finishedStatus: el?.name,
                          resonError: false,
                        })
                      }
                      style={{ width: '100%' }}
                    >
                      <LineSpaceBetween>
                        <Label>{el?.label}</Label>
                        <RadioButton checked={finishedStatus === el?.name} />
                      </LineSpaceBetween>
                    </ButtonBase>
                    {['FINISHED_AND_CONCLUDED', 'WITH_ORDER'].includes(
                      el?.name
                    ) &&
                      ['FINISHED_AND_CONCLUDED', 'WITH_ORDER'].includes(
                        finishedStatus
                      ) && (
                        <>
                          {showOrderIdInput && (
                            <div
                              style={{
                                textAlign: 'center',
                              }}
                            >
                              <TextField
                                label="Insira o número do pedido"
                                placeholder="Insira o número do pedido"
                                value={orderId}
                                error={ownerRequiredError}
                                helperText={
                                  ownerRequiredError &&
                                  'Numero do Pedido ou CPF/CNPJ são obrigatórios'
                                }
                                onChange={event =>
                                  handleChangeInput({
                                    orderId: event.target.value,
                                  })
                                }
                              />
                              <span
                                style={{ fontSize: '17px', color: '#767676' }}
                              >
                                ou
                              </span>
                            </div>
                          )}
                          <TextField
                            label="Insira o CPF/CNPJ do comprador"
                            placeholder="Insira o CPF/CNPJ do comprador"
                            value={orderCpfCnpj}
                            InputProps={{
                              inputComponent: CPFCNPJMaskCustom,
                              value: orderCpfCnpj,
                              onChange: event =>
                                handleChangeInput({
                                  orderCpfCnpj: event.target.value,
                                }),
                            }}
                            error={ownerRequiredError || cpfCnpjError}
                            helperText={
                              ownerRequiredError
                                ? showOrderIdInput
                                  ? 'Numero do Pedido ou CPF/CNPJ são obrigatórios'
                                  : 'CPF/CNPJ é obrigatório'
                                : cpfCnpjError && 'CPF/CNPJ não é valido.'
                            }
                          />
                        </>
                      )}
                    {['FINISHED_BUT_NOT_CONCLUDED'].includes(el?.name) &&
                      ['FINISHED_BUT_NOT_CONCLUDED'].includes(finishedStatus) &&
                      (!statusListIsLoading ? (
                        <>
                          <AutoComplete
                            name="reason"
                            label="Motivo"
                            value={reason ?? null}
                            onChange={itemSelected =>
                              handleChangeInput({
                                reason: itemSelected,
                                resonError: false,
                              })
                            }
                            options={statusOptionsList}
                            isClearable={false}
                          />

                          {reason?.label?.toLowerCase() === 'outros' && (
                            <TextField
                              placeholder="Descreva o motivo..."
                              label="Motivo"
                              value={reasonText}
                              onChange={event =>
                                handleChangeInput({
                                  reasonText: event.target.value,
                                  reasonError: false,
                                })
                              }
                              multiline
                              rows="3"
                              variant="outlined"
                              inputProps={{ maxLength: 200 }}
                              error={reasonError}
                              helperText={
                                reasonError
                                  ? 'A descrição do Motivo é obrigatória'
                                  : `${reasonText?.length || 0}/200`
                              }
                              style={{ marginTop: '10px' }}
                              fullWidth
                            />
                          )}
                        </>
                      ) : (
                        <Skeleton
                          width="100%"
                          height="56px"
                          variant="rect"
                          animation="wave"
                        />
                      ))}
                  </>
                );
              })}
            </>
          )}
        </Box>
      </GenericDialog>
    </ClickAwayListener>
  );
};

export default ConfirmStatusDialog;
