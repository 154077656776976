import React, { Fragment } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import Wrapper from 'components/Wrapper';
import Text from 'modules/mobiOne/components/Text';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Skeleton } from '@material-ui/lab';
import { ComponentMyQuotesProps } from '../../types';
import Card from '../Card';
import * as S from './styles';
import { Tooltip } from '@material-ui/core';

const AlertSync = () => {
  return (
    <S.AlertContainer>
      <Wrapper flex gap={8} direction="row" align="center" color="#04414E">
        <S.IconContainer>
          <InfoOutlinedIcon fontSize="small" />
        </S.IconContainer>
        <Text fontSize="14px" color="#056274">
          Carregando resultados
        </Text>
      </Wrapper>
      <Wrapper pl={24}>
        <Text fontSize="12px">
          Aguarde enquanto carregamos os resultados das cotações.
        </Text>
      </Wrapper>
    </S.AlertContainer>
  );
};

const ComponentMyQuotes = ({
  getQuotesByProposalIdIsLoading,
  selectInsuranceQuoteIsLoading,
  listQuotes,
  hasSyncQuotes,
  selectedQuote,
  handleOnClickInsuranceQuote,
}: ComponentMyQuotesProps) => {
  return (
    <Fragment>
      {hasSyncQuotes ? <AlertSync /> : null}
      {getQuotesByProposalIdIsLoading && (
        <Wrapper flex justify="center" mt={16}>
          <Progress small />
        </Wrapper>
      )}
      {listQuotes?.length ? (
        listQuotes?.map((item, index) => (
          <S.Container data-has-sync-quotes={hasSyncQuotes} key={index}>
            <S.VehicleName>{item?.vehicle}</S.VehicleName>

            <S.Accordion elevation={0}>
              <S.AccordionSummary
                expandIcon={
                  <S.ContainerExpandMore>
                    <ExpandMoreIcon color="inherit" />
                  </S.ContainerExpandMore>
                }
              >
                <S.TextWrapper>
                  <S.Text>Cotações realizadas</S.Text>
                  {item.waitingResults ? (
                    <S.Chip label="Carregando resultados" />
                  ) : null}
                </S.TextWrapper>
              </S.AccordionSummary>
              <S.AccordionDetails>
                <S.Text>Selecione uma cotação caso deseje prioriza-la:</S.Text>
                {!!item?.quotes &&
                  item?.quotes.map((quote, index) => (
                    <S.CardWrapper key={quote?.quoteId}>
                      {selectInsuranceQuoteIsLoading ? (
                        <Skeleton width="100%" height="164px" />
                      ) : (
                        <Tooltip
                          title={
                            quote.status === 'NEW'
                              ? 'Carregando resultados'
                              : ''
                          }
                          placement="top"
                        >
                          <div>
                            <Card
                              quote={quote}
                              numberQuote={index}
                              selectedQuote={selectedQuote}
                              handleOnClickInsuranceQuote={
                                handleOnClickInsuranceQuote
                              }
                              disableCard={quote.status === 'NEW'}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </S.CardWrapper>
                  ))}
              </S.AccordionDetails>
            </S.Accordion>
          </S.Container>
        ))
      ) : (
        <Wrapper flex justify="center" mt={16}>
          Não há histórico de cotações.
        </Wrapper>
      )}
    </Fragment>
  );
};

export default ComponentMyQuotes;
