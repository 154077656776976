import { useSelector } from 'react-redux';
import {
  useGetQuotesByProposalId,
  useSelectInsuranceQuote,
  useStartInsuranceQuote,
} from '../queries';
import { useState } from 'react';
import useSnackBar from 'hooks/useSnackBar';

function useInsuranceQuotes() {
  const [openModalFandi, setOpenModalFandi] = useState(false);
  const snackbar = useSnackBar();

  const { currentDealer, proposalId } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
    proposalId: state.proposalStore.currentProposal.proposalId,
  }));

  const {
    data: getQuotesByProposalIdData,
    isLoading: getQuotesByProposalIdIsLoading,
    refetch: getQuotesByProposalIdRefetch,
  } = useGetQuotesByProposalId({
    dealerId: Number(currentDealer.id),
    proposalId: Number(proposalId),
  });

  const {
    data: startInsuranceQuoteData,
    isLoading: startInsuranceQuoteIsLoading,
    mutate: startInsuranceQuoteMutate,
  } = useStartInsuranceQuote({
    onSuccess: () => {
      setOpenModalFandi(true);
    },
    onError: response => {
      snackbar.error(
        response?.data || 'Ocorreu um erro ao iniciar uma "Cotação"'
      );
    },
  });

  const {
    mutate: selectInsuranceQuoteMutante,
    isLoading: selectInsuranceQuoteIsLoading,
  } = useSelectInsuranceQuote({
    onError: response => {
      snackbar.error(
        response?.data || 'Ocorreu um erro ao selecionar uma "Cotação".'
      );
    },
  });

  return {
    dealerId: currentDealer.id,
    proposalId,
    getQuotesByProposalIdData,
    getQuotesByProposalIdIsLoading,
    getQuotesByProposalIdRefetch,
    startInsuranceQuoteData,
    startInsuranceQuoteIsLoading,
    startInsuranceQuoteMutate,
    openModalFandi,
    setOpenModalFandi,
    selectInsuranceQuoteMutante,
    selectInsuranceQuoteIsLoading,
  };
}

export default useInsuranceQuotes;
