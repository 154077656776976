export const createInsuranceProposalParameter = (currentProposal): string => {
  const {
    plate,
    vin,
    model,
    modelName,
    modelYear,
    productionYear,
  }: any = currentProposal?.vehicle?.length
    ? currentProposal.vehicle[0]
    : {
        plate: null,
        vin: null,
        model: null,
        modelYear: null,
        productionYear: null,
      };

  const _array: {
    value: string;
    params: string;
  }[] = [
    {
      value: currentProposal?.clientUserName,
      params: `Padrao2000_Nome`,
    },
    {
      value: currentProposal?.clientEmail,
      params: 'Padrao2000_E_mail',
    },
    {
      value: currentProposal.clientUserCpfCnpj,
      params: 'Padrao2000_Cgc_cpf',
    },
    {
      value: currentProposal?.clientUserPhone,
      params: 'Padrao2000_Telefone_celMod1',
    },
    {
      value: plate,
      params: 'Padrao2000_PlacaMod3',
    },
    {
      value: vin,
      params: 'Padrao2000_ChassiMod3',
    },
    {
      value: model || modelName,
      params: 'Padrao2000_Modelo',
    },
    {
      value: productionYear,
      params: 'Padrao2000_Anofabricacao',
    },
    {
      value: modelYear,
      params: 'Padrao2000_Anomodelo',
    },
    {
      value: currentProposal.id,
      params: 'Padrao2000_CotacaoExterna',
    },
  ];

  const params = [];

  _array.forEach(elem => {
    if (elem.value) {
      params.push(`${elem.params}=${elem.value}`);
    }
  });

  return params.length ? params.join('&') : '';
};
