import { useChatContext } from 'pages/chats/ChatsContext';
import React, { memo } from 'react';
import InsuranceProposalComponent from './InsuranceProposalComponent';
import { createInsuranceProposalParameter } from 'pages/chats/helpers/createInsuranceProposalParameter';

const InsuranceProposalContainer = () => {
  const { currentProposal }: any = useChatContext();
  const createParamsFrame = (): string => {
    return createInsuranceProposalParameter(currentProposal);
  };

  return <InsuranceProposalComponent params={createParamsFrame()} />;
};

export default memo(InsuranceProposalContainer);
