import { Action } from 'types/ActionTypes';
import { THIRTY_DAYS_AGO, TODAY_END_DAY } from 'helpers/predefinedDates';
import ActionTypes from './actionTypes';
import { ClientData } from 'modules/financing/interfaces';
import { OrderProduct } from 'modules/order/types/OrderProduct';
import { OrderPayment, TypeOfSale } from 'modules/order/types/OrderPayment';
import { ProposalOrderStep } from 'modules/order/types/ProposalOrder';
import moment from 'moment';

export type PaymentData = {
  typeOfSale?: TypeOfSale;
  salePrice?: string;
  saleDate?: string;
  payments?: OrderPayment[];
  validity?: string;
  products?: OrderProduct[];
  totalRemaining?: number;
  totalInserted?: number;
  totalChargedProducts?: number;
  totalServicesSubtractionTotalTroco?: number;
  percentageCommissionVDVI?: string;
  manufactorDiscountValue?: string;
  manufactorBonusValue?: string;
};

export type StatusProps = {
  label?: string;
  value?: string;
};

export type DeliveryProps = {
  externalId?: string | number;
  forecastDate?: string | number;
  situation?: string | number;
};

export type PaymentProps = {
  signalPrice?: string | number;
  soldPrice?: string | number;
};

export type MakeProps = {
  id?: string | number;
  name?: string;
};

export type ModelProps = {
  id?: string | number;
  make?: MakeProps;
  name?: string;
};

export type TrimProps = {
  exteriorColor?: string;
  fipe?: string;
  id?: string | number;
  imageId?: number;
  interiorColor?: string;
  model?: ModelProps;
  name?: string;
  productionYear?: string | number;
  modelYear?: string | number;
};

export type ClientProps = {
  cpfCnpj?: string | number;
  email?: string;
  name?: string;
  phone?: string | number;
  address?: string;
  addressType?: string;
  birthDate?: string | number;
  cityId?: number | string;
  cityStateId?: string;
  civilStatus?: string;
  complement?: string;
  formOem?: string;
  gender?: string;
  jobTitle?: string;
  nationality?: string;
  neighborhood?: string;
  number?: number | string;
  phone2?: number | string;
  rgIssuedState?: string;
  rgIssuer?: string;
  rgNumber?: number | string;
  schoolingType?: string;
  streetType?: string;
  zipcode?: string;

  cnpj?: number | string;
  companyName?: number | string;
  companySocialName?: number | string;
  companyStartDate?: number | string;
  companyEmail?: number | string;
  companyInscricaoEstadual?: number | string;
  companyPhone?: number | string;
  companyPhone2?: number | string;
  companyZipcode?: number | string;
  companyStreetType?: number | string;
  companyAddress?: number | string;
  companyNumber?: number | string;
  companyComplement?: number | string;
  companyNeighborhood?: number | string;
  companyCityStateId?: number | string;
  companyCityId?: number | string;
};

export type FiltersQueue = {
  versions?: [] | any;
  exteriorColors?: [] | any;
  model: [] | any;
};

export type FilterByDatesTypes = {
  initialDate?: string | any;
  finalDate?: string | any;
};

export type FiltersDeliveryList = {
  versions?: [] | any;
  exteriorColors?: [] | any;
  model?: [] | any;
  sellerId?: [] | any;
  filterByDates: FilterByDatesTypes;
};

export type QueueDataTypes = {
  currentStepProposalQueue?: number | string;
  RequiredFieldsForCurrentStepCompleted?: boolean;
  orderPlacedAtTheAutomaker?: boolean;
  client?: ClientProps;
  createdAt?: string | number;
  delivery?: DeliveryProps;
  comments?: string;
  payment?: PaymentProps;
  proposal?: object;
  seller?: object;
  status?: StatusProps;
  trim?: TrimProps;
  updatedAt?: string | number;
  filters?: FiltersQueue;
};

export type DeliveryListTypes = {
  filters?: FiltersDeliveryList;
};

export type ProposalDigitalTypes = {
  data?: [];
  isLoading?: boolean;
  error?: boolean;
};

export type CancelInvoiceTypes = {
  data?: any;
  isLoading?: boolean;
  error?: boolean;
};

export type OrderStepperState = {
  loadingOrder: boolean;
  dealerId: string | null;
  proposalId: string | null;
  steps: (hasProposalOEM: boolean) => Array<{ label: string }>;
  currentStep: number;
  completedSteps: number[];
  proposalStep: ProposalOrderStep;
  bankSlips: any;
  currentProposal?: any;
  currentVehicle: any;
  checkoutData: any;
  buyerCurrentData: ClientData;
  isFinished: boolean;
  orderStatus: string | null;
  paymentData: PaymentData;
  proposalDigital: ProposalDigitalTypes;
  cancelInvoice: CancelInvoiceTypes;
  saleComments: string;
  hasBankSlip: boolean;
  loadingOrderStatus: boolean;
  confettiRun: boolean;
  queueData: QueueDataTypes;
  deliveryList: DeliveryListTypes;
  financialInstitutionsOptions: any;
  approveIsLoading: boolean;
};

export const INITIAL_STATE: OrderStepperState = {
  loadingOrder: false,
  dealerId: null,
  proposalId: null,
  steps: hasProposalOEM =>
    hasProposalOEM
      ? [
          { label: 'Atendimento', value: 0 },
          { label: 'Fila de Espera', value: 1 },
          { label: 'Pedido', value: 2 },
          { label: 'Checkout', value: 3 },
        ]
      : [
          { label: 'Atendimento', value: 0 },
          { label: 'Pedido', value: 1 },
          { label: 'Checkout', value: 2 },
        ],
  currentStep: 0,
  proposalStep: ProposalOrderStep.VEHICLE,
  completedSteps: [],
  currentProposal: null,
  currentVehicle: null,
  checkoutData: null,
  approveIsLoading: false,
  paymentData: {
    typeOfSale: TypeOfSale.IN_CASH,
    salePrice: '0',
    saleDate: moment().format('YYYY-MM-DD'),
    payments: [],
    products: [],
    percentageCommissionVDVI: '0',
  },
  proposalDigital: {
    data: [],
    isLoading: false,
    error: false,
  },
  cancelInvoice: {
    data: null,
    isLoading: false,
    error: false,
  },
  hasBankSlip: false,
  bankSlips: [],
  saleComments: '',
  //ORDER_STATUS
  loadingOrderStatus: true,
  orderStatus: null,
  //BUYER_CURRENT_DATA
  buyerCurrentData: {
    //PERSONAL_FORM
    name: null,
    birthDate: null,
    cpf: null,
    motherName: null,
    rgNumber: null,
    rgIssuer: null,
    rgIssuedState: null,
    rgIssuedAt: null,
    nationality: null,
    schoolingType: 0,
    // country: 0,
    civilStatus: 0,
    gender: 0,
    phone: null,
    email: null,
    //ADDRESS_FORM
    zipCode: null,
    address: null,
    number: null,
    complement: null,
    neighborhood: null,
    cityStateId: 0,
    cityId: 0,
    addressType: 0,
    streetType: 0,
    //REFERENCES_FORM
    referenceBankId: 0,
    referenceBankAccountType: 0,
    referenceBankAgency: null,
    referenceBankAccount: null,
    referenceBankAgencyDigit: null,
    referenceName: null,
    referencePhone: null,
    referenceRelationship: null,
    //OCUPPATION_FORM
    employmentId: 0,
    careerId: 0,
    employmentName: null,
    employmentStartDate: null,
    monthlyIncome: 0,
    employmentZipcode: null,
    employmentAddressType: 0,
    employmentStreetType: 0,
    employmentAddress: null,
    employmentNumber: null,
    employmentComplement: null,
    employmentNeighborhood: null,
    employmentCityStateId: 0,
    employmentCityId: 0,
  },
  queueData: {
    currentStepProposalQueue: 0,
    RequiredFieldsForCurrentStepCompleted: false,
    orderPlacedAtTheAutomaker: false,
    client: {
      cpfCnpj: '',
      email: '',
      name: '',
      phone: '',
    },
    createdAt: '',
    delivery: {
      externalId: null,
      forecastDate: '',
      situation: '',
    },
    comments: '',
    payment: {
      signalPrice: 0,
      soldPrice: 0,
    },
    proposal: {},
    seller: {},
    status: {
      label: '',
      value: '',
    },
    trim: {
      exteriorColor: '',
      fipe: '',
      id: '',
      imageId: 0,
      interiorColor: '',
      model: {
        id: 0,
        make: {
          id: 0,
          name: '',
        },
        name: '',
      },
      name: '',
      productionYear: '',
      modelYear: '',
    },
    updatedAt: '',
  },
  deliveryList: {
    filters: {
      filterByDates: {
        initialDate: moment(THIRTY_DAYS_AGO).valueOf(),
        finalDate: moment(TODAY_END_DAY).valueOf(),
      },
    },
  },
  financialInstitutionsOptions: [],
  isFinished: false,
  confettiRun: false,
};

const evaluation = (
  state: OrderStepperState = INITIAL_STATE,
  action: Action
): OrderStepperState => {
  switch (action.type) {
    case ActionTypes.RESET_STORE:
      return { ...INITIAL_STATE };
    case ActionTypes.SET_LOADING_ORDER:
      return {
        ...state,
        loadingOrder: action.payload,
      };
    case ActionTypes.SET_LOADING_ORDER_STATUS:
      return {
        ...state,
        loadingOrderStatus: action.payload,
      };
    case ActionTypes.SET_PAYMENT_DATA:
      return {
        ...state,
        paymentData: { ...state.paymentData, ...action.payload },
      };
    case ActionTypes.SET_QUEUE_DATA:
      return {
        ...state,
        queueData: { ...state.queueData, ...action.payload },
      };
    case ActionTypes.SET_DELIVERY_LIST:
      return {
        ...state,
        deliveryList: { ...state.deliveryList, ...action.payload },
      };

    case ActionTypes.SET_PROPOSAL_DIGITAL:
      return {
        ...state,
        proposalDigital: { ...state.proposalDigital, ...action.payload },
      };
    case ActionTypes.SET_CANCEL_INVOICE:
      return {
        ...state,
        cancelInvoice: { ...state.cancelInvoice, ...action.payload },
      };
    case ActionTypes.SET_SALE_COMMENTS:
      return {
        ...state,
        saleComments: action.payload,
      };
    case ActionTypes.SET_DEALER_ID_PROPOSAL_ID:
      return {
        ...INITIAL_STATE,
        queueData: { ...state.queueData },
        deliveryList: { ...state.deliveryList },
        dealerId: action.payload.dealerId,
        proposalId: action.payload.proposalId,
      };
    case ActionTypes.SET_ACTIVE_STEP:
      return {
        ...state,
        currentStep: action.payload,
      };
    case ActionTypes.SET_CURRENT_ORDER: {
      return {
        ...state,
        buyerCurrentData: { ...state.buyerCurrentData, ...action.payload },
        orderStatus: action.payload.status,
        hasBankSlip: action.payload?.hasBankSlip || false,
        bankSlips: action.payload?.bankSlips || [],
      };
    }
    case ActionTypes.SET_CURRENT_BUYER: {
      return {
        ...state,
        buyerCurrentData: { ...state.buyerCurrentData, ...action.payload },
      };
    }
    case ActionTypes.SET_COMPLETED_STEPS:
      return {
        ...state,
        completedSteps: [...action.payload],
      };
    case ActionTypes.REMOVE_COMPLETED_STEP:
      return {
        ...state,
        completedSteps: state.completedSteps.filter(
          step => step !== action.payload
        ),
      };
    case ActionTypes.SET_PROPOSAL_STEP:
      return {
        ...state,
        proposalStep: action.payload,
      };
    case ActionTypes.SET_CURRENT_VEHICLE:
      return {
        ...state,
        currentVehicle: action.payload,
        paymentData: {
          ...state.paymentData,
          salePrice: state.paymentData.salePrice ?? action?.payload?.price ?? 0,
        },
      };
    case ActionTypes.RESET_CURRENT_VEHICLE:
      return {
        ...state,
        currentVehicle: null,
      };
    case ActionTypes.SET_ORDER_STATUS:
      return {
        ...state,
        checkoutData: action.payload,
      };
    case ActionTypes.SET_ALL_FINANCIAL_INSTITUTION_OPTIONS:
      return {
        ...state,
        financialInstitutionsOptions: [
          ...state.financialInstitutionsOptions,
          ...action.payload,
        ],
      };
    case ActionTypes.CHANGE_BUYER_CURRENT_DATA:
      //if change state reset cityId
      if (action.payload.dataName === 'addressState') {
        return {
          ...state,
          buyerCurrentData: {
            ...state.buyerCurrentData,
            [action.payload.dataName]: action.payload.dataValue,
            cityId: 0,
          },
        };
      }
      return {
        ...state,
        buyerCurrentData: {
          ...state.buyerCurrentData,
          [action.payload.dataName]: action.payload.dataValue,
        },
      };
    // case ActionType.CHANGE_
    case ActionTypes.CHANGE_PENDENCIES_INFOS_BY_ID: {
      const pendencyIndex = state.checkoutData.pendencies.findIndex(
        pendency => pendency.id === action.payload.id
      );
      const pendenciesTemp = state.checkoutData.pendencies;

      pendenciesTemp[pendencyIndex][action.payload.inputName] =
        action.payload.value;
      return {
        ...state,
        checkoutData: {
          ...state.checkoutData,
          pendencies: [...pendenciesTemp],
        },
      };
    }
    case ActionTypes.SET_CONFETTI_RUN: {
      return {
        ...state,
        confettiRun: action.payload,
      };
    }
    case ActionTypes.SET_CHECKOUT_ORDER: {
      return {
        ...state,
        isFinished: action.payload,
      };
    }
    case ActionTypes.SET_APPROVE_IS_LOADING:
      return {
        ...state,
        approveIsLoading: action.payload,
      };
    default:
      return { ...state };
  }
};

export default evaluation;
