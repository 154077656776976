import React, { useState, useCallback, useRef } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import routesPath from 'config/routesPath';
import Icon from 'bit/mobiauto.web-ui-components.components.icon';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import PaperBorder from 'components/PaperBorder';
import Divider from '@material-ui/core/Divider';
import {
  WrapperButton,
  Text,
  ExpandMore,
  Item,
  ButtonStyled,
  Overlay,
} from './styles';
import ButtonPopper from './components/ButtonPopper';
import useModalStateManager from 'hooks/useModalStateManager';
import ModalEvaluationStoryInventory from 'components/atoms/ModalEvaluationStoryInventory';
import validadeUserProfile from 'helpers/validateUserProfile';

function NewCarComponent({
  hasResaleProduct,
  isResale = false,
  hasMobioOneProduct,
}) {
  const [isOpen, setOpen] = useState(false);
  const [itemSelect, setItemSelect] = useState(null);
  const anchorEl = useRef(null);
  const { userType, profiles } = useSelector(state => state.userStore);
  const hasProfileToEditDeal = validadeUserProfile(
    'ADD_EDIT_DEAL',
    profiles,
    userType
  );

  const addNewDealModal = useModalStateManager();
  const buttonPopper = useModalStateManager();

  const handleClick = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const handleMenuItemClick = item => {
    setItemSelect(item);
    addNewDealModal.handleOpenModal();
  };

  const onCloseModal = () => {
    addNewDealModal.handleCloseModal();
    setItemSelect(null);
  };

  if (!hasProfileToEditDeal) {
    return null;
  }

  if (hasMobioOneProduct) {
    return (
      <>
        <ButtonPopper
          options={[
            { label: 'Anúncio de Showroom', value: 'showroom' },
            { label: 'Anúncio de Repasse', value: 'resale' },
          ]}
          openPopper={buttonPopper.isOpen}
          handleOpenPopper={buttonPopper.handleOpenModal}
          handleClosePopper={buttonPopper.handleCloseModal}
          handleMenuItemClick={handleMenuItemClick}
        />
        <ModalEvaluationStoryInventory
          open={addNewDealModal.isOpen}
          onClose={onCloseModal}
          dealTypeSelect={itemSelect}
        />
      </>
    );
  }

  if (hasResaleProduct) {
    return (
      <>
        <WrapperButton ref={anchorEl} onClick={handleClick}>
          <Text>Incluir anúncio</Text>
          <ExpandMore>
            {isOpen && <Overlay />}
            <Icon name={isOpen ? 'ExpandLess' : 'ExpandMore'} color="#fff" />
          </ExpandMore>
        </WrapperButton>
        <Popper
          open={isOpen}
          anchorEl={anchorEl.current}
          placement={'bottom-end'}
          disablePortal
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <PaperBorder>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <div>
                    <Link
                      to={routesPath.getRecursivePath(routesPath.dealForm)}
                      style={{ textDecoration: 'none' }}
                    >
                      <Item>Incluir anúncio de Showroom</Item>
                    </Link>
                    <Divider />
                    <Link
                      to={routesPath.getRecursivePath(
                        routesPath.resaleDealForm
                      )}
                      style={{ textDecoration: 'none' }}
                    >
                      <Item>Incluir anúncio de Repasse</Item>
                    </Link>
                  </div>
                </ClickAwayListener>
              </PaperBorder>
            </Grow>
          )}
        </Popper>
      </>
    );
  }

  return (
    <Link
      to={
        !isResale
          ? routesPath.getRecursivePath(routesPath.dealForm)
          : routesPath.getRecursivePath(routesPath.resaleDealForm)
      }
      style={{ textDecoration: 'none', marginRight: 16 }}
    >
      <ButtonStyled variant="primary">Incluir anúncio</ButtonStyled>
    </Link>
  );
}

export default NewCarComponent;
