import React from 'react';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Container } from './styles';

const InformationTooltipIcon = ({
  text,
  position = 'initial',
  top = '4px',
  right = '4px',
  color = '#757575',
  fontSize= '18px',
}) => {
  return (
    <Container position={position} top={top} right={right}>
      <Tooltip title={text}>
        <ErrorOutlineIcon style={{ fontSize: fontSize, color }} />
      </Tooltip>
    </Container>
  );
};

export default InformationTooltipIcon;
