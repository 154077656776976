import React, {
  useEffect,
  createContext,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DealerServices from 'services/dealerServices';
import ProposalServices from 'services/proposalServices';
import ProposalCallCenterServices from 'services/callCenterServices';
import { Creators as dealerCreators } from 'modules/resale/store/ducks/dealer';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { userTypes } from 'types/LeadsTypes';
import PROFILES from 'constants/profiles';
import { useSocketContext } from 'socketContext';
import GroupServices from 'services/groupServices';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';
import MessageTypes from 'constants/MessageTypes';
import { Creators as proposalCreators } from 'modules/resale/store/ducks/proposal';

const ChatContext = createContext({});

const defaultState = {
  messageText: '',
  messageType: MessageTypes.TEXT,
  isRecording: false,
  blobURL: '',
  isBlocked: false,
  currentMinute: 0,
  currentSecond: 0,
  imagesArray: [],
  timerInterval: null,
};

const ChatProvider = ({ children, onModal = false }) => {
  const {
    proposalStore,
    userStore,
    dealerStore,
    searchAndRescureCenter,
  } = useSelector(
    ({ proposalStore, userStore, dealerStore, searchAndRescureCenter }) => ({
      proposalStore,
      dealerStore,
      userStore,
      searchAndRescureCenter,
    })
  );

  const {
    dealerId,
    proposalId,
    groupId,
    currentProposalSection = null,
  } = proposalStore.currentProposal;

  const { socket, setCurrentProposalData }: any = useSocketContext();
  const dispatch = useDispatch();

  const playerRef = useRef(null);

  const [currentProposal, setCurrentProposal] = useState<any>({});
  const [currentUserType, setCurrentUserType] = useState(null);
  const [textInputState, setTextInputState] = useState<any>(defaultState);
  const [loading, setLoading] = useState(false);
  const [otherStates, setOtherStates] = useState({
    canSendWhatsappBusinessMessage: false,
    currentChannel: 'WHATSAPP',
    currentDealerProducts: [],
    interestProducts: [],
    chatIsLoading: true,
    proposalError: false,
    currentProposalSection,
  });

  const getCurrentProposal = useCallback(async () => {
    setLoading(true);

    try {
      const {
        data,
        success,
      }: any = await (checkCallCenterOsSearchAndRescueRoute()
        ? ProposalCallCenterServices
        : ProposalServices
      ).getProposalById(
        checkCallCenterOsSearchAndRescueRoute() ? groupId : dealerId,
        proposalId
      );

      if (!success) throw new Error(data);

      delete data.interactions;

      setCurrentProposal(prevState => ({
        ...data,
        interactions: prevState.interactions,
      }));

      setCurrentProposalData({ proposalId, dealerId });

      //RETURN_TO_CONFIRM_STATUS_DIALOG
      return { data };
    } catch (error) {
      const noAccessToProposal =
        String(error) === 'Error: Usuário não tem acesso a essa proposta!';

      if (noAccessToProposal) {
        dispatch(
          proposalCreators.setProposalModalOpen(false, {
            dealerId: null,
            proposalId: null,
            groupId: null,
            viewType: null,
          })
        );

        return dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Você não tem acesso a esse lead.'
          )
        );
      }

      setOtherStates(prevState => ({ ...prevState, proposalError: true }));
    }

    setLoading(false);
  }, [dealerId, groupId, proposalId]);

  const updateCurrentProposal = (key, value) => {
    setCurrentProposal(prevState => ({ ...prevState, [key]: value }));
  };

  const checkWhatsappBusinessEnable = useCallback(async () => {
    try {
      const {
        data,
        success,
      }: any = await ProposalServices.canSendWhatsappBusinessMessage(
        dealerId,
        proposalId
      );
      if (success) {
        setOtherStates(prevState => ({
          ...prevState,
          canSendWhatsappBusinessMessage: data?.cansendmessage,
        }));
      }
    } catch {
      console.log('Error');
    }
  }, [dealerId, proposalId]);

  const handleChangeChannel = currentChannel => {
    setOtherStates(prevState => ({ ...prevState, currentChannel }));
    // setCurrentProposal(prevState => ({ ...prevState, interactions: [] }));
  };

  //WHATSAPP_EVENT_TIME_LINE
  const handleWhatsappEvent = async () => {
    try {
      await ProposalServices.countChatPerWhatsapp(dealerId, proposalId);
    } catch {
      console.log('Error');
    }
  };

  const handleOnWhatsApp = async message => {
    try {
      let formatedNumber = currentProposal?.clientUserPhone?.replace(/\D/g, '');
      if (!formatedNumber?.length) {
        alert(
          'Número de telefone inválido, corrija o número de telefone e tente novamente'
        );
        return;
      }

      if (formatedNumber?.length < 11) {
        formatedNumber =
          formatedNumber.substring(0, 2) + '9' + formatedNumber.substring(2);
      }

      if (message) {
        window.open(
          `https://api.whatsapp.com/send?phone=55${formatedNumber}&text=${message}`
        );
      }
    } catch {
      console.log('Error');
    }
  };

  const addInteraction = async (
    mediaType,
    mediaData,
    dateISO,
    isBase64 = false
  ) => {
    try {
      const {
        data,
        success,
      }: any = await (checkCallCenterOsSearchAndRescueRoute()
        ? ProposalCallCenterServices
        : ProposalServices
      ).addInteraction(
        checkCallCenterOsSearchAndRescueRoute() ? groupId : dealerId,
        proposalId,
        mediaType,
        mediaData,
        dateISO,
        isBase64
      );
      if (success) {
        return { success, data };
      }
    } catch {
      console.log('Error');
    }
  };

  const sendWhatsappLocation = async tagId => {
    try {
      const response: any = await ProposalServices.sendWhatsappLocation(
        currentProposal.dealerId,
        currentProposal.id
      );
      if (response.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Endereço da loja enviado com sucesso!'
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao enviar o endereço da loja.'
        )
      );
    }
  };

  const getInteractionsById = useCallback(async () => {
    try {
      const { data, success }: any = await ProposalServices.getInteractionsById(
        dealerId,
        proposalId,
        otherStates.currentChannel,
        false
      );

      if (success) {
        const newInteractions = data?.length ? data : [];
        setCurrentProposal(prevState => ({
          ...prevState,
          interactions: newInteractions,
        }));
      }
    } catch {
      setCurrentProposal(prevState => ({
        ...prevState,
        interactions: [],
      }));
      console.log('Error');
    }
  }, [
    otherStates.currentChannel,
    currentProposal?.interactions,
    dealerId,
    proposalId,
  ]);

  const setVisualized = useCallback(() => {
    try {
      ProposalServices.setVisualized(dealerId, proposalId);
    } catch {
      console.log('Error');
    }
  }, [dealerId, proposalId]);

  const getInteractionsAndVisualized = useCallback(
    async (firstRequest = false) => {
      if (firstRequest) {
        setOtherStates(prevState => ({ ...prevState, chatIsLoading: true }));
      }
      await getInteractionsById();
      setVisualized();
      if (
        otherStates.currentChannel === 'WHATSAPP' &&
        !otherStates.canSendWhatsappBusinessMessage
      ) {
        checkWhatsappBusinessEnable();
      }
      if (firstRequest) {
        setOtherStates(prevState => ({ ...prevState, chatIsLoading: false }));
      }
    },
    [
      checkWhatsappBusinessEnable,
      getInteractionsById,
      otherStates.canSendWhatsappBusinessMessage,
      otherStates.currentChannel,
      setVisualized,
    ]
  );

  const getCurrentDealerProducts = async setFetchCurrentDealerProducts => {
    try {
      setFetchCurrentDealerProducts(true);
      const result: any = await DealerServices.getCadastro(dealerId);
      if (result.success) {
        dispatch(
          dealerCreators.setCurrentDealer({ ...result.data, id: dealerId })
        );
        setOtherStates(prevState => ({
          ...prevState,
          currentDealerProducts: result.data.products,
        }));
      }
      return;
    } catch {
      console.log('Error');
    } finally {
      setFetchCurrentDealerProducts(false);
    }
  };

  const getUserType = async () => {
    try {
      if (userStore.userProfile === PROFILES.CONSULTOR) {
        setCurrentUserType(userTypes.OWNER);
        return;
      }
      const response: any =
        searchAndRescureCenter?.currentProposal?.groupId || groupId
          ? await GroupServices.getMeInfos(
              searchAndRescureCenter?.currentProposal?.groupId ?? groupId
            )
          : await DealerServices.getCurrentUserInfosByDealerId(dealerId);

      if (response.success) {
        setCurrentUserType(response.data.userType || userTypes.SELLER);
        return;
      }
      setCurrentUserType(userTypes.SELLER);
    } catch {
      setCurrentUserType(userTypes.SELLER);
    }
  };

  const setVehicles = async (dealerId, proposalId, obj) => {
    try {
      const { success, data }: any = await ProposalServices.setVehicles(
        dealerId,
        proposalId,
        obj
      );
      if (success) {
        if ((data || {}).id) {
          return { success };
        }
        return { success };
      } else {
        return { success };
      }
    } catch {
      console.error('Error');
    }
  };

  const getInterestProducts = useCallback(async () => {
    setLoading(true);

    try {
      const response: any = await ProposalServices.getInterestProducts(
        currentProposal.dealerId,
        currentProposal.id
      );
      if (response.success) {
        setOtherStates(prevState => ({
          ...prevState,
          interestProducts: response.data,
        }));
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao adicionar um produto de interesse.'
        )
      );
    }

    setLoading(false);
  }, [currentProposal, dispatch]);

  const removeProposalTag = async tagId => {
    try {
      const response: any = await ProposalServices.deleteTag(
        currentProposal.dealerId,
        currentProposal.id,
        tagId
      );
      if (response.success) {
        updateCurrentProposal(
          'tags',
          currentProposal?.tags?.filter(tag => tag.id !== tagId)
        );
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Tag removida com sucesso!'
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Ocorreu um erro ao adicionar um produto de interesse.'
        )
      );
    }
  };

  const handleChangeDoneStatusAnnotation = (annotationId, doneStatus) => {
    const currentProposalAnnotation = currentProposal?.annotations ?? [];
    const annotationIndex = currentProposalAnnotation.findIndex(
      annotation => annotation.id === annotationId
    );
    if (annotationIndex !== -1) {
      currentProposalAnnotation[annotationIndex].done = doneStatus;
      setCurrentProposal(prevState => ({
        ...prevState,
        annotations: [...currentProposalAnnotation],
      }));
    }
  };

  useEffect(() => {
    getUserType();
    if (onModal) {
      if (proposalStore.openProposalModal) {
        getCurrentProposal();
      }
      return;
    }
    getCurrentProposal();
  }, [getCurrentProposal]);

  //SOCKET_EVENTS
  useEffect(() => {
    if (socket) {
      socket.on('message', ({ type }) => {
        if (type === 'message:getAll') {
          getInteractionsAndVisualized();
        }
      });
    }
    return () => {
      socket.off('message');
    };
  }, [otherStates.currentChannel]);

  useEffect(() => {
    getInteractionsAndVisualized(true);
  }, [proposalId, otherStates.currentChannel]);

  useEffect(() => {
    return () => {
      setCurrentProposalData(null);
    };
  }, []);

  const contextValues = {
    currentProposal,
    interactions: currentProposal?.interactions || [],
    otherStates,
    handleChangeChannel,
    handleOnWhatsApp,
    addInteraction,
    getInteractionsById,
    getCurrentProposal,
    checkWhatsappBusinessEnable,
    getCurrentDealerProducts,
    handleWhatsappEvent,
    currentUserType,
    setVehicles,
    playerRef,
    getInterestProducts,
    setOtherStates,
    setVisualized,
    updateCurrentProposal,
    removeProposalTag,
    financingBancoPanEnabled:
      dealerStore.currentDealer.financingBancoPanEnabled,
    sendWhatsappLocation,
    textInputState,
    setTextInputState,
    defaultState,
    loading,
    handleChangeDoneStatusAnnotation,
  };

  return (
    <ChatContext.Provider value={contextValues}>
      {children}
    </ChatContext.Provider>
  );
};

function useChatContext() {
  const context = useContext(ChatContext);
  return context;
}

export { ChatProvider, useChatContext };
