import React from 'react';
import GenericDialog from 'components/GenericDialog';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import TextInput from 'modules/financing/components/atoms/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import routesPath from 'config/routesPath';
import { useHistory } from 'react-router-dom';
import { IEvaluation } from './ModalEvaluationStoryInventoryContainer';
import AlertEvaluationStatus from './components/AlertEvaluationStatus';
interface Formik {
  plateOrVin: string | boolean;
}

interface EvaluationConstant {
  PENDING?: string;
  EVALUATED?: string;
  FINISHED?: string;
}

interface DealConstant {
  SHOWROOM?: string;
  REPASSE?: string;
}

type propTypes = {
  open: boolean;
  onClose: () => void;
  evaluationStatus: IEvaluation;
  isLoading: boolean;
  values: Formik;
  touched: Partial<Formik>;
  errors: Partial<Formik>;
  handleSubmit: () => void;
  onChange: (plate: string, value: string) => void;
  onBlur: (plate: string) => void;
  EVALUATION_MESSAGE: Partial<EvaluationConstant>;
  EVALUATION_STATUS: Partial<EvaluationConstant>;
  handlePushToScreen: () => void;
  handlePushToScreenEditVehicle: () => void;
  evaluationSuccess: boolean;
  DEAL_MESSAGE: Partial<DealConstant>;
  dealTypeSelect: { label: string; value: string };
};

const ModalEvaluationStoryInventoryComponent = ({
  open,
  onClose,
  evaluationStatus,
  isLoading,
  EVALUATION_MESSAGE,
  EVALUATION_STATUS,
  values,
  touched,
  errors,
  handleSubmit,
  onChange,
  onBlur,
  handlePushToScreen,
  handlePushToScreenEditVehicle,
  evaluationSuccess,
  DEAL_MESSAGE,
  dealTypeSelect,
}: propTypes) => {
  const history = useHistory();

  return (
    <GenericDialog
      title={
        dealTypeSelect ? `Novo ${dealTypeSelect?.label}` : 'Incluir Veículo'
      }
      open={open}
      onClose={onClose}
      DialogAction={() => (
        <Box
          component="div"
          width="100%"
          display="flex"
          justifyContent="flex-end"
        >
          {isLoading ? (
            <Skeleton width="376px" height="50px" />
          ) : (
            <>
              {!dealTypeSelect &&
                evaluationStatus?.stock !== undefined &&
                !evaluationStatus?.isEvaluation &&
                !evaluationStatus?.id &&
                values?.plateOrVin &&
                !errors?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={() => handlePushToScreen()}
                  >
                    Continuar para cadastro
                  </Box>
                )}
              {!dealTypeSelect &&
                !evaluationStatus?.stock &&
                !evaluationStatus?.isEvaluation &&
                values?.plateOrVin &&
                (evaluationStatus?.showroom || evaluationStatus?.resale) &&
                !errors?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={() => handlePushToScreen()}
                  >
                    Continuar para cadastro
                  </Box>
                )}

              {evaluationStatus?.evaluationStatus ===
                EVALUATION_STATUS.PENDING &&
                values?.plateOrVin &&
                !errors?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={onClose}
                  >
                    Fechar
                  </Box>
                )}
              {evaluationStatus?.isEvaluation &&
                evaluationStatus?.evaluationStatus ===
                  EVALUATION_STATUS.EVALUATED &&
                values?.plateOrVin &&
                !errors?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={() => history.push(routesPath.evaluations)}
                  >
                    Finalizar avaliação
                  </Box>
                )}
              {!dealTypeSelect &&
                evaluationStatus?.isEvaluation &&
                evaluationStatus?.evaluationStatus ===
                  EVALUATION_STATUS.FINISHED &&
                values?.plateOrVin &&
                !errors?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    height="42px"
                    fontSize="14px"
                    onClick={() => handlePushToScreenEditVehicle()}
                    style={{ padding: '10px 24px' }}
                  >
                    Ir para cadastro
                  </Box>
                )}

              {!dealTypeSelect &&
                evaluationStatus?.isEvaluation &&
                evaluationStatus?.evaluationStatus === 'CLOSED' &&
                values?.plateOrVin &&
                !errors?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={() => handlePushToScreen()}
                  >
                    Continuar para cadastro
                  </Box>
                )}

              {!dealTypeSelect &&
                evaluationStatus?.stock &&
                !evaluationStatus?.isEvaluation &&
                evaluationStatus?.id &&
                evaluationStatus?.evaluationStatus !==
                  EVALUATION_STATUS.EVALUATED &&
                values?.plateOrVin &&
                !errors?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={() => {
                      history.push(
                        `${routesPath.getRecursivePath(
                          routesPath.mobiOneVehicleDetails,
                          { id: evaluationStatus?.id }
                        )}`
                      );
                    }}
                  >
                    Ver detalhes do veículo
                  </Box>
                )}
              {!isLoading &&
                evaluationStatus?.resale &&
                dealTypeSelect?.value === 'resale' &&
                values?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={(): void => {
                      handlePushToScreenEditVehicle();
                    }}
                  >
                    Ver anúncio
                  </Box>
                )}

              {!isLoading &&
                evaluationStatus?.showroom &&
                dealTypeSelect?.value == 'showroom' &&
                values?.plateOrVin && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={(): void => {
                      handlePushToScreenEditVehicle();
                    }}
                  >
                    Ver anúncio
                  </Box>
                )}

              {!isLoading &&
                evaluationStatus &&
                !evaluationStatus?.showroom &&
                dealTypeSelect?.value == 'showroom' &&
                values?.plateOrVin &&
                evaluationStatus?.evaluationStatus !==
                  EVALUATION_STATUS.PENDING &&
                evaluationStatus?.evaluationStatus !==
                  EVALUATION_STATUS.EVALUATED && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={(): void => {
                      handlePushToScreen();
                    }}
                  >
                    Continuar
                  </Box>
                )}

              {!isLoading &&
                evaluationStatus &&
                !evaluationStatus?.resale &&
                dealTypeSelect?.value === 'resale' &&
                values?.plateOrVin &&
                evaluationStatus?.evaluationStatus !==
                  EVALUATION_STATUS.PENDING &&
                evaluationStatus?.evaluationStatus !==
                  EVALUATION_STATUS.EVALUATED && (
                  <Box
                    component="button"
                    color="#fff"
                    bgcolor="#009A8A"
                    border={0}
                    borderRadius="4px"
                    width="376px"
                    height="42px"
                    fontSize="14px"
                    onClick={(): void => {
                      handlePushToScreen();
                    }}
                  >
                    Continuar
                  </Box>
                )}
            </>
          )}
        </Box>
      )}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        gridGap="16px"
        ml={1}
      >
        <Box component="p" fontSize="16px" color="#000" m={0}>
          Informe a Placa
        </Box>
        <Box component="div" width="275px">
          <TextInput
            name="plateOrVin"
            label="Placa"
            placeholder="Placa"
            value={values.plateOrVin}
            error={touched.plateOrVin && Boolean(errors.plateOrVin)}
            helperText={Boolean(touched.plateOrVin) && errors.plateOrVin}
            onChange={e => {
              onChange('plateOrVin', e.target.value);
            }}
            onBlur={() => {
              onBlur('plateOrVin');
            }}
            InputProps={{
              maxLength: 7,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Clique para buscar"
                    onClick={() => handleSubmit()}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            mini
          />
        </Box>

        {!dealTypeSelect &&
          !isLoading &&
          !evaluationStatus?.isEvaluation &&
          evaluationStatus?.id &&
          evaluationStatus?.evaluationStatus !== EVALUATION_STATUS.EVALUATED &&
          values?.plateOrVin &&
          evaluationStatus?.stock &&
          !errors?.plateOrVin && (
            <AlertEvaluationStatus
              text={'Esse veículo já pertence ao seu estoque.'}
            />
          )}

        {!isLoading &&
          evaluationStatus?.isEvaluation &&
          evaluationStatus?.evaluationStatus === EVALUATION_STATUS.PENDING &&
          values?.plateOrVin &&
          !errors?.plateOrVin && (
            <AlertEvaluationStatus text={EVALUATION_MESSAGE.PENDING} />
          )}
        {!isLoading &&
          evaluationStatus?.isEvaluation &&
          evaluationStatus?.evaluationStatus === EVALUATION_STATUS.EVALUATED &&
          values?.plateOrVin && (
            <AlertEvaluationStatus text={EVALUATION_MESSAGE.EVALUATED} />
          )}

        {!isLoading &&
          evaluationStatus?.isEvaluation &&
          evaluationStatus?.evaluationStatus === EVALUATION_STATUS.FINISHED &&
          values?.plateOrVin &&
          !errors?.plateOrVin &&
          !evaluationStatus?.showroom &&
          !evaluationStatus?.resale &&
          !dealTypeSelect?.value && (
            <AlertEvaluationStatus text={EVALUATION_MESSAGE.FINISHED} />
          )}

        {!isLoading && values?.plateOrVin && !evaluationSuccess && (
          <AlertEvaluationStatus text={'Placa ou Chassi Inválido.'} />
        )}
        {!isLoading &&
          evaluationStatus?.showroom &&
          dealTypeSelect?.value === 'showroom' &&
          values?.plateOrVin && (
            <AlertEvaluationStatus text={DEAL_MESSAGE.SHOWROOM} />
          )}

        {!isLoading &&
          evaluationStatus?.resale &&
          dealTypeSelect?.value === 'resale' &&
          values?.plateOrVin && (
            <AlertEvaluationStatus text={DEAL_MESSAGE.REPASSE} />
          )}
      </Box>
    </GenericDialog>
  );
};

export default ModalEvaluationStoryInventoryComponent;
