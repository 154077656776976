import { createActions, createReducer } from 'reduxsauce';

export const { Types, Creators } = createActions({
  setCurrentUser: ['user'],
  setUserSiteId: ['userSiteId'],
  getCurrentUserInfosByDealerId: ['dealerId', 'callback'],
});

export const INITIAL_STATE = {
  email: null,
  username: null,
  firstName: null,
  attributes: [],
  emailVerified: null,
  userType: 'SELLER',
  profiles: [],
  department: {},
};

export const setCurrentUser = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    ...action.user,
    profiles: action.user?.profiles || [],
    userType: state?.email?.match(/@mobiauto.com.br|@grupopan.com/g)
      ? 'OWNER'
      : action.user?.userType || 'SELLER',
  };
};

export const setUserSiteId = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    userSite: {
      ...state.userSite,
      id: action.userSiteId,
    },
  };
};

export const HANDLERS = {
  [Types.SET_CURRENT_USER]: setCurrentUser,
  [Types.SET_USER_SITE_ID]: setUserSiteId,
};

export default createReducer(INITIAL_STATE, HANDLERS);
