import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import { matchPath } from 'react-router-dom';
import ProposalServices from 'services/proposalServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import routesPath from 'config/routesPath';
import { ReactComponent as TemplateIcon } from 'assets/bubble-icon.svg';
import Chip from 'components/atoms/Chip';
import ThemeCard from './components/ThemeCard';
import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import AutoComplete from 'components/atoms/AutoComplete';
import EmptyTemplates from './components/EmptyTemplates';
import { useChatContext } from 'pages/chats/ChatsContext';
import { Container, ActionButtonContainer, TitleContainer } from './styles';

interface Theme {
  id: number;
  message: string;
  category?: {
    id?: number;
    name: string;
    color?: string;
  };
  department?: {
    id?: number;
    name: string;
  };
  channel: string;
  systemic: boolean;
  whatsappTemplate: boolean;
}

interface MessageTemplateProps {
  open: boolean;
  handleOnClose(): void;
  sendMessage(message: string): void;
  currentChannel: string;
}

const DialogAction = ({ themeSelectedId, handleClickConfirm }) => (
  <ActionButtonContainer>
    <ThemeButton
      disabled={themeSelectedId === null}
      onClick={handleClickConfirm}
    >
      Enviar
    </ThemeButton>
  </ActionButtonContainer>
);

const MessageTemplateDialog: React.FC<MessageTemplateProps> = ({
  open,
  handleOnClose,
  sendMessage,
  currentChannel,
}) => {
  const { currentProposal }: any = useChatContext();

  const dispatch = useDispatch();
  const [themeSelected, setThemeSelected] = useState<Theme>({
    id: null,
    message: null,
    category: {
      name: null,
    },
    channel: null,
    systemic: false,
    whatsappTemplate: false,
  });
  const [fetchTemplates, setFetchTemplates] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [templatesFiltred, setTemplatesFiltred] = useState([]);
  const [filterState, setFilterState] = useState({
    categoryId: null,
    departmentId: currentProposal?.department?.id ?? null,
  });

  const filterTemplates = useCallback(
    (array = null) => {
      const filtredByCategory = (array || allTemplates).filter(message => {
        if (filterState?.categoryId) {
          return message?.category?.id === filterState.categoryId;
        }
        return true;
      });

      const filtredByCategoryAndDepartment = filtredByCategory.filter(
        message => {
          if (!message?.department?.id) {
            return true;
          }
          if (filterState?.departmentId) {
            return message?.department?.id === filterState.departmentId;
          }
          return true;
        }
      );

      return setTemplatesFiltred(filtredByCategoryAndDepartment);
    },
    [allTemplates, filterState.categoryId, filterState.departmentId]
  );

  const getMessagesTemplate = useCallback(async () => {
    try {
      setFetchTemplates(true);
      const { dealerId, id } = currentProposal
        ? currentProposal
        : matchPath(
            window.location.pathname,
            routesPath.getRecursivePath(routesPath.proposalOrderById)
          )?.params;

      const response: any = await ProposalServices.getMessagesTemplate(
        dealerId,
        id,
        currentChannel
      );
      if (response.success) {
        setAllTemplates(response.data);
        filterTemplates(response.data);
        setFetchTemplates(false);
      }
    } catch {
      setFetchTemplates(false);
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    }
  }, [currentChannel, currentProposal, dispatch, filterTemplates]);

  useEffect(() => {
    getMessagesTemplate();
  }, []);

  useEffect(() => {
    filterTemplates();
  }, [filterState, filterTemplates]);

  const getFilters = filterName => {
    const filterOptions = [];
    allTemplates.map(template => {
      if (!template?.[filterName]?.id) {
        return;
      }
      if (
        !filterOptions.find(
          filter => filter.value === template?.[filterName]?.id
        )
      ) {
        filterOptions.push({
          name: template?.[filterName]?.name,
          value: template?.[filterName]?.id,
        });
      }
    });
    return filterOptions;
  };

  const categoryFilterOptions = getFilters('category');

  const departmentFilterOptions = getFilters('department');

  return (
    <GenericDialog
      open={open}
      onClose={handleOnClose}
      title={
        <TitleContainer>
          <TemplateIcon />
          Mensagens pré-definidas
        </TitleContainer>
      }
      maxWidth={'sm'}
      DialogAction={() =>
        templatesFiltred.length ? (
          <DialogAction
            themeSelectedId={themeSelected.id}
            handleClickConfirm={() => sendMessage(themeSelected.message)}
          />
        ) : null
      }
    >
      <>
        {(!!categoryFilterOptions.length ||
          !!departmentFilterOptions.length) && (
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: 4 }}>Filtrar: </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexFlow: 'wrap',
                }}
              >
                {categoryFilterOptions.map(el => (
                  <div
                    style={{ cursor: 'pointer', margin: '0px 4px' }}
                    onClick={() =>
                      setFilterState(prevState => ({
                        ...prevState,
                        categoryId:
                          el?.value === filterState.categoryId
                            ? null
                            : el?.value,
                      }))
                    }
                    key={el.value}
                  >
                    <Chip
                      type={
                        filterState.categoryId === el.value ? 'high' : 'default'
                      }
                    >
                      {el?.name}
                    </Chip>
                  </div>
                ))}
              </div>
            </div>

            {!currentProposal?.department?.id &&
              !!departmentFilterOptions?.length && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 329 }}>
                    <AutoComplete
                      name="department"
                      label="Departamento"
                      onChange={event =>
                        setFilterState(prevState => ({
                          ...prevState,
                          departmentId: event?.value || null,
                        }))
                      }
                      options={departmentFilterOptions.map(el => ({
                        label: el.name,
                        value: el.value,
                      }))}
                    />
                  </div>
                </div>
              )}
          </div>
        )}
        {fetchTemplates ? (
          <div>
            <Progress large />
          </div>
        ) : templatesFiltred.length ? (
          <Container>
            {templatesFiltred.map(theme => {
              return (
                <ThemeCard
                  key={theme.id}
                  theme={theme}
                  handleClick={setThemeSelected}
                  themeSelected={themeSelected}
                />
              );
            })}
          </Container>
        ) : (
          <EmptyTemplates />
        )}
      </>
    </GenericDialog>
  );
};

export default MessageTemplateDialog;
