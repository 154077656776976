import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import ButtonBase from '@material-ui/core/ButtonBase';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import TextField from 'modules/financing/components/atoms/TextField';
import AutoComplete from 'components/atoms/AutoComplete';
import ThemeButton from 'components/atoms/ThemeButton';
import GenericDialog from 'components/GenericDialog';
import { LineSpaceBetween, Label, LineEnd } from './styles';

const ConfirmStatusDialog = ({
  isOpen,
  onClose,
  isLoading,
  finishedStatus,
  handleConfirmChangeStatus,
  handleChangeInput,
  statusListIsLoading,
  statusList,
  reason,
  statusOptionsList,
  reasonEmpty,
  reasonText,
  reasonError,
  titleModal,
}) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <GenericDialog
        open={isOpen}
        onClose={onClose}
        title={titleModal}
        maxWidth={'xs'}
        DialogAction={() => (
          <LineEnd>
            {isLoading && <Progress small />}
            <ThemeButton
              fullWidth
              onClick={isLoading ? () => null : handleConfirmChangeStatus}
              disabled={
                isLoading ||
                !finishedStatus ||
                (['DISQUALIFIED'].includes(finishedStatus) && !reason)
              }
            >
              Concluir
            </ThemeButton>
          </LineEnd>
        )}
      >
        {statusListIsLoading ? (
          <>
            <div style={{ padding: '8px 0px' }}>
              <Skeleton
                width="100%"
                height="56px"
                variant="rect"
                animation="wave"
              />
            </div>

            <div style={{ padding: '8px 0px' }}>
              <Skeleton
                width="100%"
                height="56px"
                variant="rect"
                animation="wave"
              />
            </div>

            <div style={{ padding: '8px 0px' }}>
              <Skeleton
                width="100%"
                height="56px"
                variant="rect"
                animation="wave"
              />
            </div>
          </>
        ) : (
          <>
            {statusList.map(el => {
              return (
                <>
                  <ButtonBase
                    key={el.id}
                    onClick={() =>
                      handleChangeInput({
                        finishedStatus: el.name,
                        resonError: false,
                      })
                    }
                    style={{ width: '100%' }}
                  >
                    <LineSpaceBetween>
                      <Label>{el.label}</Label>
                      <RadioButton checked={finishedStatus === el.name} />
                    </LineSpaceBetween>
                  </ButtonBase>
                  {el.name === 'DISQUALIFIED' &&
                    finishedStatus === 'DISQUALIFIED' &&
                    (!statusListIsLoading ? (
                      <>
                        <AutoComplete
                          name="reason"
                          label="Motivo"
                          value={reason ? reason : null}
                          onChange={itemSelected =>
                            handleChangeInput({
                              reason: itemSelected,
                              resonError: false,
                            })
                          }
                          options={statusOptionsList}
                          isClearable={false}
                          error={reasonEmpty}
                          helperText={
                            reasonEmpty && 'Selecione o motivo para conluir'
                          }
                        />

                        {reason?.label.toLowerCase() === 'outros' && (
                          <TextField
                            placeholder="Descreva o motivo..."
                            label="Motivo"
                            value={reasonText}
                            onChange={event =>
                              handleChangeInput({
                                reasonText: event.target.value,
                                reasonError: false,
                              })
                            }
                            multiline
                            error={reasonError}
                            rows="3"
                            variant="outlined"
                            inputProps={{ maxLength: 200 }}
                            helperText={
                              reasonError
                                ? 'A descrição do Motivo é obrigatória'
                                : `${reasonText?.length || 0}/200`
                            }
                            style={{ marginTop: '10px' }}
                            fullWidth
                          />
                        )}
                      </>
                    ) : (
                      <Skeleton
                        width="100%"
                        height="56px"
                        variant="rect"
                        animation="wave"
                      />
                    ))}
                </>
              );
            })}
          </>
        )}
      </GenericDialog>
    </ClickAwayListener>
  );
};

export default ConfirmStatusDialog;
