import { useMutation } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import fandiServices from 'services/fandiServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import EnvVars from 'helpers/envVars';
import getImage from 'components/getImage';
import { useChatContext } from 'pages/chats/ChatsContext';

const domain = EnvVars('API_FANDI_BASE_URL');

const useToObtainGuid = () => {
  const dispatch = useDispatch();
  const {
    dealerStore: { currentDealer },
    dealerStore: { primaryColor },
    dealerStore: { secondaryColor },
  } = useSelector(state => ({
    dealerStore: state.dealerStore,
  }));

  const { currentProposal, otherStates }: any = useChatContext();

  const lastVehicleArray =
    otherStates?.interestProducts[otherStates?.interestProducts.length - 1];

  const vehicle = currentProposal?.vehicle?.find(
    item => item?.id === lastVehicleArray?.id
  );

  const linkImage = getImage(
    {
      imageId: currentDealer?.logo?.imageId,
      imageVcode: currentDealer?.logo?.imageVcode,
    },
    80,
    120,
    120
  );

  const fandiIntegration = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'FANDI'
  );

  const valorVeiculo =
    Number(vehicle?.price) +
      currentDealer?.additionalValueOfProductsOnFinancing ?? null;

  const fetchGuidMutation = useMutation(
    async () => {
      const response = await fandiServices.toObtainGuid({
        config: {
          chaveAcesso: fandiIntegration?.externalIdentification?.split(
            '|'
          )?.[1],
          cnpjLoja: currentDealer.cnpj?.replace(/[.-]/g, ''),
          cpfVendedor: '30030030030',
          urlLogomarca: linkImage || null,
          corFundo: '#ffffff',
          corDestaque: primaryColor,
          corFonteBotao: secondaryColor,
          urlCallback: `${domain}/public/api/callback/checkout/quick-simulation?dealerId=${currentDealer?.id}&proposalId=${currentProposal?.id}`,
          // 'https://api.mobiauto.com.br/fandi/public/api/callback/checkout/quick-simulation?dealerId=18371&proposalId=38352388',
        },
        cliente: {
          nome: currentProposal?.clientUserName,
          sexo: null,
          dataNascimento: null,
          possuiCnh: null,
          usoComercial: false,
          pcd: false,
          cpf: currentProposal.clientUserCpfCnpj?.replace(/[.-]/g, ''),
          email: currentProposal?.clientEmail,
          celular: currentProposal?.clientUserPhone,
          usoTaxi: false,
        },
        veiculo: {
          molicar: null,
          codigoParceiro: null,
          tipo: null,
          placa: vehicle?.plate || null,
          valorVeiculo,
          chassi: vehicle?.vin || null,
          fipe: vehicle?.fipe || null,
          quilometragem: vehicle?.deal0km ? 0 : vehicle?.km,
          zeroKm: vehicle?.deal0km,
          cor: null,
          anoModelo: vehicle?.modelYear || null,
          anoFabricacao: vehicle?.productionYear || null,
        },
        simulacao: {
          valorEntrada: null,
          quantidadeParcelas: null,
        },
        confirmarDados: [
          'ConfirmarDadosPessoais',
          'ConfirmarDadosVeiculo',
          'ConfirmarDadosFinanciamento',
        ],
      });
      return response;
    },
    {
      onError: () => {
        dispatch(
          UIActionCreators.snackbarManagement(
            'error',
            'Ocorreu um erro inesperado.',
            true
          )
        );
      },
    }
  );

  return fetchGuidMutation;
};

export default useToObtainGuid;
