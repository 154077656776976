import MODULE from './module';
import actionOptions, { getters } from '../actionsOptions';

export default {
  //SET_CURRENT_USER
  SET_CURRENT_USER_TYPE: `@${MODULE}/${getters.SET}_CURRENT_USER_TYPE`,
  //SET_DISPLAYED_VISION
  SET_DISPLAYED_VISION: `@${MODULE}/${getters.SET}_DISPLAYED_VISION`,
  //SET_SELLER
  SET_SELLER_ID: `@${MODULE}/${getters.SET}_SELLER_ID`,
  SET_SELLER_NAME: `@${MODULE}/${getters.SET}_SELLER_NAME`,
  //CHANGE_DATE_FILTERS
  SET_FILTER_DATE: `@${MODULE}/SET_FILTER_DATE`,
  SET_FILTER_DATE_QUALIFIED: `@${MODULE}/SET_FILTER_DATE_QUALIFIED`,
  //CHANGE_FILTERS
  SET_CHANNEL: `@${MODULE}/${getters.SET}_CHANNEL`,
  GET_CHANNEL_OPTIONS: `@${MODULE}/${getters.GET}_GET_CHANNEL_OPTIONS`,
  SET_CHANNEL_OPTIONS: `@${MODULE}/${getters.SET}_SET_CHANNEL_OPTIONS`,
  SET_ORIGIN: `@${MODULE}/${getters.SET}_ORIGIN`,
  SET_TAGS: `@${MODULE}/${getters.SET}_TAGS`,
  GET_ORIGIN_OPTIONS: `@${MODULE}/${getters.GET}_GET_ORIGIN_OPTIONS`,
  SET_ORIGIN_OPTIONS: `@${MODULE}/${getters.SET}_SET_ORIGIN_OPTIONS`,
  SET_DEPARTMENT: `@${MODULE}/${getters.SET}_DEPARTMENT`,
  GET_DEPARTMENT_OPTIONS: `@${MODULE}/${getters.GET}_DEPARTMENT_OPTIONS`,
  SET_DEPARTMENT_OPTIONS: `@${MODULE}/${getters.SET}_DEPARTMENT_OPTIONS`,
  SET_ATTENDANCE_TYPE: `@${MODULE}/${getters.SET}_SET_ATTENDANCE_TYPE`,
  SET_FILTER_BY_DEALER_ID: `@${MODULE}/${getters.SET}SET_FILTER_BY_DEALER_ID`,
  SET_FILTER_BY_SUB_GROUPS: `@${MODULE}/${getters.SET}SET_FILTER_BY_SUB_GROUPS`,
  SET_DEALERS: `@${MODULE}/${getters.SET}SET_DEALERS`,
  //PROPOSALS INFOS
  GET_MANAGER_PROPOSALS_INFOS: `@${MODULE}/${getters.GET}_MANAGER_PROPOSALS_INFOS_${actionOptions.REQUEST}`,
  GET_SELLER_PROPOSALS_INFOS: `@${MODULE}/${getters.GET}_SELLER_PROPOSALS_INFOS_${actionOptions.REQUEST}`,
  SET_PROPOSALS_INFOS_ERROR: `@${MODULE}/${getters.SET}_PROPOSALS_INFOS_${actionOptions.ERROR}`,
  SET_PROPOSALS_INFOS_SUCCEDED: `@${MODULE}/${getters.SET}_PROPOSALS_INFOS_${actionOptions.SUCCESS}`,
  SET_PROPOSALS_INFOS_LOADING: `@${MODULE}/${getters.SET}_PROPOSALS_INFOS_${actionOptions.LOADING}`,
  //CONVERTION
  GET_SELLER_CONVERTION: `@${MODULE}/${getters.GET}_SELLER_CONVERTION_${actionOptions.REQUEST}`,
  GET_MANAGER_CONVERTION: `@${MODULE}/${getters.GET}_MANAGER_CONVERTION_${actionOptions.REQUEST}`,
  SET_CONVERTION_ERROR: `@${MODULE}/${getters.SET}_CONVERTION_${actionOptions.ERROR}`,
  SET_CONVERTION_SUCCEDED: `@${MODULE}/${getters.SET}_CONVERTION_${actionOptions.SUCCESS}`,
  SET_CONVERTION_LOADING: `@${MODULE}/${getters.SET}_CONVERTION_${actionOptions.LOADING}`,
  //SELLERS_PROPOSALS
  GET_SELLERS_PROPOSALS: `@${MODULE}/${getters.GET}_SELLERS_PROPOSALS_${actionOptions.REQUEST}`,
  SET_SELLERS_PROPOSALS_ERROR: `@${MODULE}/${getters.SET}_SELLERS_PROPOSALS_${actionOptions.ERROR}`,
  SET_SELLERS_PROPOSALS_SUCCEDED: `@${MODULE}/${getters.SET}_SELLERS_PROPOSALS_${actionOptions.SUCCESS}`,
  SET_SELLERS_PROPOSALS_LOADING: `@${MODULE}/${getters.SET}_SELLERS_PROPOSALS_${actionOptions.LOADING}`,
  //PROVIDERS_PROPOSALS
  GET_PROVIDERS_PROPOSALS: `@${MODULE}/${getters.GET}_PROVIDERS_PROPOSALS_${actionOptions.REQUEST}`,
  SET_PROVIDERS_PROPOSALS_ERROR: `@${MODULE}/${getters.SET}_PROVIDERS_PROPOSALS_${actionOptions.ERROR}`,
  SET_PROVIDERS_PROPOSALS_SUCCEDED: `@${MODULE}/${getters.SET}_PROVIDERS_PROPOSALS_${actionOptions.SUCCESS}`,
  SET_PROVIDERS_PROPOSALS_LOADING: `@${MODULE}/${getters.SET}_PROVIDERS_PROPOSALS_${actionOptions.LOADING}`,
  //SELLER_SCHEDULE
  GET_SELLER_SCHEDULE: `@${MODULE}/${getters.GET}_SELLER_SCHEDULE_${actionOptions.REQUEST}`,
  SET_SELLER_SCHEDULE_ERROR: `@${MODULE}/${getters.SET}_SELLER_SCHEDULE_${actionOptions.ERROR}`,
  SET_SELLER_SCHEDULE_SUCCEDED: `@${MODULE}/${getters.SET}_SELLER_SCHEDULE_${actionOptions.SUCCESS}`,
  SET_SELLER_SCHEDULE_LOADING: `@${MODULE}/${getters.SET}_SELLER_SCHEDULE_${actionOptions.LOADING}`,
  //SELLER_SCHEDULE_INFOS
  GET_SELLER_SCHEDULE_INFOS: `@${MODULE}/${getters.GET}_SELLER_SCHEDULE_INFOS_${actionOptions.REQUEST}`,
  GET_SELLER_SCHEDULE_INFOS_ERROR: `@${MODULE}/${getters.GET}_SELLER_SCHEDULE_INFOS_${actionOptions.ERROR}`,
  SET_SELLER_SCHEDULE_INFOS_LOADING: `@${MODULE}/${getters.SET}_SELLER_SCHEDULE_INFOS_${actionOptions.LOADING}`,
  GET_SELLER_SCHEDULE_INFOS_SUCCEDED: `@${MODULE}/${getters.GET}_SELLER_SCHEDULE_INFOS_${actionOptions.SUCCESS}`,
  //SELLER_ACTIVITIES
  GET_SELLER_ACTIVITIES: `@${MODULE}/${getters.GET}_SELLER_ACTIVITIES_${actionOptions.REQUEST}`,
  SET_SELLER_ACTIVITIES_ERROR: `@${MODULE}/${getters.SET}_SELLER_ACTIVITIES_${actionOptions.ERROR}`,
  SET_SELLER_ACTIVITIES_SUCCEDED: `@${MODULE}/${getters.SET}_SELLER_ACTIVITIES_${actionOptions.SUCCESS}`,
  SET_SELLER_ACTIVITIES_LOADING: `@${MODULE}/${getters.SET}_SELLER_ACTIVITIES_${actionOptions.LOADING}`,
  //SALES_ASSISTANT_FILTER
  GET_SALES_ASSISTANT_FILTER: `@${MODULE}/${getters.GET}_SALES_ACTIVITIES_${actionOptions.REQUEST}`,
  SET_SALES_ASSISTANT_FILTER: `@${MODULE}/${getters.SET}_SALES_ASSISTANT_FILTER_${actionOptions.SUCCESS}`,
  //TYPE_OF_SALE_FILTER
  GET_TYPE_OF_SALE_FILTER: `@${MODULE}/${getters.GET}_TYPE_OF_SALE_FILTER_${actionOptions.REQUEST}`,
  SET_TYPE_OF_SALE_FILTER: `@${MODULE}/${getters.SET}_TYPE_OF_SALE_FILTER_${actionOptions.SUCCESS}`,
  //INITIAL_ANNOTATIONS
  SET_INITIAL_ANNOTATIONS_INDEX: `@${MODULE}/${getters.SET}_SET_INITIAL_ANNOTATIONS_INDEX_${actionOptions.REQUEST}`,
  SET_INITIAL_ANNOTATIONS_INDEX_ERROR: `@${MODULE}/${getters.SET}_SET_INITIAL_ANNOTATIONS_INDEX_${actionOptions.ERROR}`,
  SET_INITIAL_ANNOTATIONS_INDEX_SUCCEDED: `@${MODULE}/${getters.SET}_SET_INITIAL_ANNOTATIONS_INDEX_${actionOptions.SUCCESS}`,
  //CHANGE_TAB
  CHANGE_TAB_VALUE: `@${MODULE}/${getters.SET}_CHANGE_TAB_VALUE`,
};
