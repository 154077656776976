import React from 'react';
import { ReactComponent as EmptyTemplateMessages } from 'assets/empty-messages-template.svg';
import { EmptyContainer, EmptyIcon, TextMessage } from './styles';

const EmptyTemplates = () => {
  
  return <EmptyContainer>
    <EmptyIcon>
      <EmptyTemplateMessages />
    </EmptyIcon>
    <TextMessage>
      Crie mensagens pré-definidas para facilitar o trabalho da sua equipe.
    </TextMessage>
  </EmptyContainer>
}

export default EmptyTemplates;