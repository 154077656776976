import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'DealerServices';

interface ApiRequestQ {
  dealerId: string | number;
  proposalId: string | number;
}
export type InsureQuoteQ = ApiRequestQ;

export type SelectInsuranceQuoteQ = {
  insuranceId: number;
} & ApiRequestQ;

export type GetAllInsuranceQuoteByProposalIdQ = ApiRequestQ;

export interface GetAllInsuranceQuoteByProposalIdR {
  id: number;
  proposalId: number;
  status: 'New' | 'SYNCED';
  isSelected?: boolean;
  value?: number;
  vehicle?: string;
}

export default class InsureQuote {
  static startInsuranceQuote = (params: InsureQuoteQ) => {
    return request
      .post(
        `${domain}/dealer/${params.dealerId}/proposal/${params.proposalId}/insurance-quote/v1.0`,
        {}
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.startInsuranceQuote()`) as any
      );
  };

  static getAllInsuranceQuoteByProposalId = (
    params: GetAllInsuranceQuoteByProposalIdQ
  ): Promise<{
    success: boolean;
    data: GetAllInsuranceQuoteByProposalIdR[];
  }> => {
    return request
      .get<GetAllInsuranceQuoteByProposalIdR[]>(
        `${domain}/dealer/${params.dealerId}/proposal/${params.proposalId}/insurance-quote/getall/v1.0`
      )
      .then(
        buildHTTPResponseHandler(
          `${serviceName}.getAllInsuranceQuoteByProposalId()`
        ) as any
      );
  };

  static selectInsuranceQuote = (params: SelectInsuranceQuoteQ) => {
    return request
      .put(
        `${domain}/dealer/${params.dealerId}/proposal/${params.proposalId}/insurance-quote/v1.0/selected/${params.insuranceId}`
      )
      .then(
        buildHTTPResponseHandler(`${serviceName}.selectInsuranceQuote`) as any
      );
  };
}
