import request from 'helpers/request';
import EnvVars from 'helpers/envVars';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';

const serviceName = 'SchedulerServices';
const domain = EnvVars('API_PAINEL_DIGITAL_BASE_URL');

export default {
  updateEvent: function(dealerId, id, data) {
    return request
      .put(`${domain}/dealer/${dealerId}/schedule/v1.0/${id}`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updateEvent()`));
  },
  createEvent: function(dealerId, data, createProposal) {
    return request
      .post(`${domain}/dealer/${dealerId}/schedule/v1.0`, data, {
        params: { createProposal },
      })
      .then(buildHTTPResponseHandler(`${serviceName}.createEvent()`));
  },
  deleteEvent: function(dealerId, id) {
    return request
      .delete(`${domain}/dealer/${dealerId}/schedule/v1.0/${id}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteEvent()`));
  },
  getItervalEvents: function(dealerId, start, finish) {
    return request
      .get(`${domain}/dealer/${dealerId}/schedule/v1.0/${start}/${finish}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getItervalEvents()`));
  },
  getEventsById: function(dealerId, id) {
    return request
      .get(`${domain}/dealer/${dealerId}/schedule/v1.0/${id}`)
      .then(buildHTTPResponseHandler(`${serviceName}.getEventsById()`));
  },

  //CALLCENTER
  getEventsCallCenter: function(groupId, start, finish, params) {
    return request
      .get(
        `${domain}/callcenter/${groupId}/schedule/v2.0/reminders/detailed/${start}/${finish}`,
        params
      )
      .then(buildHTTPResponseHandler(`${serviceName}.getEventsCallCenter()`));
  },
  getEventCallCenterById: function(groupId, id) {
    return request
      .get(`${domain}/callcenter/${groupId}/schedule/v1.0/${id}`)
      .then(
        buildHTTPResponseHandler(`${serviceName}.getEventCallCenterById()`)
      );
  },
  createEventCallCenter: function(dealerId, data) {
    return request
      .post(`${domain}/callcenter/${dealerId}/schedule/v1.0`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.createEventCallCenter()`));
  },
  deleteEventCallCenter: function(dealerId, id) {
    return request
      .delete(`${domain}/callcenter/${dealerId}/schedule/v1.0/${id}`)
      .then(buildHTTPResponseHandler(`${serviceName}.deleteEventCallCenter()`));
  },
  updateEventCallCenter: function(dealerId, id, data) {
    return request
      .put(`${domain}/callcenter/${dealerId}/schedule/v1.0/${id}`, data)
      .then(buildHTTPResponseHandler(`${serviceName}.updateEventCallCenter()`));
  },
};
