import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  > svg {
    width: 27px;
    height: 27px;
    margin-right: 18px;
  }
`;

export const ActionButtonContainer = styled.div`
  width: 100%;
  text-align: end;
`;
