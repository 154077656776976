import { DealerFromStore } from 'components/withDealer';
import PROFILES from 'constants/profiles';
import VEHICLE_TYPE from 'constants/vehicleTypes';
import isEmpty from 'helpers/isEmpty';
import { action, decorate, observable } from 'mobx';
import remotedev from 'mobx-remotedev';
import CallCenterServices from 'services/callCenterServices';
import DealerServices from 'services/dealerServices';
import { userTypes } from 'types/LeadsTypes';

const _dealerPropsDefault = {
  type: 'PJ',
  email: '',
  cnpj: '',
  socialName: '',
  website: '',
  dealerDescription: '',
  logo: null,
  storeFacade: null,
  cityId: 0,
  address: '',
  number: '',
  zipCode: '',
  complement: '',
  neighborhood: '',
  latitude: '',
  longitude: '',
  phone: '',
  phone2: '',
  mobile: '',
  mobile2: '',
  mlMask: '',
  makeId: '',
  emailUsedProposals: '',
  email0kmProposals: '',
  legalResponsibleName: '',
  legalResponsibleEmail: '',
  legalResponsibleCpf: '',
  portfolioValue: '',
  users: [],
  workScheduleStartWeek: '',
  workScheduleStopWeek: '',
  workScheduleStartSaturday: '',
  workScheduleStopSaturday: '',
  workScheduleStartSunday: '',
  workScheduleStopSunday: '',
  state: '',
  dealersObserved: {},
  externalDealerIdentification: {},
  products: [],
  vehicleType: VEHICLE_TYPE[0].label,
  primaryColor: '#4443BC',
  secondaryColor: '#222222',
  dealersByGroup: null,
  taxRegime: '',
  numberStateTax: '',
  specialTaxRegime: '',
  serieInvoice: '',
  numberInvoice: '',
};
const _defaultInitialState = {
  dealer: {},
  hasFetched: false,
  hasFailed: false,
  isLoading: false,
  users: [],
  sellers: [],
  dealers: [],
  email: null,
  dealerSelected: 0,
  isWithoutDealer: null,
  type: 'PJ',
  cnpj: '',
  socialName: '',
  website: '',
  dealerDescription: '',
  logo: null,
  storeFacade: null,
  cityId: 0,
  address: '',
  number: '',
  zipCode: '',
  complement: '',
  neighborhood: '',
  latitude: '',
  longitude: '',
  phone: '',
  phone2: '',
  mobile: '',
  mobile2: '',
  mlMask: '',
  emailUsedProposals: '',
  email0kmProposals: '',
  legalResponsibleName: '',
  legalResponsibleEmail: '',
  legalResponsibleCpf: '',
  portfolioValue: '',
  workScheduleStartWeek: '',
  workScheduleStopWeek: '',
  workScheduleStartSaturday: '',
  workScheduleStopSaturday: '',
  workScheduleStartSunday: '',
  workScheduleStopSunday: '',
  state: '',
  dealerProps: {},
  dealersObserved: {},
  isLoadingDealersObserved: null,
  hasFetchedDealersObserved: false,
  hasFailedDealersObserved: false,
  dealerObserved: null,
  vehicleType: VEHICLE_TYPE[0].label,
  closeLead: false,
  primaryColor: '#4443BC',
  secondaryColor: '#222222',
  dealersByGroup: [],
  groupId: null,
  makeId: null,
  minimumImagesToPublish: null,
  taxRegime: '',
  numberStateTax: '',
  specialTaxRegime: '',
  serieInvoice: '',
  numberInvoice: '',
  pjType: '',
};

export class DealerStore {
  constructor(initialState) {
    this.setInitialState(initialState || _defaultInitialState);
  }

  setInitialState = initialState => {
    const {
      dealer,
      email,
      users,
      sellers,
      dealerSelected,
      hasFailed,
      hasFetched,
      isLoading,
      dealers,
      isWithoutDealer,
      logo,
      image,
      description,
      dealersObserved,
      isLoadingDealersObserved,
      hasFetchedDealersObserved,
      hasFailedDealersObserved,
      dealerObserved,
      externalDealerIdentification,
      products,
      vehicleType,
      primaryColor,
      secondaryColor,
      dealersByGroup,
      pjType,
    } = initialState;
    this.dealer = dealer;
    this.email = email;
    this.users = users;
    this.sellers = sellers;
    this.dealers = dealers;
    this.dealerSelected = dealerSelected;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
    this.isWithoutDealer = isWithoutDealer;
    this.logo = logo;
    this.image = image;
    this.description = description;
    this.dealersObserved = dealersObserved;
    this.isLoadingDealersObserved = isLoadingDealersObserved;
    this.hasFetchedDealersObserved = hasFetchedDealersObserved;
    this.hasFailedDealersObserved = hasFailedDealersObserved;
    this.dealerObserved = dealerObserved;
    this.externalDealerIdentification = externalDealerIdentification;
    this.products = products;
    this.vehicleType = vehicleType;
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.dealersByGroup = dealersByGroup;
    this.pjType = pjType;
  };

  setSellers = (sellers, hasFailed, hasFetched, isLoading) => {
    this.sellers = sellers;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setSalesAssistant = (salesAssistant, hasFailed, hasFetched, isLoading) => {
    this.salesAssistant = salesAssistant;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setAllStore = (dealersByGroup, hasFailed, hasFetched, isLoading) => {
    this.dealersByGroup = [...dealersByGroup];
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setUsers = (users, hasFailed, hasFetched, isLoading) => {
    this.users = users;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };
  setDealers = (dealers, hasFailed, hasFetched, isLoading) => {
    this.dealers = dealers;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  setActivateEmail = (email, hasFailed, hasFetched, isLoading) => {
    this.email = email;
    this.hasFailed = hasFailed;
    this.hasFetched = hasFetched;
    this.isLoading = isLoading;
  };

  getSellers = async (dealerId = null, proposalId = null) => {
    this.setSellers(null, null, null, true);
    const result = await DealerServices.getSellers(
      dealerId || this.dealer.id,
      proposalId
    );
    const { data, success } = result;
    if (success) {
      this.setSellers(data, false, true, false);
      return data;
    } else {
      this.setSellers(null, true, false, false);
    }
  };

  getAllStores = async groupId => {
    const result = await CallCenterServices.getDealersByGroup(groupId);
    const { data, success } = result;
    if (success) {
      this.setAllStore(data, false, true, false);
    } else {
      this.setAllStore(null, true, false, false);
    }
  };

  // getUsers = async () => {
  //   this.setUsers(null, null, null, true);
  //   const result = await DealerServices.getUsers(this.dealer.id);
  //   const { data, success } = result;

  //   if (success) {
  //     this.setUsers(data, false, true, false);
  //   } else {
  //     this.setUsers(null, true, false, false);
  //   }
  // };

  getUsers = async () => {
    try {
      const result = await DealerServices.getUsers(this.dealer.id);
      if (result.success) {
        try {
          this.setUsers(result.data, false, true, false);
          return result;
        } catch (e) {
          throw new Error(`Could not get users, ${JSON.stringify(e)}`);
        }
      } else {
        throw new Error('Could not get users');
      }
    } catch (e) {
      console.log(e);
    }
  };

  isEmpty = item => {
    if (!item || item.length === 0) return true;
    return false;
  };

  getDealers = async dealer => {
    if (this.profile === PROFILES.CONSULTOR) {
      return;
    }

    const { data, success } = await DealerServices.getDealers();

    if (success) {
      this.setDealers(data, false, true, false);
    }

    if (isEmpty(data)) {
      this.setWithoutDealer();
      return;
    }

    if (this.hasId(dealer)) {
      await this.getCadastro(dealer.id);
      this.setDealerCurrent(dealer);
      return data;
    }

    this.setDealerSelectedInitial();
    return data;
  };

  setDealerCurrent = async index => {
    this.dealer = this.dealers?.[index];
    DealerFromStore.setDealerToLocalStorage(this.dealer);
    this.dealerSelected = index;
    await this.getCadastro();
  };

  setDealerSelectedInitial = async () => {
    const dealer = DealerFromStore.getDealerFromLocalStorage();
    if (isEmpty(dealer)) {
      this.setDealerCurrent(0);
      return;
    }

    if (!this.dealers?.find(item => Number(dealer.id) === Number(item.id))) {
      this.setDealerCurrent(0);
      return;
    }
    const dealerPosition = this.dealers.findIndex(
      item => Number(item.id) === Number(dealer.id)
    );

    this.setDealerCurrent(dealerPosition);
  };

  hasId = item => {
    if (!item || item.length === 0) {
      return false;
    }
    if (!(item || {}).id) {
      return false;
    }
    return true;
  };

  setDealerFromLocalStorage = dealer => {
    this.dealer = dealer;
  };

  setDealerSelected = currentDealer => {
    if (isEmpty(currentDealer) || this.isWithoutDealer) return;
    if (!this.dealers.some(item => item.id === parseInt(currentDealer))) {
      return;
    }
    const dealerCurrent = this.dealers.findIndex(
      item => item.id === parseInt(currentDealer)
    );
    this.setDealerCurrent(dealerCurrent);

    return this.dealer;
  };

  updateDealer = async obj => {
    const result = await DealerServices.updateDealer(this.dealer.id, obj);
    const { data, success } = result;
    if (success) {
      this.setCadastro(obj);
      return { success, data };
    } else {
      return { success, data };
    }
  };

  updateDealerPresentation = async dealerId => {
    await DealerServices.updateDealerPresentation(dealerId, this.dealCurrent);
  };

  registerPushService = async dealerId => {
    await DealerServices.registerPushService(dealerId, this.dealCurrent);
  };

  updateUsers = async dealerId => {
    await DealerServices.updateUsers(dealerId, this.dealCurrent);
  };

  activateEmail = async (dealerId, activationCode) => {
    const result = await DealerServices.activateEmail(dealerId, activationCode);
    const { success, data } = result;
    if (success) {
      this.setActivateEmail(data);
      return data;
    } else {
      return false;
    }
  };

  activeDealer = async obj => {
    const result = await DealerServices.activateDealer(obj);
    const { success } = result;
    if (success) {
      return true;
    } else {
      return false;
    }
  };

  activateDealerSimple = async obj => {
    const result = await DealerServices.activateDealerSimple(obj);
    const { success } = result;
    if (success) {
      return true;
    } else {
      return false;
    }
  };

  unregisterPushService = async dealerId => {
    await DealerServices.unregisterPushService(dealerId, this.dealCurrent);
  };

  setWithoutDealer = () => {
    this.isWithoutDealer = true;
  };

  setCadastro = (cadastro = {}) => {
    const dealerCurrent = { ..._dealerPropsDefault, ...cadastro };

    this.type = dealerCurrent.type;
    this.email = dealerCurrent.email;
    this.cnpj = dealerCurrent.cnpj;
    this.socialName = dealerCurrent.socialName;
    this.name = dealerCurrent.name;
    this.description = dealerCurrent.description;
    this.website = dealerCurrent.website;
    this.dealerDescription = dealerCurrent.dealerDescription;
    this.logo = dealerCurrent.logo;
    this.storeFacade = dealerCurrent.storeFacade;
    this.cityId = dealerCurrent.cityId;
    this.address = dealerCurrent.address;
    this.number = dealerCurrent.number;
    this.zipCode = dealerCurrent.zipCode;
    this.complement = dealerCurrent.complement;
    this.neighborhood = dealerCurrent.neighborhood;
    this.latitude = dealerCurrent.latitude;
    this.longitude = dealerCurrent.longitude;
    this.phone = dealerCurrent.phone;
    this.phone2 = dealerCurrent.phone2;
    this.mobile = dealerCurrent.mobile;
    this.mobile2 = dealerCurrent.mobile2;
    this.mlMask = dealerCurrent.mlMask;
    this.emailUsedProposals = dealerCurrent.emailUsedProposals;
    this.email0kmProposals = dealerCurrent.email0kmProposals;
    this.legalResponsibleName = dealerCurrent.legalResponsibleName;
    this.legalResponsibleEmail = dealerCurrent.legalResponsibleEmail;
    this.legalResponsibleCpf = dealerCurrent.legalResponsibleCpf;
    this.portfolioValue = dealerCurrent.portfolioValue;
    this.users = dealerCurrent.users;
    this.workScheduleStartWeek = dealerCurrent.workScheduleStartWeek;
    this.workScheduleStopWeek = dealerCurrent.workScheduleStopWeek;
    this.workScheduleStartSaturday = dealerCurrent.workScheduleStartSaturday;
    this.workScheduleStopSaturday = dealerCurrent.workScheduleStopSaturday;
    this.workScheduleStartSunday = dealerCurrent.workScheduleStartSunday;
    this.workScheduleStopSunday = dealerCurrent.workScheduleStopSunday;
    this.state = dealerCurrent.state;
    this.dealerProps = dealerCurrent;
    this.externalDealerIdentification =
      dealerCurrent.externalDealerIdentification;
    this.products = dealerCurrent.products;
    this.vehicleType = dealerCurrent.vehicleType || VEHICLE_TYPE[0].label;
    this.closeLead = dealerCurrent.closeLead;
    this.shouldFinishProposalWithoutOwner =
      dealerCurrent.shouldFinishProposalWithoutOwner;
    this.primaryColor = dealerCurrent.primaryColor;
    this.secondaryColor = dealerCurrent.secondaryColor;
    this.groupId = dealerCurrent.groupId;
    this.makeId = dealerCurrent.makeId;
    this.shouldUpdateStockByIntegration =
      dealerCurrent.shouldUpdateStockByIntegration;
    this.shouldUpdateUsedVehiclePriceByIntegration =
      dealerCurrent.shouldUpdateUsedVehiclePriceByIntegration;
    this.shouldUpdate0kmVehiclePriceByIntegration =
      dealerCurrent.shouldUpdate0kmVehiclePriceByIntegration;
    this.shouldUpdateNoEssentialsStockAttributesByIntegration =
      dealerCurrent.shouldUpdateNoEssentialsStockAttributesByIntegration;
    this.minimumImagesToPublish = dealerCurrent.minimumImagesToPublish;
    this.taxRegime = dealerCurrent.taxRegime;
    this.numberStateTax = dealerCurrent.numberStateTax;
    this.specialTaxRegime = dealerCurrent.specialTaxRegime;
    this.serieInvoice = dealerCurrent.serieInvoice;
    this.numberInvoice = dealerCurrent.numberInvoice;
    this.pjType = dealerCurrent.pjType;
  };

  getCadastro = async (dealerId = null) => {
    try {
      const result = await DealerServices.getCadastro(
        dealerId || this.dealer.id
      );
      const { data, success } = result;
      if (success) {
        this.setCadastro(data, false, true, false);
        return data;
      } else {
        this.setCadastro(null, true, false, false);
      }
    } catch (e) {
      this.setCadastro(null, true, false, false);
    }
  };

  updateApresentationDealer = async obj => {
    const result = await DealerServices.updateApresentationDealer(
      this.dealer.id,
      obj
    );
    const { success } = result;

    if (success) {
      this.getCadastro();
      return success;
    } else {
      return success;
    }
  };

  doAuthentication = async obj => {
    await DealerServices.doAuthentication(this.dealer.id, obj);
  };

  getAllDealersByUser = async () => {
    return this.searchDealersByConsultor({
      page: 0,
      size: 20,
    });
  };

  setDealersObserved = (
    data,
    isLoadingDealersObserved,
    hasFetchedDealersObserved,
    hasFailedDealersObserved
  ) => {
    this.dealersObserved = data;
    this.isLoadingDealersObserved = isLoadingDealersObserved;
    this.hasFetchedDealersObserved = hasFetchedDealersObserved;
    this.hasFailedDealersObservesrd = hasFailedDealersObserved;
  };

  searchDealersByConsultor = async options => {
    try {
      this.setDealersObserved({}, true, false, false);

      const result = await DealerServices.searchDealersByConsultor(options);
      const { success, data } = result;
      if (success) {
        this.setDealersObserved(data, false, true, false);
      } else {
        this.setDealersObserved({}, false, true, true);
      }
    } catch (e) {
      this.setDealersObserved({}, false, true, true);
    }
  };

  setDealerObserved = dealer => {
    this.dealer = dealer;
    DealerFromStore.setDealerToLocalStorage(this.dealer);
    this.dealerSelected = 0;
  };

  addUserToList = user => {
    const users = [{ ...user }, ...this.users];
    this.setUsers([...JSON.parse(JSON.stringify(users))], false, true, false);
  };

  replaceUserFromList = user => {
    const userIndex = this.users.findIndex(
      item => item.userSite.id === user.userSite.id
    );
    const users = [...this.users];
    users[userIndex] = user;
    this.setUsers([...JSON.parse(JSON.stringify(users))], false, true, false);
  };

  removeUserFromList = user => {
    const userIndex = this.users.findIndex(
      item => item.userSite.id === user.userSite.id
    );
    const users = [...this.users];
    users.splice(userIndex, 1);
    this.setUsers([...JSON.parse(JSON.stringify(users))], false, true, false);
  };

  createUser = async user => {
    const result = await DealerServices.createUser(this.dealer.id, user);
    const { success } = result;
    if (success) {
      return result;
    } else {
      throw new Error('Could not create user');
    }
  };

  updateUser = async user => {
    const result = await DealerServices.updateUser(this.dealer.id, user);
    if (result.success) {
      try {
        const { password, ...userWithoutPassword } = user;
        this.replaceUserFromList(userWithoutPassword);
        return result;
      } catch (e) {
        console.log(e);
      }
    } else {
      throw new Error('Could not create user');
    }
  };

  deleteUser = async user => {
    try {
      const result = await DealerServices.deleteUser(
        this.dealer.id,
        user.userSite.id
      );
      if (result.success) {
        this.removeUserFromList(user);
        return result;
      } else {
        throw new Error('Could not delete user');
      }
    } catch (e) {
      throw new Error(`Could not delete user, ${JSON.stringify(e)}`);
    }
  };

  getSellerIdByEmail = async email => {
    const sellerIdNotFound = 9999;
    if (!email) {
      return sellerIdNotFound;
    }
    const sellers = await this.getSellers();
    //ou a loja nao possui vendedores, ou vc não é um vendedor
    if (!sellers) return;
    const seller = sellers.find(seller => seller.userSite.email === email);
    return seller ? seller.id : sellerIdNotFound;
  };

  getUserIdByEmail = email => {
    const userIdNotFound = -1;
    if (!email) {
      return userIdNotFound;
    }
    if (!this.users) return;
    const user = this.users.find(user => user.userSite.email === email);
    return user ? user.userSite.id : userIdNotFound;
  };

  getUserType = async () => {
    try {
      if (this.profile === PROFILES.CONSULTOR) {
        return userTypes.OWNER;
      }
      const response = await DealerServices.getCurrentUserInfosByDealerId(
        this.dealer.id
      );
      if (response.success) {
        return response.data.userType || userTypes.SELLER;
      }
      return userTypes.SELLER;
    } catch {
      return userTypes.SELLER;
    }
  };

  setProfile = (profile = PROFILES.USER) => {
    this.profile = profile;
  };
}

export default remotedev(
  decorate(DealerStore, {
    dealer: observable,
    hasFetched: observable,
    hasFailed: observable,
    isLoading: observable,
    dealers: observable,
    sellers: observable,
    users: observable,
    dealerSelected: observable,
    setInitialState: action,
    getDealers: action,
    setDealers: action,
    setInteraction: action,
    getSellers: action,
    setSellers: action,
    getUsers: action,
    setUsers: action,
    unregisterPushService: action,
    updateDealer: action,
    updateDealerPresentation: action,
    updateUsers: action,
    setDealerSelected: action,
    formatToDealer: action,
    setDealerSelectedInitial: action,
    setDealerFromLocalStorage: action,
    setActivateEmail: action,
    activateEmail: action,
    setWithoutDealer: action,
    setCadastro: action,
    getCadastro: action,
    setDescription: action,
    setLogo: action,
    setImage: action,
    setDealerCurrent: action,
    logo: observable,
    image: observable,
    description: observable,
    type: observable,
    cnpj: observable,
    socialName: observable,
    name: observable,
    website: observable,
    dealerDescription: observable,
    storeFacade: observable,
    cityId: observable,
    address: observable,
    number: observable,
    zipCode: observable,
    complement: observable,
    neighborhood: observable,
    latitude: observable,
    longitude: observable,
    phone: observable,
    phone2: observable,
    mobile: observable,
    mobile2: observable,
    minimumImagesToPublish: observable,
    emailUsedProposals: observable,
    email0kmProposals: observable,
    legalResponsibleName: observable,
    legalResponsibleEmail: observable,
    legalResponsibleCpf: observable,
    portfolioValue: observable,
    workScheduleStartWeek: observable,
    workScheduleStopWeek: observable,
    workScheduleStartSaturday: observable,
    workScheduleStopSaturday: observable,
    workScheduleStartSunday: observable,
    workScheduleStopSunday: observable,
    updateApresentationDealer: action,
    primaryColor: observable,
    secondaryColor: observable,
    state: observable,
    dealerProps: observable,
    externalDealerIdentification: observable,
    products: observable,
    doAuthentication: action,
    createUser: action,
    searchDealersByConsultor: action,
    getAllDealersByUser: action,
    setDealersObserved: action,
    dealersObserved: observable,
    setDealerObserved: action,
    dealerObserved: observable,
    hasId: action,
    isEmpty: action,
    setFirst: action,
    replaceUserFromList: action,
    addUserToList: action,
    deleteUser: action,
    vehicleType: observable,
    setProfile: action,
    profile: observable,
    closeLead: observable,
    getSalesAssistant: action,
    setSalesAssistant: action,
    getAllStores: action,
    setAllStore: action,
  })
);
