import styled from 'styled-components';
import { typeColors } from 'pages/proposalsConfig/tabs/PredefinedMessages/typesColor';

export const Container = styled.div`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid #e0e0e0;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .textView {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #757575;
    font-size: 12px;
    line-height: 17px;
  }
`;

export const MessageCardTitle = styled.p`
  font-size: 12px;
  color: ${({ color }) => color ?? typeColors.NOT_STARTED.color};
  border-radius: 20px;
  background-color: ${({ color }) =>
    color ? `${color}25` : typeColors.NOT_STARTED.backgroundColor};
  white-space: nowrap;
  padding: 4px 8px;
  line-height: 20px;
`;
