import { useSelector } from 'react-redux';

import { highAccessUserTypes } from 'constants/userAccess';
import { checkCallCenterOsSearchAndRescueRoute } from 'helpers/checkCallcenterOrSearchAndRescue';

const PRIVILEGED_EMAIL_DOMAINS = ['@mobiauto.com.br', '@grupopan.com'];

export function usePermissions() {
  const { user } = useSelector(state => ({ user: state?.userStore }));

  const userType = String(user?.userType ?? '');
  const userEmail = String(user?.userSite?.email ?? '');
  const userPermissions: string[] = user?.profiles ?? [];

  const isPrivilegedUserType = highAccessUserTypes.includes(userType);

  const isPrivilegedUserEmail = () => {
    const domainPattern = PRIVILEGED_EMAIL_DOMAINS.map(domain =>
      domain.replace('.', '\\.')
    ).join('|');

    const regex = new RegExp(domainPattern, 'g');

    return regex.test(userEmail);
  };

  const hasPermission = (permission: string) => {
    if (checkCallCenterOsSearchAndRescueRoute()) {
      return true;
    }
    return (
      isPrivilegedUserEmail() ||
      isPrivilegedUserType ||
      userPermissions.includes(permission)
    );
  };

  return { hasPermission };
}
