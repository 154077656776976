import React, { useEffect, useState } from 'react';
import { Select, MenuItem } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

// Constants import
import columns from 'constants/columns';

// Context import
import { useChatContext } from 'pages/chats/ChatsContext';

// Service import
import ProposalServices from 'services/proposalServices';
import ActionCreators from 'modules/order/store/reducers/actionCreators';

// Helper import
import lazyRetry from 'helpers/lazyRetry';

// Style import
import { Wrapper } from '../styles';

// Component import
import ConfirmStatusDialog from 'pages/chats/components/ConfirmStatusDialog';
const AddSchedule = React.lazy(() => lazyRetry(() => import('components/AddSchedule')));
const AddAnnotation = React.lazy(() => lazyRetry(() => import('components/AddAnnotation')));

type StepKey = "NEW" | "IN_NEGOTIATION" | "SCHEDULED" | "CHECKOUT";
type StepLabel = "Novo" | "Em negociação" | "Agendamento" | "Concluído";

interface Step {
  label: StepLabel;
  key: StepKey;
}

const steps: Step[] = [
  { label: "Novo", key: "NEW" },
  { label: "Em negociação", key: "IN_NEGOTIATION" },
  { label: "Agendamento", key: "SCHEDULED" },
  { label: "Concluído", key: "CHECKOUT" },
];

const finishedTypes = [
  'WITH_ORDER',
  'FINISHED_WITH_SOLD',
  'FINISHED_AND_CONCLUDED',
  'FINISHED_BUT_NOT_CONCLUDED',
  'FINISHED_BY_TIME',
  'FINISHED_WITHOUT_SOLD',
];

export function StatusSteps() {
  // Hooks
  const dispatch = useDispatch();
  const {
    currentProposal,
    getCurrentProposal
  }: any = useChatContext();
  const { products } = useSelector(state => state.dealerStore.currentDealer);

  // States
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openAnnotation, setOpenAnnotation] = useState(false);
  const [openScheduling, setOpenScheduling] = useState(false);
  const [proposalStatus, setProposalStatus] = useState(null);

  const hasProductProposalApplication = products
    ? products.some(product => ['PROPOSAL_APPLICATION', 'MOBIONE'].includes(product.type))
    : false;

  const getProposalStatusDetail = async () => {
    const response = await ProposalServices.getProposalStatusDetail(currentProposal.dealerId);
    const { data, success }: any = response;

    if (success) return data;

    throw new Error('Failed to get proposal status detail');
  };

  const updateProposal = currentProposal => {
    if (currentProposal.status == columns.WITH_ORDER.id)
      dispatch(ActionCreators.getOrderById());
  };

  const alterStatusCurrentProposal = async (
    status,
    extraData = {
      orderCpfCnpj: '',
      orderId: '',
      statusDetailId: '',
      reasonNotToSell: '',
    }
  ) => {
    try {
      if (!status) return null;

      const response: any = await ProposalServices.changeStatusProposal(
        currentProposal.dealerId,
        currentProposal.id,
        status,
        extraData
      );

      if (!response?.success) return response?.data;

      const { data } = await getCurrentProposal(
        currentProposal.dealerId,
        currentProposal.id
      );

      setProposalStatus(data.status);
      updateProposal(data);
      setOpenConfirmDialog(false);

      if (status === 'WITH_ORDER') dispatch(ActionCreators.setActiveStep(1));
    } catch (e) {
      return e;
    }
  };

  function handleOpenModal(status: StepKey) {
    if (status === 'IN_NEGOTIATION') return setOpenAnnotation(true);
    if (status === 'SCHEDULED') return setOpenScheduling(true);
    if (status === 'CHECKOUT') return setOpenConfirmDialog(true);
  }

  useEffect(() => {
    if (!currentProposal?.status) return;

    finishedTypes.includes(currentProposal.status)
      ? setProposalStatus("CHECKOUT")
      : setProposalStatus(currentProposal.status);

  }, [currentProposal?.status])


  return (
    <>
      <Wrapper>
        <span>Status</span>

        <Select
          style={{ marginTop: 16 }}
          variant="outlined"
          fullWidth
          value={proposalStatus}
        >
          {steps.map(step => (
            <MenuItem
              key={step.key}
              value={step.key}
              onClick={() => handleOpenModal(step.key)}
            >
              {step.label}
            </MenuItem>
          ))}
        </Select>
      </Wrapper>

      {openAnnotation && (
        <AddAnnotation
          open={openAnnotation}
          handleClose={() => setOpenAnnotation(false)}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() => {
            alterStatusCurrentProposal(columns.IN_NEGOTIATION.id);
            setOpenAnnotation(false);
          }}
          dateIsRequired
        />
      )}

      {openScheduling && (
        <AddSchedule
          open={openScheduling}
          handleClose={() => setOpenScheduling(false)}
          currentProposal={{
            dealerId: currentProposal.dealerId,
            id: currentProposal.id,
          }}
          callbackOnSuccess={() => {
            alterStatusCurrentProposal(columns.SCHEDULED.id);
            setOpenScheduling(false);
          }}
        />
      )}

      {openConfirmDialog && (
        <ConfirmStatusDialog
          isOpen={openConfirmDialog}
          onClose={() => setOpenConfirmDialog(false)}
          confirmStatus={alterStatusCurrentProposal}
          getProposalStatusDetail={getProposalStatusDetail}
          draggableItemId={currentProposal.dealerId}
          departmentId={currentProposal?.department?.id}
          clientUserCpfCnpj={currentProposal?.clientUserCpfCnpj}
          currentProposalId={currentProposal.id}
          enableOrderId={!hasProductProposalApplication}
        />
      )}
    </>
  );
}
