import { all, takeEvery, put } from 'redux-saga/effects';
import ProposalsListServices from 'modules/resale/services/ProposalsListServices';
import ProposalListCorporativeSaleServices from 'modules/resale/services/ProposalListCorporativeSaleServices';
import { ProposalServicesToggleEndpoints } from 'helpers/corporativeSaleServicesToggleWithResaleProposals';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import { Action } from 'types/ActionTypes';

function* getSellerProposals(action: Action) {
  try {
    yield put(ActionCreators.setIsLoading());
    const response = yield ProposalServicesToggleEndpoints(
      action.payload.dealerId
    ).getSellerProposals(action.payload.dealerId, action.payload.options);
    if (response.success) {
      if (action.payload.options.combo) {
        yield put(ActionCreators.setComboProposalsSuccess(response.data));
        return;
      }
      yield put(ActionCreators.setIndividualProposalsSuccess(response.data));
    } else {
      if (action.payload.options.combo) {
        yield put(ActionCreators.setComboProposalsError(response.problem));
        return;
      }
      yield put(ActionCreators.setIndividualProposalsError(response.problem));
    }
  } catch (e) {
    if (action.payload.options.combo) {
      yield put(ActionCreators.setComboProposalsError(e));
      return;
    }
    yield put(ActionCreators.setIndividualProposalsError(e));
  }
}

function* getBuyerProposals(action: Action) {
  try {
    yield put(ActionCreators.setIsLoading());
    const response = yield ProposalServicesToggleEndpoints(
      action.payload.dealerId
    ).getBuyerProposals(action.payload.dealerId, action.payload.options);
    if (response.success) {
      if (action.payload.options.combo) {
        yield put(ActionCreators.setComboProposalsSuccess(response.data));
        return;
      }
      yield put(ActionCreators.setIndividualProposalsSuccess(response.data));
    } else {
      if (action.payload.options.combo) {
        yield put(ActionCreators.setComboProposalsError(response.problem));
        return;
      }
      yield put(ActionCreators.setIndividualProposalsError(response.problem));
    }
  } catch (e) {
    if (action.payload.options.combo) {
      yield put(ActionCreators.setComboProposalsError(e));
      return;
    }
    yield put(ActionCreators.setIndividualProposalsError(e));
  }
}

export default all([
  takeEvery(ActionTypes.GET_SELLER_PROPOSALS, getSellerProposals),
  takeEvery(ActionTypes.GET_BUYER_PROPOSALS, getBuyerProposals),
]);
