import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import FormHelperText from '@material-ui/core/FormHelperText';

const Container = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  z-index: 1;
  left: 10px;
  top: 7px;
  color: ${({ error }) => (error ? '#f44336' : 'rgba(0, 0, 0, 0.54)')};
  line-height: ${({ minHeight }) => (minHeight === '58px' ? '15px' : '13px')};
`;

const AutoComplete = ({
  options,
  name,
  onChange,
  value = undefined,
  label = null,
  defaultValue = null,
  isClearable = true,
  isSearchable = true,
  isLoading = false,
  isRtl = false,
  disabled = false,
  minHeight = '47px',
  isMulti = false,
  width = '',
  error = null,
  helperText = null,
  disabledOptions = [],
  ...rest
}) => {
  const customStyles = {
    container: styles => ({
      ...styles,
      // height: '100%',
      minHeight: minHeight,
      maxHeight: '58px',
      width,
    }),
    option: (styles, { data, disabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: '1rem',
        backgroundColor: disabled
          ? undefined
          : isSelected
          ? '#00B0AA'
          : isFocused
          ? '#00b0aa39'
          : undefined,
        color: disabled ? '#ccc' : isSelected ? 'black' : data.color,
        cursor: disabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: !disabled
            ? isSelected
              ? data.color
              : '#00B0AA'
            : undefined,
        },
      };
    },
    valueContainer: styles => ({
      ...styles,
      overflowX: 'auto',
      flexWrap: 'initial',
      alignItems: 'end',
      minHeight: minHeight,
      maxHeight: '58px',
    }),
    multiValueLabel: styles => ({
      ...styles,
      lineHeight: '14px',
    }),
    multiValue: styles => ({
      ...styles,
      minWidth: 'initial',
    }),
    control: (styles, state) => ({
      ...styles,
      border: state.isFocused ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
      fontSize: '1rem',
      alignItems: value ? 'end' : 'center',
      boxShadow: state.isFocused ? 0 : 0,
      '&:hover': {
        border: state.isFocused ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
      },
      height: '100%',
      minHeight: minHeight,
      maxHeight: '58px',
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
    // menu: (styles) => ({ ...styles, zIndex: "1000", backgroundColor: "white", boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
    singleValue: styles => ({
      ...styles,
      top: 'initial',
      lineHeight: minHeight === '58px' ? '19px' : '13px',
    }),
    placeholder: styles => ({
      ...styles,
      fontSize: '1rem',
      whiteSpace: 'nowrap',
      // display: 'none'
    }),
  };

  return (
    <Container>
      {!!value && (
        <Label error={error} minHeight={minHeight}>
          {label}
        </Label>
      )}
      <Select
        value={value}
        placeholder={label}
        styles={customStyles}
        className="basic-single"
        classNamePrefix="select"
        onChange={onChange}
        name={name}
        defaultValue={defaultValue}
        options={(options?.length ? options : [])?.map(option => ({
          ...option,
          isDisabled: disabledOptions?.includes(option?.value),
        }))}
        isDisabled={disabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        menuPosition={'fixed'}
        isMulti={isMulti}
        {...rest}
      />
      {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </Container>
  );
};

export default React.memo(AutoComplete);
