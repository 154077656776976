import moment from 'moment';
import React from 'react';
import NumberFormat from 'react-number-format';
import { useSetRecoilState } from 'recoil';

import useModalStateManager from 'hooks/useModalStateManager';

import { ReactComponent as InfoIcon } from 'assets/information.svg';

import { triggerRefetchEvaluations } from '../../contexts/atom';
import ModalConfirmation from '../ModalConfirmation';
import ReferencePrices from '../PricingVehicle/ReferencePrices';

import { Tooltip, makeStyles } from '@material-ui/core';
import Wrapper from 'components/Wrapper';
import useSnackBar from 'hooks/useSnackBar';
import { generateReport } from 'pages/chats/helpers/generateReport';
import { getMessageStatus } from 'pages/chats/helpers/getMessageStatus';
import { numberFormat } from 'pages/chats/helpers/numberFormat';
import * as S from './styles';

const useStyles = makeStyles(() => ({
  arrow: {
    color: '#424242',
  },
  tooltip: {
    backgroundColor: '#424242',
    padding: '4px 8px',
    margin: '0',
  },
}));

export default function CardComponent({
  item,
  currentDealer,
  currentProposal,
  isLoading = false,
  dealerBelongsToSagaGroup = false,
}) {
  const refetchEvaluationsList = useSetRecoilState(triggerRefetchEvaluations);

  const classes = useStyles();

  const snackbar = useSnackBar();

  const date = moment(item.createdAt).format('L');
  const wasEvaluated =
    item?.status === 'EVALUATED' || item?.status === 'FINISHED';

  const { isOpen, handleCloseModal, handleOpenModal } = useModalStateManager();

  function getMessageActions(type) {
    switch (type) {
      case 'SHOWROOM':
        return 'Showroom';
      case 'RESALE':
        return 'Repasse';
      case 'NOT_BUY':
        return 'Não comprar';
      case 'GET_PRICE':
        return 'Cotação';
      case 'WORKSHOP':
        return 'Oficina';
      default:
        return '-';
    }
  }

  const getVersionInfo = value => (!value ? '' : `${String(value)} `);

  return (
    <>
      <S.Container>
        <S.DivLine>
          <S.Plate>{item.plate}</S.Plate>
          <S.TitleCardWrapper>
            <S.StyleTitleCard>{item.modelName}</S.StyleTitleCard>
            <S.Version>
              {getVersionInfo(item?.trimName)}
              {getVersionInfo(item?.transmissionName)}
              {getVersionInfo(item?.fuelName)}
            </S.Version>
          </S.TitleCardWrapper>
        </S.DivLine>
        {item?.followEvaluation &&
          item?.dealerId !== currentProposal?.dealerId && (
            <Wrapper
              flex
              align="center"
              width="max-content"
              mt="sm"
              backgroundColor="#ED6C021F"
              fontSize={12}
              r="xs"
              fontWeight={400}
              px={4}
              py={2}
            >
              <Wrapper mr="sm">Avaliação pela {item?.dealerName}</Wrapper>
              <Tooltip
                classes={classes}
                title={
                  <S.TooltipTitle>
                    Avaliação feita por loja do mesmo grupo.
                  </S.TooltipTitle>
                }
                placement="top"
              >
                <InfoIcon />
              </Tooltip>
            </Wrapper>
          )}
        <S.DivLine margin="8px 0 0 0">
          <S.TextWrapper firstItem>
            <S.LabelText>Ano:</S.LabelText>
            <S.ValueStyle>{getVersionInfo(item?.modelYear)}</S.ValueStyle>
          </S.TextWrapper>
          <S.TextWrapper>
            <S.LabelText>Km:</S.LabelText>
            <S.ValueStyle>
              {item?.km ? numberFormat({ value: item?.km }) : '-'}
            </S.ValueStyle>
          </S.TextWrapper>
        </S.DivLine>
        <S.Divider />
        <S.DivLine>
          <S.TextWrapper firstItem>
            <S.LabelText>Data:</S.LabelText>
            <S.ValueStyle>{date}</S.ValueStyle>
          </S.TextWrapper>
          <S.TextWrapper>
            <S.LabelText>Status:</S.LabelText>
            <S.ValueStyle>{getMessageStatus(item?.status)}</S.ValueStyle>
          </S.TextWrapper>
        </S.DivLine>
        <S.Divider />
        {wasEvaluated && (
          <>
            <S.DivLine>
              <S.LabelText>Sugestão de compra:</S.LabelText>
              <S.ValueStyle>
                {getMessageActions(item.evaluationRecommendation)}
              </S.ValueStyle>
            </S.DivLine>
            <S.Divider />
            <S.DivLine>
              <S.LabelText>Avaliado por:</S.LabelText>
              <Wrapper {...S.wrapperStyle}>{item.evaluator ?? '-'}</Wrapper>
            </S.DivLine>
            <S.DivLine>
              <S.LabelText>Avaliado em:</S.LabelText>
              {item.evaluationFinalPrice ? (
                <S.StyleTitleCard>
                  <NumberFormat
                    displayType="text"
                    value={item.evaluationFinalPrice}
                    thousandSeparator="."
                    decimalSeparator=","
                    allowNegative={false}
                    allowLeadingZeros={false}
                    prefix="R$ "
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </S.StyleTitleCard>
              ) : (
                <S.ValueStyle>-</S.ValueStyle>
              )}
            </S.DivLine>
            <S.Divider />
          </>
        )}
        {item?.pricing?.length > 0 && (
          <S.DivLine>
            <S.ReferenceBox>
              <ReferencePrices
                references={item?.pricing}
                isLoading={isLoading}
                listMode
                dealerBelongsToSagaGroup={dealerBelongsToSagaGroup}
              />
            </S.ReferenceBox>
          </S.DivLine>
        )}
        <S.ButtonsBox>
          <S.LaudoButton
            size="medium"
            variant="outlined"
            disabled={!wasEvaluated}
            onClick={async () => {
              const result = await generateReport({
                dealerId: currentDealer.id,
                evaluationId: item.dealId,
                enabledReport: wasEvaluated,
              });

              if (!result.success) {
                snackbar.error('Erro ao baixar PDF');
              }
            }}
          >
            Ver laudo
          </S.LaudoButton>
          <S.RequestButton
            size="medium"
            variant="contained"
            onClick={() => handleOpenModal()}
            disabled={!item?.canStartEvaluation}
          >
            Solicitar
          </S.RequestButton>
        </S.ButtonsBox>
      </S.Container>
      <ModalConfirmation
        isOpen={isOpen}
        dealId={item.dealId}
        dealerId={currentDealer.id}
        proposalId={currentProposal.id}
        onClose={() => {
          handleCloseModal();
          refetchEvaluationsList(true);
        }}
      />
    </>
  );
}
