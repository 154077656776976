import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import DealCard from 'modules/financing/components/atoms/DealListCard';
import VehicleByMakeListCard from 'modules/financing/components/atoms/VehicleByMakeListCard';
import CloseButtonIcon from 'modules/financing/components/atoms/CloseButton';
import Chip from 'modules/financing/components/atoms/Chip';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import ChooseVehicleDialog from 'components/molecules/ChooseVehicleDialog';
import formatAmount from 'helpers/formatAmount';

const ExtraCardComponent = ({
  vehiclePrice,
  removeVehicleFunc,
  isQueue = false,
}) => {
  const { queueData } = useSelector(state => state.order);
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!!vehiclePrice && (
        <Chip type={'info'} fontSize="16px">
          {formatAmount(vehiclePrice, 'PT-BR', 'R$', true)}
        </Chip>
      )}
      {isQueue &&
      !['NEW', '', undefined].includes(queueData?.status?.value) ? null : (
        <CloseButtonIcon onClick={removeVehicleFunc} />
      )}
    </div>
  );
};

type ChooseVehicleProps = {
  currentVehicle: any;
  onVehicleChoosen: (choosedVehicle?) => void;
  listType?: 'SHOWROOM' | 'EVALUATION';
  isFinished?: boolean;
  isQueue?: boolean;
};

const ChooseVehicle = ({
  currentVehicle,
  onVehicleChoosen,
  listType = 'SHOWROOM',
  isFinished = false,
  isQueue = false,
}: ChooseVehicleProps) => {
  const [openDialogState, setOpenDialogState] = useState(false);

  const handleOnDialogClose = choosenVehicle => {
    setOpenDialogState(false);
    if (choosenVehicle) {
      onVehicleChoosen(choosenVehicle);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', width: '100%' }}>
        {!currentVehicle ? (
          <HypertextButton onClick={() => setOpenDialogState(true)}>
            + Selecionar um veículo
          </HypertextButton>
        ) : !currentVehicle.vin ? (
          <VehicleByMakeListCard
            deal={currentVehicle}
            selectComponent={
              isFinished ? null : (
                <ExtraCardComponent
                  vehiclePrice={currentVehicle.price0km}
                  removeVehicleFunc={() => onVehicleChoosen(null)}
                />
              )
            }
          />
        ) : (
          <DealCard
            deal={currentVehicle}
            selectComponent={
              isFinished ? null : (
                <ExtraCardComponent
                  vehiclePrice={
                    listType === 'EVALUATION'
                      ? currentVehicle?.evaluationFinalPrice
                      : currentVehicle?.price || currentVehicle?.price0km
                  }
                  removeVehicleFunc={() => onVehicleChoosen(null)}
                  isQueue={isQueue}
                />
              )
            }
          />
        )}
      </div>
      {openDialogState && (
        <ChooseVehicleDialog
          open={openDialogState}
          onClose={handleOnDialogClose}
          listType={listType}
          isProfile={false}
          isModels0Km={false}
          isStock={isQueue ? false : true}
          extraDefaultSopValues={{ avl_: 't.1' }}
        />
      )}
    </>
  );
};

export default ChooseVehicle;
