import React, { useState } from 'react';
import GenericDialog from 'components/GenericDialog';
import ChooseVehicleTabs from 'components/molecules/ChooseVehicleTabs';
import { ActionsContainer, CancelButton, AddCarButton } from './styles';

type ChooseVehicleDialogProps = {
  open: boolean;
  onClose: (choosedVehicle?) => void;
  listType?: 'SHOWROOM' | 'EVALUATION';
  allAvaliable?: boolean;
  isStock?: boolean;
  isCatalog?: boolean;
  isProfile?: boolean;
  isModels0Km?: boolean;
  extraDefaultSopValues?: object;
};

const ChooseVehicleDialog = ({
  open,
  onClose,
  listType = 'SHOWROOM',
  allAvaliable = false,
  isStock = true,
  isCatalog = true,
  isProfile = true,
  isModels0Km = true,
  extraDefaultSopValues,
}: ChooseVehicleDialogProps) => {
  const [currentVehicle, setCurrentVehicle] = useState<any>(null);
  // const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  //DIALOG_ACTIONS_COMPONENT
  const DialogActions = () => {
    const handleOnVehicleChoosen = () => {
      onClose(currentVehicle);
      // setConfirmationDialogOpen(true);
    };

    return (
      <ActionsContainer>
        <CancelButton onClick={() => onClose()}>Cancelar</CancelButton>
        <AddCarButton
          disabled={
            !currentVehicle ||
            (currentVehicle &&
              Object.values(currentVehicle).every(el => el === null))
          }
          onClick={handleOnVehicleChoosen}
        >
          Adicionar veículo
        </AddCarButton>
      </ActionsContainer>
    );
  };

  //DIALOG
  return (
    <GenericDialog
      open={open}
      onClose={() => onClose()}
      OnCloseButton
      DialogAction={DialogActions}
      title="Selecionar veículo"
      maxWidth={'xl'}
      fullWidth
    >
      <ChooseVehicleTabs
        selectedVehicle={currentVehicle}
        handleSelectVehicle={setCurrentVehicle}
        allAvaliable={allAvaliable}
        isStock={isStock}
        isCatalog={isCatalog}
        isProfile={isProfile}
        isModels0Km={isModels0Km}
        listType={listType}
        extraDefaultSopValues={extraDefaultSopValues}
      />

      {/* {confirmationDialogOpen && (
        <ConfirmationDialog
          open={confirmationDialogOpen}
          onClose={() => setConfirmationDialogOpen(false)}
          onClickConfirmation={() => onClose(currentVehicle)}
          maxWidth={'md'}
        >
          <DealCard
            deal={buildVehicleObject(currentVehicle)}
          />
        </ConfirmationDialog>
      )} */}
    </GenericDialog>
  );
};

export default ChooseVehicleDialog;
