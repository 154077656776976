import styled from 'styled-components';

export const ContainerIframeModal = styled.div`
  overflow-x: hidden;
`;

export const ContainerButtonQuote = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;
