import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Wrapper from 'components/Wrapper';
import GenericDialog from 'components/GenericDialog';
import TabPanel from 'components/StyledComponents/TabPanel';
import StyledTab from 'components/StyledComponents/StyledTab';
import StyledTabs from 'components/StyledComponents/StyledTabs';
import ListDeals from 'modules/financing/components/molecules/ListDeals';
import ListVehiclesByMakeId from 'modules/financing/components/molecules/ListVehiclesByMakeId';
import { ListDealsQueryOptions } from 'modules/financing/components/molecules/ListDeals/ListDealsContainer';
import OutlinedButton from 'components/atoms/OutlinedButton';
import ThemeButton from 'components/atoms/ThemeButton';
import Gallery from './Gallery';

const CarPicturesDialog = ({ open, handleOnClose, handleClickImage }) => {
  const { currentDealer } = useSelector(state => state.dealerStore);

  const [vehicleSelected, setVehicleSelected] = useState<any>({
    id: null,
    zeroKm: false,
  });
  const [selectedPictures, setSelectedPictures] = useState<any>([]);
  const [currentTab, setCurrentTab] = useState(0);

  const options: ListDealsQueryOptions = {
    evaluation: true,
    evaluationStatus: 'FINISHED',
  };

  const handleChangeTab = (_, newTab) => {
    if (newTab !== currentTab) {
      setCurrentTab(newTab);
      setVehicleSelected({ id: null, zeroKm: false });
    }
  };

  function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const getBase64FromUrl = async url => {
    const data = await fetch(url);
    const blob = await data.blob();
    const base64File = await new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });

    return { base64File, type: blob.type.split('/')?.[1] };
  };

  const confirmSendImages = async () => {
    const files = [];
    if (selectedPictures.length) {
      for (let i = 0; i < selectedPictures.length; i++) {
        const { base64File, type } = await getBase64FromUrl(
          selectedPictures[i]
        );
        const file = dataURLtoFile(base64File, `${i}.${type}`);
        files.push(file);
      }
    }
    handleClickImage({ target: { files } });
    handleOnClose();
  };

  const DialogAction = () => (
    <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
      {vehicleSelected.id ? (
        <OutlinedButton
          onClick={() => {
            setVehicleSelected({ id: null, zeroKm: false });
            setSelectedPictures([]);
          }}
        >
          Voltar
        </OutlinedButton>
      ) : (
        <OutlinedButton onClick={handleOnClose}>Cancelar</OutlinedButton>
      )}
      {vehicleSelected.id && (
        <>
          <div style={{ width: 8 }} />
          <ThemeButton onClick={confirmSendImages}>Confirmar</ThemeButton>
        </>
      )}
    </div>
  );

  return (
    <GenericDialog
      open={open}
      onClose={handleOnClose}
      title={
        vehicleSelected.id
          ? 'Selecione as imagens para enviar'
          : 'Selecione um veículo'
      }
      aria-labelledby=""
      DialogAction={DialogAction}
      maxWidth={null}
      fullWidth
    >
      <StyledTabs value={currentTab} onChange={handleChangeTab}>
        <StyledTab value={0} label="Meu Estoque" />
        {currentDealer.pjType === 'CONCESSIONARIA' && (
          <StyledTab value={1} label="Catálogo 0km" />
        )}
      </StyledTabs>

      <TabPanel value={currentTab} index={0} key={'estoque'}>
        <Wrapper pt={16}>
          {!vehicleSelected.id ? (
            <ListDeals
              selectedItem={vehicleSelected}
              handleClickItem={setVehicleSelected}
              allAvaliable
            />
          ) : (
            <Gallery
              vehicleSelected={vehicleSelected}
              selectedPictures={selectedPictures}
              setSelectedPictures={setSelectedPictures}
            />
          )}
        </Wrapper>
      </TabPanel>

      {currentDealer.pjType === 'CONCESSIONARIA' && (
        <TabPanel value={currentTab} index={1} key={'0km'}>
          <Wrapper pt={16}>
            {!vehicleSelected.id ? (
              <ListVehiclesByMakeId
                selectedItemId={vehicleSelected?.id}
                handleClickItem={vehicle =>
                  setVehicleSelected({ id: vehicle?.id, zeroKm: true })
                }
                queryOptions={options}
              />
            ) : (
              <Gallery
                vehicleSelected={vehicleSelected}
                selectedPictures={selectedPictures}
                setSelectedPictures={setSelectedPictures}
              />
            )}
          </Wrapper>
        </TabPanel>
      )}
    </GenericDialog>
  );
};

export default CarPicturesDialog;
