import styled, { css } from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Label = styled.div`
  font-family: Poppins Regular, Roboto, Helvetica, Arial, sans-serif;
  font-family: Poppins Regular;
  font-size: 14px;
  color: ${Colors.gray.dark};
`;

export const LineSpaceBetween = styled.div`
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LineEnd = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Hint = styled.span`
  font-style: italic;
  color: #5f5f5f;
  font-size: 14px;
  align-self: start;
  margin-bottom: -10px;
  ${props =>
    props.error &&
    css`
      color: red;
    `}
`;
