import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  DataGrid,
  GridSortDirection,
  GridSortModel,
} from '@material-ui/data-grid';
import { Tooltip } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import FlagOutlined from '@material-ui/icons/FlagOutlined';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import { ReactComponent as PaidIcon } from 'assets/paid.svg';
import { ReactComponent as NotPaidIcon } from 'assets/not_paid.svg';
import { ReactComponent as ConeIconSvg } from 'assets/cone.svg';
import OrderDetailDialog from 'modules/store-inventory/components/Dialogs/OrderDetailDialog';
import BlockedDetailsDialog from 'modules/store-inventory/components/Dialogs/BlockedDialogs/BlockedDetailsDialog';
import BookDetailDialog from 'pages/chats/components/SideBarComponents/BookVehicleInfos/BookedVehicleInfo/BookDetailDialog';
import WarningDetailDialog from 'modules/store-inventory/components/Dialogs/WarningDialog/';
import {
  IconsLine,
  PaidChip,
  Chip,
  PlateContainer,
  IconComponent,
} from './styles';
import ViewColumns from 'components/viewColumns/ViewColumns';

function TableView(props) {
  const { products } = useSelector(state => state.dealerStore.currentDealer);
  const {
    dealsList,
    handleClick,
    currentSelected,
    currentPage,
    setCurrentPage,
    // pageSize,
    rowCount,
    loading,
  } = props;

  const [openBookDetail, setOpenBookDetail] = useState({
    open: false,
    currentDeal: null,
  });
  const [openOrderDetail, setOpenOrderDetail] = useState({
    open: false,
    currentDeal: null,
  });
  const [openBlokedDetail, setOpenBlokedDetail] = useState({
    open: false,
    currentDeal: null,
  });
  const [openWarningDetail, setWarningDetail] = useState({
    open: false,
    currentDeal: null,
  });
  const [page, setPage] = useState(0);

  function plateAndVinComponent(planteOrVin) {
    return (
      <>
        {planteOrVin?.length > 7 ? (
          <Tooltip title={planteOrVin} placement="top">
            <PlateContainer>
              {planteOrVin.substr(planteOrVin.length - 6)}
            </PlateContainer>
          </Tooltip>
        ) : (
          <Tooltip title={planteOrVin} placement="top">
            <PlateContainer>{planteOrVin}</PlateContainer>
          </Tooltip>
        )}
      </>
    );
  }

  function colorComponent(color) {
    return (
      <>
        <Tooltip title={color} placement="top">
          <span>{color}</span>
        </Tooltip>
      </>
    );
  }

  function selectedComponent(deal) {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {props.allAvaliable ? (
          <RadioButton
            checked={deal.id === currentSelected}
            onChange={() => handleClick(deal)}
            name="vehicleRadio"
            aria-label={deal.id}
          />
        ) : !deal.available ? (
          <Tooltip title={`Indisponível`} placement="top">
            <IconComponent>
              <HighlightOffIcon />
            </IconComponent>
          </Tooltip>
        ) : (
          <RadioButton
            checked={deal.id === currentSelected}
            onChange={() => handleClick(deal)}
            name="vehicleRadio"
            aria-label={deal.id}
          />
        )}
      </div>
    );
  }

  function iconsStatus(currentDeal) {
    return (
      <IconsLine>
        {!currentDeal.available ? (
          <Tooltip title={'Indisponível'} placement="top">
            <PaidChip className="not-paid">
              <CloseIcon />
            </PaidChip>
          </Tooltip>
        ) : (
          <Tooltip title={'Disponivel'} placement="top">
            <PaidChip className="paid">
              <CheckIcon />
            </PaidChip>
          </Tooltip>
        )}

        {!currentDeal.oemPaid ? (
          <Tooltip title={'Não Pago'} placement="top">
            <PaidChip className="not-paid">
              <NotPaidIcon />
            </PaidChip>
          </Tooltip>
        ) : (
          <Tooltip title={'Pago'} placement="top">
            <PaidChip className="paid">
              <PaidIcon />
            </PaidChip>
          </Tooltip>
        )}

        {!currentDeal.booked ? (
          <Tooltip title={'Sem reserva'} placement="top">
            <Chip className="disabled-cone">
              <ConeIconSvg />
            </Chip>
          </Tooltip>
        ) : (
          <Tooltip
            title={`Veículo reservado até o dia ${moment(
              currentDeal.bookedAt
            ).format('DD/MM/YY [às] HH:mm')}`}
            placement="top"
          >
            <Chip
              className="active"
              onClick={() =>
                setOpenBookDetail({
                  open: true,
                  currentDeal: currentDeal,
                })
              }
            >
              <ConeIconSvg />
            </Chip>
          </Tooltip>
        )}

        {!currentDeal.withOrder ? (
          <Tooltip title={'Sem pedido'} placement="top">
            <Chip className="disabled">
              <AssignmentTurnedInIcon />
            </Chip>
          </Tooltip>
        ) : (
          <Tooltip title={'Com pedido'} placement="top">
            <Chip
              className="active"
              onClick={() =>
                setOpenOrderDetail({ open: true, currentDeal: currentDeal })
              }
            >
              <AssignmentTurnedInIcon />
            </Chip>
          </Tooltip>
        )}

        {!currentDeal.inTransit ? (
          <Tooltip title={''} placement="top">
            <Chip className="disabled">
              <LocalShippingIcon />
            </Chip>
          </Tooltip>
        ) : (
          <Tooltip title={'Em trânsito'} placement="top">
            <Chip className="inTransit">
              <LocalShippingIcon />
            </Chip>
          </Tooltip>
        )}
        {currentDeal?.externalWarning ? (
          <Tooltip title={'Aviso'} placement="top">
            <Chip
              className="warning"
              onClick={() => {
                setWarningDetail({
                  open: true,
                  currentDeal: currentDeal,
                });
              }}
            >
              <FlagOutlined />
            </Chip>
          </Tooltip>
        ) : (
          <Tooltip title={'Aviso'} placement="top">
            <Chip className="disabled">
              <FlagOutlined />
            </Chip>
          </Tooltip>
        )}

        {currentDeal.blocked ? (
          <Tooltip title={'Bloqueado'} placement="top">
            <PaidChip
              className="not-paid"
              onClick={() =>
                setOpenBlokedDetail({
                  open: true,
                  currentDeal: currentDeal,
                })
              }
            >
              <LockIcon />
            </PaidChip>
          </Tooltip>
        ) : (
          <Tooltip title={'Desbloqueado'} placement="top">
            <PaidChip
              className="paid"
              onClick={() =>
                setOpenBlokedDetail({
                  open: true,
                  currentDeal: currentDeal,
                })
              }
            >
              <LockOpenIcon />
            </PaidChip>
          </Tooltip>
        )}
      </IconsLine>
    );
  }

  const columns = [
    {
      field: 'plate',
      headerName: 'Placa/Chassi',
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => plateAndVinComponent(params.value),
      hide: false,
    },
    {
      field: 'vehicle',
      headerName: 'Veículo',
      flex: 0.2,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => colorComponent(params.value),
      hide: false,
    },
    {
      field: 'year',
      headerName: 'Ano',
      width: 80,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => colorComponent(params.value),
      hide: false,
    },
    {
      field: 'price',
      headerName: 'Preço do anúncio',
      width: 150,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      hide: true,
    },
    {
      field: 'salePrice',
      headerName: 'Preço de venda',
      width: 150,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      hide: false,
    },
    {
      field: 'minimumPrice',
      headerName: 'Preço mínimo',
      width: 100,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      hide: true,
    },
    {
      field: 'bonusValue',
      headerName: 'Bônus',
      width: 100,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      hide: true,
    },
    {
      field: 'km',
      headerName: 'Km',
      width: 80,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      hide: false,
    },
    {
      field: 'color',
      headerName: 'Cor',
      flex: 0.1,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => colorComponent(params.value),
      hide: false,
    },
    {
      field: 'dealerName',
      headerName: 'Loja',
      flex: 0.1,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => colorComponent(params.value),
      hide: false,
    },
    {
      field: 'inStockDate',
      headerName: 'Dias em estoque',
      flex: 0.1,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      hide: false,
    },
    {
      field: 'manufacturingPackageCode',
      headerName: 'Código da montadora',
      flex: 0.1,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => colorComponent(params.value),
      hide: false,
    },
    {
      field: 'accessories',
      headerName: 'Acessórios',
      width: 150,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => colorComponent(params.value),
      hide: false,
    },
    {
      field: 'icons',
      headerName: 'Status',
      width: 250,
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => iconsStatus(params.value),
    },
    {
      field: 'actions',
      headerName: ' ',
      headerNameFilterLabel: 'Ações',
      headerClassName: 'tabel-header',
      cellClassName: 'tabel-body',
      renderCell: params => selectedComponent(params.value),
    },
  ];

  const [dynamicColumns, setDynamicColumns] = useState(columns);

  const getDiffDays = inStockDate => {
    const today = moment();
    const diffDate = moment(inStockDate);
    return today.diff(diffDate, 'days');
  };

  const rows = dealsList.map(currentDeal => ({
    id: `${currentDeal.id}`,
    plate: currentDeal?.plate || currentDeal?.vin,
    vehicle: currentDeal?.externalVehicleName
      ? currentDeal?.externalVehicleName
      : `${currentDeal.makeName} ${
          currentDeal.modelName
        } ${currentDeal?.externalVehicle || currentDeal.trimName}`,
    createdAt: moment(currentDeal?.createdAt).format('DD/MM/YYYY'),
    type: currentDeal.purchaseType || '-',
    year: `${String(currentDeal?.productionYear).substring(2)}/${String(
      currentDeal?.modelYear
    ).substring(2)}`,
    salePrice:
      currentDeal?.salePrice || currentDeal?.vehiclePrice || currentDeal?.price,
    price: currentDeal?.price,
    minimumPrice: currentDeal?.minimumPrice?.toLocaleString() || 0,
    bonusValue: currentDeal?.bonusValue?.toLocaleString() || 0,
    km: `${currentDeal?.km?.toLocaleString()}km`,
    color: currentDeal?.exteriorColor || currentDeal.colorName,
    dealerName: currentDeal?.dealerName,
    inStockDate: currentDeal?.inStockDate
      ? getDiffDays(currentDeal?.inStockDate)
      : '',
    manufacturingPackageCode: currentDeal?.manufacturingPackageCode,
    accessories: currentDeal?.accessories,
    icons: currentDeal,
    actions: currentDeal,
  }));

  const [sortModel] = useState<GridSortModel>([
    {
      field: 'inStockDate',
      sort: 'desc' as GridSortDirection,
    },
  ]);

  if (
    !(products || []).some(product => product.type === 'PROPOSAL_APPLICATION')
  ) {
    //REMOVE_COLUMN_MANUFACTURING_PACKAGE_CODE_AND_STATUS
    columns.splice(7, 2);
  }

  useEffect(() => {
    setPage(page + 1);
  }, [dealsList.length]);

  useEffect(() => {
    const savedColumns: any = JSON.parse(
      localStorage.getItem('selectedColumns')
    );

    const updatedColumns = columns.map((column, index) => ({
      ...column,
      hide: savedColumns?.[index]?.hide ?? column?.hide,
    }));

    if (updatedColumns) {
      setDynamicColumns(updatedColumns);
    }
  }, [currentSelected]);

  return (
    <div style={{ height: 400, width: '100%', background: 'white' }}>
      <ViewColumns
        columns={dynamicColumns}
        setDynamicColumns={setDynamicColumns}
        mini
      />
      <DataGrid
        rows={rows}
        columns={dynamicColumns}
        page={currentPage}
        pageSize={dealsList.length}
        onPageChange={newPage => {
          setCurrentPage(newPage.page);
        }}
        rowCount={rowCount}
        paginationMode="server"
        pagination
        disableSelectionOnClick
        columnBuffer={2}
        sortModel={sortModel}
        loading={loading}
      />
      {openBookDetail.open && (
        <BookDetailDialog
          open={openBookDetail.open}
          onClose={() => setOpenBookDetail({ open: false, currentDeal: null })}
          currentDeal={openBookDetail.currentDeal}
          onBookCancelled={props.searchFunction}
          onClick={event => event.stopPropagation()}
        />
      )}
      {openOrderDetail.open && (
        <OrderDetailDialog
          open={openOrderDetail.open}
          onClose={() => setOpenOrderDetail({ open: false, currentDeal: null })}
          currentDeal={openOrderDetail.currentDeal}
          callbackOnSuccess={props.searchFunction}
        />
      )}
      {openBlokedDetail.open && (
        <BlockedDetailsDialog
          open={openBlokedDetail.open}
          onClose={() =>
            setOpenBlokedDetail({ open: false, currentDeal: null })
          }
          currentDeal={openBlokedDetail.currentDeal}
          callbackOnSuccess={props.searchFunction}
        />
      )}
      {openWarningDetail.open && (
        <WarningDetailDialog
          open={openWarningDetail.open}
          onClose={() => setWarningDetail({ open: false, currentDeal: null })}
          currentDeal={openWarningDetail?.currentDeal}
        />
      )}
    </div>
  );
}

export default TableView;
