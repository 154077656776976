import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 280px);
  overflow-y: auto;
`;

export const ContainerIframeModal = styled.div`
  overflow-x: hidden;
`;
