export enum userTypes {
  OWNER = 'OWNER',
  MANAGER = 'SALES_MANAGER',
  SELLER = 'SELLER',
}

export type allowedTypes = userTypes.MANAGER | userTypes.SELLER;

export type ProposalInfosType = {
  totalNewers: number | null;
  totalInNegociation: number | null;
  totalSold: number | null;
  totalNotSold: number | null;
  visitScheduled: number | null;
};

export type ConvertionChartType = {
  month: string;
  totalProposals: number;
  totalSold: number;
  convertion: number;
};

export type ActivityType = {
  proposalId: number;
  proposalStatus: string;
  clientUserName: string;
  message: string;
  updatedAt: number;
};

export type ScheduleItemType = {
  active: boolean;
  reminderDate: number;
  reminderMessage: string;
};

export type ProvidersType = {
  providerId: number;
  proposals: number;
};

export type SellersType = {
  sellerId: number;
  sellerName: string;
  convertion: number;
  sla: number;
};

export interface LeadsState {
  sellerId: number | undefined;
  sellerName: string | undefined;
  currentUserType: allowedTypes | undefined;
  displayedVision: allowedTypes | undefined;
  initialAnnotationsIndex: number | null;
  proposalsInfos: ProposalInfosType;
  proposalsInfosIsLoading: boolean;
  proposalsInfosIsError: any;
  schedule: number[];
  activities: ActivityType[];
  convertion: ConvertionChartType[];
  scheduleInfos: ScheduleItemType[];
  providers: ProvidersType[];
  sellers: SellersType[];
  tabValue: number;
  activitiesIsLoading: boolean;
  convertionIsLoading: boolean;
  convertionIsError: any;
  scheduleIsLoading: boolean;
  scheduleInfosIsLoading: boolean;
  providersIsLoading: boolean;
  providersIsError: any;
  sellersIsLoading: boolean;
  sellersIsError: any;
  scheduleIsError: any;
  activitiesIsError: any;
  filterByDates: {
    initialDate: number;
    finalDate: number;
  };
  filterByDatesQualified: {
    initialDate: number;
    finalDate: number;
    periodId: string;
  };
  filterSalesAssistant: {
    salesAssistantId: number;
  };
  channel: Array<{
    label: string;
    value: string | number;
  }>;
  filterTypeOfSale: string;
  channelOptions: Array<{
    label: string;
    value: string | number;
  }>;
  origin: Array<{
    label: string;
    value: string | number;
  }>;
  tags: Array<{
    label: string;
    value: string | number;
  }>;
  originOptions: Array<{
    label: string;
    value: string | number;
  }>;
  filterByDealerId: string | number;
  filterBySubGroups: string | number;
  departmentCode: string;
  departmentOptions: Array<{
    label: string;
    value: string | number;
  }>;
  dealers: string[];
}
