import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Chip as MuiChip,
} from '@material-ui/core';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  gap: 8px;
  &[data-has-sync-quotes='true'] {
    padding: 0 16px;
  }
`;

export const VehicleName = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.025em;
  text-align: left;
`;

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #757575;
  font-family: 'Poppins Medium';
`;

export const Chip = styled(MuiChip)`
  &.MuiChip-root {
    background: #ceedf3;
    height: 24px;
    border-color: #6bc8da;
  }

  & .MuiChip-label {
    color: #056274;
    font-size: 12px;
    font-family: 'Poppins Medium';
    padding: 0 8px;
  }
`;

export const ContainerExpandMore = styled.div`
  color: ${Colors.primary.medium};
`;

export const Accordion = withStyles({
  root: {
    '&$expanded': {
      margin: '0px',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionDetails = withStyles(() => ({
  root: {
    display: 'block',
    padding: '0px',
  },
}))(MuiAccordionDetails);

export const AccordionSummary = withStyles(() => ({
  root: {
    '&$expanded': {
      margin: '0px',
    },
    padding: 0,
  },
  content: {
    '&$expanded': {
      margin: '0px',
    },
  },
}))(MuiAccordionSummary);

export const CardWrapper = styled.div``;

export const AlertContainer = styled.div`
  background: #ceedf3;
  border: 1px solid #6bc8da;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  box-sizing: border-box;
  margin: 8px;
`;

export const IconContainer = styled.span`
  background-color: #6bc8da;
  font-size: 12px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
