/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useContext, useEffect, createContext } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import proposalService from 'services/proposalServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import { useChatContext } from 'pages/chats/ChatsContext';
import { useFormik } from 'formik';
import simulationSchema from '../components/Schema/simulationSchema';
import FinancingService from 'services/financingService';

const FinancingSimulationContext = createContext({});

const FinancingSimulationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    proposalStore,
    dealerStore: { currentDealer },
  } = useSelector(state => ({
    proposalStore: state.proposalStore,
    dealerStore: state.dealerStore,
  }));

  const fandiIntegration = currentDealer.externalDealerIdentification.find(
    externalDealer => externalDealer.externalProvider === 'FANDI'
  );

  const { currentProposal }: any = useChatContext();

  //CREATE_SIMULATION
  const [createSimulationState, setCreateSimulationState] = useState({
    selectVehicleDialogOpen: false,
    simulateResponse: null,
  });

  //HISTORY
  const [simulationHistoryState, setSimulationHistoryState] = useState({
    historyData: [],
  });

  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (_, newTab) => {
    setCurrentTab(newTab);
  };

  const handleChangeNewSimulationValues = ({ target }) => {
    const { name, value } = target;
    setCreateSimulationState(prevState => ({ ...prevState, [name]: value }));
  };

  const getSimulations = async () => {
    const { success, data }: any = fandiIntegration
      ? await proposalService.getSearchSimulations(
          currentDealer.id,
          proposalStore.currentProposal.proposalId
        )
      : await FinancingService.getFinancingSimulate({
          dealerId: currentDealer.id,
          proposalId: proposalStore.currentProposal.proposalId,
        });

    if (success) {
      setSimulationHistoryState(prevState => ({
        ...prevState,
        historyData: data || [],
      }));
    }
  };

  const getSimulationsQueryResult = useQuery(
    ['getSimulations', currentTab],
    getSimulations,
    {
      // The query will not execute until the userId exists
      enabled: currentTab > 0,
    }
  );

  useEffect(() => {
    if (currentTab === 1) {
      getSimulationsQueryResult.refetch();
    }
  }, [currentTab]);

  const calculeSimulationMutation = useMutation(
    (): Promise<any> => {
      handleChangeNewSimulationValues({
        target: { name: 'simulateResponse', value: null },
      });

      const cleanCpf = createSimulateFormValues?.clientCpf.replace(
        /[./()-\s]/g,
        ''
      );

      return FinancingService.simulationFinancing({
        dealerId: currentDealer.id,
        proposalId: proposalStore.currentProposal.proposalId,
        ...(createSimulateFormValues?.vehicle?.trim?.id
          ? { trimId: createSimulateFormValues?.vehicle?.trim?.id }
          : { dealId: createSimulateFormValues?.vehicle?.id }),
        payload: {
          cpf: Number(cleanCpf),
          email: currentProposal?.clientEmail,
          name: currentProposal?.clientUserName,
          phone: currentProposal?.clientUserPhone,
          inputValue: createSimulateFormValues.inputValue,
          modelYear: createSimulateFormValues?.vehicle?.modelYear,
          vehiclePrice: createSimulateFormValues?.vehiclePrice,
        },
      });
    },
    {
      onSuccess: (data: { success: boolean; data: any }) => {
        if (data.success) {
          handleChangeNewSimulationValues({
            target: { name: 'simulateResponse', value: data.data },
          });
        } else {
          dispatch(UIActionCreators.snackbarManagement('error', data?.data));
        }
      },
    }
  );

  // const handleClickInterested = useMutation(
  //   (): boolean => {
  //     return;
  //   },
  //   {
  //     onSuccess: (data: { success: boolean; data: any }) => {
  //       if (data.success) {
  //         dispatch(
  //           UIActionCreators.snackbarManagement(
  //             'success',
  //             '🤩 Simulação realizada com sucesso'
  //           )
  //         );
  //       } else {
  //         dispatch(UIActionCreators.snackbarManagement('error', data?.data));
  //       }
  //     },
  //   }
  // );

  const initialCPFValue = currentProposal?.clientUserCpfCnpj?.replace(
    /\D/g,
    ''
  );

  const {
    errors: formErros,
    values: createSimulateFormValues,
    handleChange: handleChangeSimulateForm,
    touched: formTouched,
    handleBlur,
    submitForm: handleClickCalculateSimulation,
    resetForm,
  } = useFormik({
    initialValues: simulationSchema.cast({
      clientCpf: initialCPFValue || 0,
    }),
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: simulationSchema,
    enableReinitialize: true,
    onSubmit: () => {
      try {
        calculeSimulationMutation.mutate();
      } catch (e) {
        console.log('>>>> error', e);
      }
    },
  });

  const handleClickInterested = {
    mutate: () => {
      dispatch(
        UIActionCreators.snackbarManagement(
          'success',
          '🤩 Simulação realizada com sucesso'
        )
      );
    },
  };

  const contextValues = {
    //CREATE
    formErros,
    formTouched,
    handleBlur,
    createSimulateFormValues,
    createSimulationState,
    handleChangeSimulateForm,
    handleChangeNewSimulationValues,
    handleClickCalculateSimulation,
    resetForm,
    calculeSimulationMutation,
    handleClickInterested,
    //HISTORY
    simulationHistoryState,
    getSimulationsQueryResult,
    getSimulations,
    //CHECK_SIMULATION_TYPE
    financingBancoPanEnabled: currentDealer.financingBancoPanEnabled,
    //TABS_MANAGE
    currentTab,
    handleChangeTab,
  };

  return (
    <FinancingSimulationContext.Provider value={contextValues}>
      {children}
    </FinancingSimulationContext.Provider>
  );
};

function useFinancingSimulationContext() {
  const context = useContext(FinancingSimulationContext);
  return context;
}

export { FinancingSimulationProvider, useFinancingSimulationContext };
