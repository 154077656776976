import styled from 'styled-components';

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #424242;
  margin-top: 8px;
`;

export const EmptyIcon = styled.div`
  background: #e0f3f1;
  width: 64px;
  height: 64px;
  border-radius: 55px;
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const TextMessage = styled.div`
  margin: 8px 0px;
  font-size: 14px;
`;
