import styled from 'styled-components';
import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const Container = styled.div`
  display: flex;
  margin: 16px 0;
  padding: 16px;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  cursor: pointer;
  &[data-is-selected='true'] {
    border: 1px solid #00b2a9;
  }
  &[data-is-waiting-results='true'] {
    cursor: initial;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  & .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
  & .MuiRadio-colorSecondary.Mui-checked {
    color: #00b2a9;
  }
`;

export const ContainerBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  letter-spacing: 0.025em;
  text-align: left;
  color: ${Colors.primary.medium};
`;

export const TextDate = styled.span`
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #757575;
`;

export const Text = styled.span`
  white-space: nowrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #757575;
`;

export const Line = styled.div`
  display: -webkit-inline-box;
`;

export const TextResponse = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
  color: #333333;
  margin-left: 3px;
`;

export const Id = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 400;
  justify-content: center;
  color: #757575;
`;

export const StyledHr = styled.hr`
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 16px 0;
`;
