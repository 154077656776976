/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Text from 'modules/mobiOne/components/Text';
import { InfoOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

const AlertEvaluationStatus = ({ text }): JSX.Element => {
  return (
    <>
      <Alert
        icon={<InfoOutlined style={{ color: '#967748' }} fontSize="inherit" />}
        severity="warning"
      >
        <Text fontSize="14px" color="#967748">
          {text}
        </Text>
      </Alert>
    </>
  );
};

export default AlertEvaluationStatus;
