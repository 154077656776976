import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { FilterOption } from 'pages/proposals/tabs/PopoverFilters/interfaces';
import useCustomMutation from 'pages/proposals/tabs/PopoverFilters/hooks/useCustomMutation';
import dealerGroupServices from 'services/dealerGroupServices';
import useSnackBar from 'hooks/useSnackBar';

interface Props {
  apiProperty: string;
  selectedValue?: string;
}

export default function useDepartments({ apiProperty, selectedValue }: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<FilterOption | null>(
    null
  );

  const { handleSnackBarError } = useSnackBar();
  const { currentGroup } = useSelector(state => state.callCenter);
  const groupId = currentGroup.id;

  function useGetGroupDepartment() {
    return useCustomMutation({
      key: 'getGroupDepartment',
      handleReturn: {
        onError: () => {
          handleSnackBarError('Ocorreu um erro ao consultar "Departamentos".');
        },
      },
      request: async () =>
        dealerGroupServices
          .getDepartmentsByGrupId(groupId)
          .then(response => response?.data),
    });
  }

  const {
    data,
    isLoading,
    mutate: getGroupDepartment,
  } = useGetGroupDepartment();

  useEffect(() => {
    if (options.length && selectedValue) {
      const selected = options.find(option => option.value === selectedValue);
      setSelectedOption(selected);
    }
  }, [options, selectedValue]);

  useEffect(() => {
    if (data) {
      const formatted: FilterOption[] = data?.map(el => ({
        filterGroupId: apiProperty,
        id: el?.id,
        label: el?.name,
        value: el?.code,
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOption,
    getGroupDepartment,
    isLoading,
  };
}
