import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useChatContext } from 'pages/chats/ChatsContext';
import ThemeButton from 'components/atoms/ThemeButton';
import TextInputMessage from './components/TextInputMessage';
import ViewText from './components/ViewText';
import ChannelTabs from './components/ChannelTabs';
import { ReactComponent as EmptyTemplateMessages } from 'assets/empty-messages-template.svg';
import MessageTemplateDialog from './components/TextInputMessage/components/MessageTemplateDialog';
import proposalIsFinished from 'pages/chats/helpers/proposalIsFinished';
import { Container, EmptyContainer, EmptyIcon } from './styles';

const ChatComponent = () => {
  const { currentDealer } = useSelector(({ dealerStore }) => dealerStore);
  const {
    otherStates: {
      currentChannel,
      canSendWhatsappBusinessMessage,
      currentDealerProducts,
    },
    handleOnWhatsApp,
    handleWhatsappEvent,
    currentProposal,
  }: any = useChatContext();

  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const hasWhatsappProduct = useMemo(
    () =>
      currentDealerProducts?.find(product => product.type === 'WHATSAPP') ||
      currentDealer.products?.find(product => product.type === 'WHATSAPP'),
    [currentDealer.products, currentDealerProducts]
  );

  return (
    <Container>
      <ChannelTabs hasWhatsappProduct={hasWhatsappProduct} />
      
      {currentChannel === 'CHAT' ? (
        <>
          <ViewText currentChannel={'CHAT'} key={'CHAT'} />
          <TextInputMessage disabled={proposalIsFinished(currentProposal)} />
        </>
      ) : (
        <>
          {hasWhatsappProduct ? (
            <>
              <ViewText
                currentChannel={'WHATSAPP'}
                key={'WHATSAPP'}
                canSendWhatsappBusinessMessage={canSendWhatsappBusinessMessage}
              />
              <TextInputMessage
                canSendWhatsappBusinessMessage={canSendWhatsappBusinessMessage}
                disabled={proposalIsFinished(currentProposal)}
              />
            </>
          ) : (
            <>
              <EmptyContainer>
                <EmptyIcon>
                  <EmptyTemplateMessages />
                </EmptyIcon>
                <span>Sem histórico de mensagens.</span>
              </EmptyContainer>
              <div style={{ padding: 8 }}>
                <ThemeButton
                  onClick={() => setOpenTemplateDialog(true)}
                  fullWidth
                >
                  Ir para Whatsapp Web
                </ThemeButton>
                {openTemplateDialog && (
                  <MessageTemplateDialog
                    open={openTemplateDialog}
                    handleOnClose={() => setOpenTemplateDialog(false)}
                    sendMessage={message => {
                      handleWhatsappEvent();
                      handleOnWhatsApp(message);
                      setOpenTemplateDialog(false);
                    }}
                    currentChannel={'WHATSAPP'}
                  />
                )}
              </div>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default React.memo(ChatComponent);
