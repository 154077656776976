import React, { SyntheticEvent } from 'react';
import ItemHeader from '../atoms/ItemHeader';
import StyledTabs from 'components/StyledComponents/StyledTabs';
import StyledTab from 'components/StyledComponents/StyledTab';
import TabPanel from 'components/StyledComponents/TabPanel';
import { CardList, Container, HistoryContainer } from './style';
import SimulationForm from './components/SimulationForm';
import HistoryCard from './components/HistoryCard';
import ScrollView from 'components/ScrollView';
import DetailsCard from './components/DetailsCard';
import formatAmount from 'helpers/formatAmount';
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from 'react-query';

interface DownPaymentSimulationProps {
  formatIdSimulation: (id: number) => string;
  formatTimestamp: (timestamp: number) => string;
  currentTab: number;
  setCurrentTab: React.Dispatch<React.SetStateAction<number>>;
  selectedSimulation: any;
  setSelectedSimulation: React.Dispatch<any>;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  simulations: any;
  refetchSimulations: <TPageData>(
    options?: RefetchOptions & RefetchQueryFilters<TPageData>
  ) => Promise<QueryObserverResult<any, unknown>>;
}

const DownPaymentSimulationComponent = ({
  formatIdSimulation,
  formatTimestamp,
  currentTab,
  setCurrentTab,
  selectedSimulation,
  setSelectedSimulation,
  openModal,
  setOpenModal,
  simulations,
  refetchSimulations,
}: DownPaymentSimulationProps) => {
  const disabled = true;

  const handleChangeTab = (_event: SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab);
  };

  const handleOpenDetails = (id: number) => {
    const detailSimulation = simulations?.find(
      (simulation: any) => simulation.id === id
    );

    setSelectedSimulation(detailSimulation);

    setOpenModal(true);
  };

  const reversedOrderSimulations = simulations?.slice().reverse();

  return (
    <>
      <ItemHeader>Simulação de entrada</ItemHeader>
      <Container>
        <StyledTabs
          value={currentTab}
          onChange={handleChangeTab}
          variant="fullWidth"
        >
          <StyledTab value={0} label="Simulação" />
          <StyledTab value={1} label="Histórico" />
        </StyledTabs>
        <TabPanel value={currentTab} index={0} key={'Simulação'}>
          <SimulationForm refetchSimulations={refetchSimulations} />
        </TabPanel>
        <TabPanel value={currentTab} index={1} key={'Histórico'}>
          <HistoryContainer>
            <ScrollView>
              <CardList>
                {reversedOrderSimulations?.map((item: any) => (
                  <HistoryCard
                    id={formatIdSimulation(item.id)}
                    date={formatTimestamp(item.createdAt)}
                    partner={item.name}
                    amount={formatAmount(item.downPayment, 'PT-BR', 'R$')}
                    handleOpenDetails={handleOpenDetails}
                  />
                ))}
              </CardList>
            </ScrollView>
          </HistoryContainer>
        </TabPanel>
        <DetailsCard
          openModal={openModal}
          setOpenModal={setOpenModal}
          disabled={disabled}
          selectedSimulation={selectedSimulation}
          formatIdSimulation={formatIdSimulation}
          formatTimeStamp={formatTimestamp}
        />
      </Container>
    </>
  );
};

export default DownPaymentSimulationComponent;
