import {
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import * as React from 'react';
import { ReactComponent as PlusIcon } from 'assets/plus.svg';
import Wrapper from 'components/Wrapper';
import { WrapperButton } from 'components/NewCar/styles';

export default function ButtonPopper({
  options,
  openPopper,
  handleOpenPopper,
  handleClosePopper,
  handleMenuItemClick,
}) {
  const anchorRef = React.useRef<HTMLDivElement>(null);

  return (
    <ClickAwayListener onClickAway={() => handleClosePopper()}>
      <div>
        <WrapperButton
          ref={anchorRef}
          onClick={() => {
            if (!openPopper) return handleOpenPopper();

            return handleClosePopper();
          }}
        >
          <Wrapper
            flex
            gap="sm"
            align="center"
            justify="center"
            my={4}
            mx="md"
            color="white"
          >
            <PlusIcon />
            Novo anúncio
            <ArrowDropDown />
          </Wrapper>
        </WrapperButton>
        <Popper
          style={{
            //one-more-than-header
            zIndex: 1101,
          }}
          open={openPopper}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'top right',
              }}
            >
              <Paper>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options?.map(option => (
                    <MenuItem
                      style={{
                        paddingBottom: '8px',
                        paddingTop: '8px',
                      }}
                      key={option}
                      onClick={() => {
                        handleMenuItemClick(option);
                        handleClosePopper();
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}
