import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as proposalCreators } from 'modules/resale/store/ducks/proposal';
import proposalServices from 'services/proposalServices';
import validateCpfCnpj from 'helpers/validateCpfCnpj';
import ConfirmStatusShowroom from './View/ConfirmStatusDialog';
import ConfirmStatusCallCenter from './View/ConfirmStatusCallCenter';
import isEmpty from 'helpers/isEmpty';

const buildStatusDetailList = statusList => {
  const statusListTemp = [];
  statusList
    .sort(function(a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    })
    .map(item =>
      statusListTemp.push({ label: item.name, value: item.id, name: item.name })
    );
  return statusListTemp;
};

const ConfirmStatusDialogContainer = ({
  isOpen,
  confirmStatus,
  getProposalStatusDetail,
  isCallCenter = false,
  isOem = false,
  draggableItemId,
  departmentId = null,
  onClose,
  clientUserCpfCnpj,
  enableOrderId = false,
  currentProposalId,
  titleModal = `Mover para "Concluído"`,
}) => {
  const dispatch = useDispatch();

  const { shouldFinishProposalWithoutOwner } = useSelector(
    state => state.dealerStore.currentDealer
  );

  const [state, setState] = useState({
    statusList: [],
    finishedStatus: null,
    statusOptionsList: [],
    statusListIsLoading: false,
    isLoading: false,
    orderId: '',
    orderCpfCnpj: clientUserCpfCnpj || '',
    reason: null,
    reasonText: '',
    reasonError: false,
    reasonSelectEmpty: false,
    apiErrorMessage: null,
  });

  const getStatus = async () => {
    try {
      setState(prevState => ({ ...prevState, statusListIsLoading: true }));
      const { success, data } = await proposalServices.getProposalStatus(
        draggableItemId,
        isCallCenter,
        departmentId,
        isOem,
        currentProposalId
      );
      if (success) {
        setState(prevState => ({
          ...prevState,
          statusList: data,
          statusListIsLoading: false,
        }));
      }
    } catch {
      setState(prevState => ({ ...prevState, statusListIsLoading: false }));
    }
  };

  const getStatusDetail = async () => {
    setState(prevState => ({ ...prevState, statusListIsLoading: true }));
    const statusList = await getProposalStatusDetail(draggableItemId);
    const reasonList = buildStatusDetailList(statusList);
    setState(prevState => ({
      ...prevState,
      statusListIsLoading: false,
      statusOptionsList: reasonList,
    }));
  };

  const verificationCpfCnpj = () => {
    const cpfCnpjError = !validateCpfCnpj(state.orderCpfCnpj.replace('_', ''));
    setState(prevState => ({ ...prevState, cpfCnpjError }));
  };

  const validateOwner = () => {
    if (shouldFinishProposalWithoutOwner === false) {
      const noHasOwner = isEmpty(state.orderId) && isEmpty(state.orderCpfCnpj);
      if (noHasOwner) {
        setState(prevState => ({
          ...prevState,
          ownerRequiredError: true,
        }));
        return true;
      }
      setState(prevState => ({
        ...prevState,
        ownerRequiredError: false,
      }));
      return false;
    }
    return false;
  };

  const handleChangeInput = newValues => {
    setState(prevState => ({
      ...prevState,
      ...newValues,
    }));
  };

  const handleConfirmChangeStatus = async () => {
    if (!state.finishedStatus) {
      return;
    }
    const extraData = {
      orderCpfCnpj: state.orderCpfCnpj,
      orderId: state.orderId,
      statusDetailId: state.reason?.value,
      reasonNotToSell: state.reasonText,
    };

    if (
      !isCallCenter &&
      ['FINISHED_AND_CONCLUDED', 'WITH_ORDER'].includes(state.finishedStatus)
    ) {
      //CHECK_CPF/CNPJ_IS_VALID_AND_CHECK_OWNER_REQUIRED
      if (state?.cpfCnpjError || validateOwner()) {
        return;
      }
    }

    //CHECK_RESON_SELECT_EMPTY
    if (
      ['FINISHED_BUT_NOT_CONCLUDED', 'DISQUALIFIED'].includes(
        state.finishedStatus
      )
    ) {
      if (!state.reason) {
        setState(prevState => ({
          ...prevState,
          reasonSelectEmpty: true,
        }));
        return;
      }
      //CHECK_RESON_TEXT_EMPTY
      if (
        state.reason.label.toLowerCase() === 'outros' &&
        !state.reasonText?.length
      ) {
        setState(prevState => ({
          ...prevState,
          reasonError: true,
        }));
        return;
      }
    }

    setState(prevState => ({ ...prevState, isLoading: true }));
    const errorMessage = await confirmStatus(state.finishedStatus, extraData);
    if (errorMessage) {
      setState(prevState => ({
        ...prevState,
        apiErrorMessage: errorMessage,
        isLoading: false,
      }));
      return;
    }
    setState(prevState => ({ ...prevState, isLoading: false }));

    onClose({}, true);

    if (
      !isCallCenter &&
      ['FINISHED_BUT_NOT_CONCLUDED'].includes(state.finishedStatus)
    ) {
      dispatch(proposalCreators.setProposalModalOpen(false));
    }
  };

  useEffect(() => {
    if (
      !isCallCenter &&
      ['FINISHED_AND_CONCLUDED', 'WITH_ORDER'].includes(state.finishedStatus)
    ) {
      if (state.orderCpfCnpj?.length) {
        verificationCpfCnpj();
      }
      validateOwner();
    }
  }, [state.orderCpfCnpj, state.orderId]);

  useEffect(() => {
    if (state.statusList.length === 0) {
      getStatus();
      getStatusDetail();
    }
  }, []);

  const viewComponentProps = {
    isOpen,
    onClose,
    reason: state.reason,
    statusList: state.statusList,
    orderCpfCnpj: state.orderCpfCnpj,
    cpfCnpjError: state.cpfCnpjError,
    finishedStatus: state.finishedStatus,
    statusOptionsList: state.statusOptionsList,
    statusListIsLoading: state.statusListIsLoading,
    reasonText: state.reasonText,
    isLoading: state.isLoading,
    apiErrorMessage: state.apiErrorMessage,
    ownerRequiredError: state.ownerRequiredError,
    enableOrderId,
    reasonError: state.reasonError,
    reasonEmpty: !state.reason,
    handleChangeInput,
    handleConfirmChangeStatus,
    titleModal,
    orderId: state.orderId,
  };

  if (isCallCenter) {
    return <ConfirmStatusCallCenter {...viewComponentProps} />;
  }

  return <ConfirmStatusShowroom {...viewComponentProps} />;
};

export default ConfirmStatusDialogContainer;
