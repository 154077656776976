import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  tooltip: {
    fontSize: '10px',
  },
});

function DefaultTooltip({ title, placement, classes, ...props }) {
  return (
    <Tooltip
      title={title}
      placement={placement}
      classes={{ tooltip: classes.tooltip, customWidth: classes.customWidth }}
    >
      {props.children}
    </Tooltip>
  );
}

export default withStyles(styles)(DefaultTooltip);
