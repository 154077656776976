import React, { useEffect, useState } from 'react';
import { ListQuotesProps } from '../../types';
import ComponentMyQuotes from './ComponentMyQuotes';
import useInsuranceQuotes from '../../hooks/useInsuranceQuotes';
import useSnackBar from 'hooks/useSnackBar';

const ContainerMyQuotes = () => {
  const {
    dealerId,
    proposalId,
    getQuotesByProposalIdData,
    getQuotesByProposalIdIsLoading,
    getQuotesByProposalIdRefetch,
    selectInsuranceQuoteMutante,
    selectInsuranceQuoteIsLoading,
  } = useInsuranceQuotes();

  const [listQuotes, setListQuotes] = useState<ListQuotesProps[]>();
  const [selectedQuote, setSelectedQuote] = useState<number | null>(null);
  const snackbar = useSnackBar();

  const hasSyncQuotes =
    listQuotes !== undefined &&
    listQuotes.some(item => item.quotes.some(quote => quote.status === 'NEW'));

  const TransformeListQuotes = dataQuotes => {
    const transformedData = {};

    if (typeof dataQuotes === 'object') {
      dataQuotes.forEach(item => {
        if (!transformedData[item.vehicle]) {
          transformedData[item.vehicle] = {
            proposalId: item.proposalId,
            vehicle: item.vehicle,
            quotes: [],
            waitingResults: item.status === 'NEW',
          };
        }

        transformedData[item.vehicle].quotes.push({
          financingInstitution: item.financingInstitution ?? null,
          status: item.status ?? null,
          quoteId: item.quoteId ?? null,
          value: item.value ?? null,
          quoteDate: item.quoteDate ?? null,
          name: item.name ?? null,
          cpfCnpj: item.cpfCnpj ?? null,
          coverType: item.coverType ?? null,
        });
      });
    }

    setListQuotes(Object.values(transformedData));
  };

  const handleOnClickInsuranceQuote = (insuranceId: number) => {
    selectInsuranceQuoteMutante(
      {
        dealerId,
        proposalId,
        insuranceId,
      },
      {
        onSuccess: () => {
          setSelectedQuote(insuranceId);

          snackbar.success('Cotação selecionada com sucesso.');
        },
      }
    );
  };

  useEffect(() => {
    if (
      getQuotesByProposalIdData?.data instanceof Array &&
      getQuotesByProposalIdData?.data?.length
    ) {
      TransformeListQuotes(getQuotesByProposalIdData?.data);
      const initialInsuranceQuoteSelected = getQuotesByProposalIdData.data?.find(
        item => item.isSelected
      );

      setSelectedQuote(initialInsuranceQuoteSelected?.id);
    }
  }, [getQuotesByProposalIdData?.success]);

  useEffect(() => {
    getQuotesByProposalIdRefetch();
  }, []);

  const viewComponentProps = {
    getQuotesByProposalIdIsLoading,
    selectInsuranceQuoteIsLoading,
    listQuotes,
    hasSyncQuotes,
    selectedQuote,
    handleOnClickInsuranceQuote,
  };

  return <ComponentMyQuotes {...viewComponentProps} />;
};

export default ContainerMyQuotes;
