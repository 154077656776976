import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import envVars from 'helpers/envVars';
import request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import { FilterOption } from '../interfaces';
import useCustomMutation from './useCustomMutation';

interface Props {
  apiProperty: string;
  selectedsValues?: { label?: string; value: string }[];
}

const domain = envVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'ProviderService';

export default function useOrigins({ apiProperty, selectedsValues }: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[] | null>(
    null
  );

  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => state.dealerStore);

  const dealerId = currentDealer.id;

  function useGetOriginsOptions() {
    return useCustomMutation({
      key: 'getOriginsOptions',
      handleReturn: {
        onError: () => {
          dispatch(
            UIActionCreators.snackbarManagement(
              'error',
              'Ocorreu um erro ao consultar "Origem".'
            )
          );
        },
      },
      request: async ({ leadCreation = false }: { leadCreation?: boolean }) => {
        const response = await request
          .get(
            `${domain}/dealer/${dealerId}/proposals/v1.0/origins`,
            leadCreation ? { action: 'CREATE_LEAD' } : {}
          )
          .then(response => {
            buildHTTPResponseHandler(`${serviceName}.getOriginsOptions()`);
            return response?.data as any;
          });
        return response;
      },
    });
  }

  const { data, isLoading, mutate: getOrigins } = useGetOriginsOptions();

  useEffect(() => {
    if (options?.length) {
      if (!selectedsValues.length && selectedOptions) {
        setSelectedOptions(null);
      }
      if (selectedsValues && selectedsValues.length) {
        const filtered = options.filter(option => {
          return selectedsValues.some(selected => {
            return selected.value === option.value;
          });
        });
        setSelectedOptions(filtered);
      }
    }
  }, [options, selectedsValues]);

  useEffect(() => {
    if (data) {
      const formatted: FilterOption[] = data?.map(el => ({
        filterGroupId: apiProperty,
        id: `${apiProperty}-${el?.id}`,
        label: el?.label,
        value: String(el?.name),
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOptions,
    getOrigins,
    isLoading,
  };
}
