import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import PROFILES from 'constants/profiles';
import { userTypes } from 'types/LeadsTypes';
import DealerServices from 'services/dealerServices';
import LeadsActionCreators from 'modules/resale/store/reducers/leads/actionCreators';
import {
  Types as UserTypes,
  Creators as UserCreators,
} from 'modules/resale/store/ducks/user';

function* getCurrentUserInfosByDealerId(action) {
  try {
    // SET_CURRENT_USER_TO_OWNER_IF_USER_IS_CONSULTO (@mobiauto.com.br)
    const { userProfile } = yield select(state => state.userStore);

    const response = yield call(
      DealerServices.getCurrentUserInfosByDealerId,
      action.dealerId
    );

    if (response.success) {
      yield put(
        LeadsActionCreators.setDepartment(
          response.data?.department?.code || null
        )
      );

      //SET_FILTER_SELLER_ID
      if (response?.data?.userType === userTypes.SELLER) {
        yield put(LeadsActionCreators.setSellerId(response.data?.userSite?.id));
      }

      if (userProfile === PROFILES.CONSULTOR) {
        yield put(
          UserCreators.setCurrentUser({
            department: {},
            ...response.data,
            userType: userTypes.OWNER,
          })
        );
        typeof action.callback === 'function' && action.callback();
        return;
      }

      yield put(
        UserCreators.setCurrentUser({ department: {}, ...response.data })
      );
      typeof action.callback === 'function' && action.callback();
      return;
    }
    yield put(
      UserCreators.setCurrentUser({
        userType:
          userProfile === PROFILES.CONSULTOR
            ? userTypes.OWNER
            : userTypes.SELLER,
      })
    );
    typeof action.callback === 'function' && action.callback();
  } catch (e) {
    const { userProfile } = yield select(state => state.userStore);
    yield put(
      UserCreators.setCurrentUser({
        userType:
          userProfile === PROFILES.CONSULTOR
            ? userTypes.OWNER
            : userTypes.SELLER,
      })
    );
  }
}

export default all([
  takeLatest(
    UserTypes.GET_CURRENT_USER_INFOS_BY_DEALER_ID,
    getCurrentUserInfosByDealerId
  ),
]);
