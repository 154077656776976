import { useMutation, useQueryClient } from 'react-query';
import useSnackBar from './useSnackBar';

export interface ApiResponse<T> {
  success?: boolean;
  data?: T | string;
  message?: string;
}

export interface HandleReturn {
  onSuccess?: <T>(params?: ApiResponse<T>) => void;
  onError?: <T>(params?: ApiResponse<T>) => void;
  showSuccessSnackbar?: boolean;
  successMessage?: string;
  showErrorSnackbar?: boolean;
  errorMessage?: string;
}

interface CustomMutation<Query, Response> {
  key: string;
  request: (params: Query) => Promise<Response>;
  handleReturn?: HandleReturn;
  queriesToInvalidate?: string[];
}

export default function useCustomMutation<Query, Response>({
  handleReturn = { showErrorSnackbar: true, showSuccessSnackbar: true },
  key,
  request,
  queriesToInvalidate,
}: CustomMutation<Query, ApiResponse<Response>>) {
  const queryClient = useQueryClient();
  const snackbar = useSnackBar();
  return useMutation({
    retry: 2,
    retryDelay: 2000,
    mutationKey: [key],
    mutationFn: async (params: Query) => {
      const response = await request(params);

      const error: ApiResponse<Response> = {
        message: response.data as string,
      };

      if (!response.success) {
        throw error;
      }

      return response;
    },
    onSuccess: (data: ApiResponse<Response>) => {
      if (queriesToInvalidate) {
        queriesToInvalidate.map(query => queryClient.invalidateQueries(query));
      }

      if (handleReturn?.successMessage) {
        snackbar.success(handleReturn.successMessage);
      }

      if (handleReturn?.onSuccess) return handleReturn?.onSuccess(data);
    },
    onError: (data: ApiResponse<Response>) => {
      if (handleReturn?.onError) handleReturn?.onError(data);

      if (handleReturn?.errorMessage) {
        snackbar.error(handleReturn.errorMessage);
      }
    },
  });
}
