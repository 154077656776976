import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Wrapper from 'components/Wrapper/Wrapper';
// import GenericDialog from 'components/GenericDialog';
import ThemeButton from 'components/atoms/ThemeButton';
import { useChatContext } from 'pages/chats/ChatsContext';
// import { createInsuranceProposalParameter } from 'pages/chats/helpers/createInsuranceProposalParameter';
import OrderServices from 'modules/order/services/OrderServices';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';
import styled from 'styled-components';

export const ContainerIframeModal = styled.div`
  overflow-x: hidden;
`;

const InsuranceQuote: React.FC = () => {
  // const { currentDealer } = useSelector(state => state.dealerStore);
  // const { currentVehicle, buyerCurrentData } = useSelector(
  //   state => state.order
  // );
  const { currentProposal }: any = useChatContext();
  const dispatch = useDispatch();
  // const [openModalFandi, setOpenModalFandi] = useState(false);
  const [loadings, setLoadings] = useState({
    callToClientIsLoading: false,
    callToSpecialistIsLoading: false,
  });

  // const quiverPW = currentDealer.externalDealerIdentification.find(
  //   externalDealer => externalDealer.externalProvider === 'QUIVER'
  // )?.externalIdentification;

  // const onCloseModalFandi = () => {
  //   setOpenModalFandi(false);
  // };

  // const createParamsFrame = (): string => {
  //   return createInsuranceProposalParameter({
  //     id: currentProposal.id,
  //     vehicle: [currentVehicle],
  //     clientUserName: buyerCurrentData?.name,
  //     clientEmail: buyerCurrentData?.email,
  //     clientUserCpfCnpj: buyerCurrentData?.cpf,
  //     clientUserPhone: buyerCurrentData?.phone,
  //   });
  // };

  // const Model = () => {
  //   return (
  //     <GenericDialog
  //       open={openModalFandi}
  //       onClose={onCloseModalFandi}
  //       title={'Cotar Seguros'}
  //       maxWidth="md"
  //       OnCloseButton
  //     >
  //       <ContainerIframeModal>
  //         <iframe
  //           src={`https://www.corretor-online.com.br/canalcliente/index.htm?Pw=${quiverPW}&${createParamsFrame()}`}
  //           width={'100%'}
  //           style={{ height: 'calc(100vh - 130px)' }}
  //         />
  //       </ContainerIframeModal>
  //     </GenericDialog>
  //   );
  // };

  //***DISABLE_TEMPORALITY
  // const handleClickCallToClient = async () => {
  //   try {
  //     setLoadings(prevState => ({ ...prevState, callToClientIsLoading: true }));
  //     const response: any = await OrderSevices.sendInsuranceQuoteToCustomer(
  //       currentProposal.dealerId,
  //       currentProposal.id
  //     );
  //     if (response.success) {
  //       dispatch(
  //         UIActionCreators.snackbarManagement(
  //           'success',
  //           'Enviado para Cliente.'
  //         )
  //       );
  //     }
  //   } catch {
  //     dispatch(
  //       UIActionCreators.snackbarManagement(
  //         'error',
  //         'Desculpe, Ocorreu um erro inesperado.'
  //       )
  //     );
  //   } finally {
  //     setLoadings(prevState => ({
  //       ...prevState,
  //       callToClientIsLoading: false,
  //     }));
  //   }
  // };

  const handleClickCallToSpecialist = async () => {
    try {
      setLoadings(prevState => ({
        ...prevState,
        callToSpecialistIsLoading: true,
      }));
      const response: any = await OrderServices.sendInsuranceQuoteToSpecialist(
        currentProposal.dealerId,
        currentProposal.id
      );
      if (response.success) {
        dispatch(
          UIActionCreators.snackbarManagement(
            'success',
            'Enviado para especialista.'
          )
        );
      }
    } catch {
      dispatch(
        UIActionCreators.snackbarManagement(
          'error',
          'Desculpe, Ocorreu um erro inesperado.'
        )
      );
    } finally {
      setLoadings(prevState => ({
        ...prevState,
        callToSpecialistIsLoading: false,
      }));
    }
  };

  return (
    <Wrapper flex gap={16} width={'100%'} justify="end">
      {/* //***DISABLE_TEMPORALITY */}
      {/* <ThemeButton
        onClick={handleClickCallToClient}
        loading={loadings.callToClientIsLoading}
        disabled={loadings.callToClientIsLoading}
      >
        Enviar para cliente
      </ThemeButton> */}
      <ThemeButton
        onClick={handleClickCallToSpecialist}
        loading={loadings.callToSpecialistIsLoading}
        disabled={loadings.callToSpecialistIsLoading}
      >
        Enviar para especialista
      </ThemeButton>
      {/* //***DISABLE_TEMPORALITY */}
      {/* <ThemeButton onClick={() => setOpenModalFandi(true)}>
        Cotar agora
      </ThemeButton> */}
      {/* {openModalFandi && <Model />} */}
    </Wrapper>
  );
};

export default InsuranceQuote;
