import React from 'react';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import Chip from 'components/atoms/Chip';
import { Container, MessageCardTitle } from './styles';
interface Theme {
  id: number;
  message: string;
  category?: {
    id?: number;
    name: string;
    color?: string;
  };
  department?: {
    id?: number;
    name: string;
  };
  channel: string;
  systemic: boolean;
  whatsappTemplate: boolean;
}
interface ThemeProps {
  themeSelected: Theme;
  handleClick(theme: Theme): void;
  theme: Theme;
}

const ThemeCard: React.FC<ThemeProps> = ({
  themeSelected,
  handleClick,
  theme,
}) => {
  return (
    <Container>
      <div style={{ display: 'flex' }}>
        <RadioButton
          onClick={() =>
            handleClick(themeSelected.id === theme.id ? null : theme)
          }
          checked={themeSelected.id === theme.id}
        />
        <Tooltip title={theme.message}>
          <div className="textView">{theme.message}</div>
        </Tooltip>
      </div>
      <div style={{ display: 'flex' }}>
        {theme?.category?.name && (
          <>
            <MessageCardTitle color={theme?.category?.color}>
              {theme?.category?.name}
            </MessageCardTitle>
          </>
        )}
        {theme?.department?.name && <Chip>{theme?.department?.name}</Chip>}
      </div>
    </Container>
  );
};

export default ThemeCard;
