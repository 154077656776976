import React, { memo } from 'react';
import Box from '@material-ui/core/Box';
import Switch from 'components/atoms/Switch';
import ExpansionPanel from 'components/ExpansionPanel';
import ExpansionPanelTitle from 'components/atoms/ExpansionPanelTitle';
import PersonalInfosForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/PersonalInfosForm';
import PersonalInfoFullPayForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/PersonalInfosFullPayForm';
import AddressInfosForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/AddressInfosForm';
import ReferencesForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/ReferencesForm';
import OccupationForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/OccupationForm';
import ActionsFormButton from './components/ActionsFormButton';
import GuarantorInfosForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/GuarantorInfosForm';
import GuarantorAddressInfosForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/GuarantorAddressInfosForm';
import GuarantorOccupationForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/GuarantorOccupationForm';
import MaritalInfosForm from 'modules/financing/components/molecules/ClientCompleteDataForm/components/MaritalInfosForm';

const ClientDataPF = props => {
  const {
    buyerCurrentData,
    handleChangeFormValues,
    handleChangeFormStep,
    handleClickFinishedStep,
    formStep,
    isFinished,
    typeOfSale,
    handleClickFinishedInCashForm,
    guarantorCheckAndDisabledInputs,
    setGuarantorCheckAndDisabledInputs,
  } = props;

  //EXPANSIONS_TITLES
  const Client = <ExpansionPanelTitle title={'Dados do Cliente'} />;
  const Address = <ExpansionPanelTitle title={'Endereço'} />;
  const References = <ExpansionPanelTitle title={'Referências'} />;
  const Occupation = <ExpansionPanelTitle title={'Ocupação'} />;
  const Guarantor = (
    <ExpansionPanelTitle
      title={'Avalista'}
      extraButton={
        <Box component="div" display="flex" alignItems="center">
          <p>Desabilitar Avalista</p>
          <Switch
            onChange={() =>
              setGuarantorCheckAndDisabledInputs(
                !guarantorCheckAndDisabledInputs
              )
            }
            checked={guarantorCheckAndDisabledInputs}
          />
        </Box>
      }
    />
  );
  const Marital = <ExpansionPanelTitle title={'Dados do Cônjugue'} />;

  return (
    <div>
      {typeOfSale === 'IN_CASH' ? (
        <ExpansionPanel panel={{ title: Client }} startExpanded>
          <PersonalInfoFullPayForm
            formData={buyerCurrentData}
            onChange={handleChangeFormValues}
            disableAllInputs={isFinished}
            disableCpfCnpj
          />
          {!isFinished && (
            <ActionsFormButton
              currentForm="inCashPerson"
              handleChangeFormStep={() =>
                handleClickFinishedInCashForm('inCashPerson')
              }
              isLastStep
              enableNext={false}
              skipInputsCheck={isFinished}
            />
          )}
        </ExpansionPanel>
      ) : (
        <>
          <ExpansionPanel
            panel={{ title: Client }}
            openManual={
              isFinished ? null : formStep.clientFormOpen.isCurrentStep
            }
          >
            <PersonalInfosForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
              disableCpfCnpj
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="clientFormOpen"
                nextFormName={'addressFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
                skipInputsCheck={isFinished}
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: Address }}
            openManual={
              isFinished ? null : formStep.addressFormOpen.isCurrentStep
            }
          >
            <AddressInfosForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="addressFormOpen"
                nextFormName={'referencesFormOpen'}
                returnFormName={'clientFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
                skipInputsCheck={isFinished}
                enableReturn
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: References }}
            openManual={
              isFinished ? null : formStep.referencesFormOpen.isCurrentStep
            }
          >
            <ReferencesForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="referencesFormOpen"
                nextFormName={'occupationFormOpen'}
                returnFormName={'addressFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
                skipInputsCheck={isFinished}
                enableReturn
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: Occupation }}
            openManual={
              isFinished ? null : formStep.occupationFormOpen.isCurrentStep
            }
          >
            <OccupationForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={isFinished}
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="occupationFormOpen"
                returnFormName={'referencesFormOpen'}
                nextFormName={'guarantorFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
                skipInputsCheck={isFinished}
                enableReturn
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: Guarantor }}
            openManual={
              isFinished ? null : formStep.guarantorFormOpen.isCurrentStep
            }
          >
            <GuarantorInfosForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={guarantorCheckAndDisabledInputs || isFinished}
            />
            <div style={{ minHeight: 4 }} />
            <GuarantorAddressInfosForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={guarantorCheckAndDisabledInputs || isFinished}
            />
            <div style={{ minHeight: 4 }} />
            <GuarantorOccupationForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={guarantorCheckAndDisabledInputs || isFinished}
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="guarantorFormOpen"
                returnFormName={'occupationFormOpen'}
                nextFormName={'maritalInfosFormOpen'}
                handleChangeFormStep={handleChangeFormStep}
                skipInputsCheck={guarantorCheckAndDisabledInputs || isFinished}
                enableReturn
              />
            )}
          </ExpansionPanel>
          <div style={{ height: 16 }} />
          <ExpansionPanel
            panel={{ title: Marital }}
            openManual={
              isFinished ? null : formStep.maritalInfosFormOpen.isCurrentStep
            }
          >
            <MaritalInfosForm
              formData={buyerCurrentData}
              onChange={handleChangeFormValues}
              disableAllInputs={
                buyerCurrentData.civilStatus !== 'MARRIED' || isFinished
              }
            />
            {!isFinished && (
              <ActionsFormButton
                currentForm="maritalInfosFormOpen"
                returnFormName={'guarantorFormOpen'}
                handleChangeFormStep={handleClickFinishedStep}
                skipInputsCheck={isFinished}
                enableReturn
                isLastStep
                enableNext={false}
              />
            )}
          </ExpansionPanel>
        </>
      )}
    </div>
  );
};

export default memo(ClientDataPF);
