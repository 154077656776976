import isEmpty from 'helpers/isEmpty';

const checkClientFormData = (currentForm, currentData) => {
  if (currentForm === 'inCashCompany') {
    if (
      isEmpty(currentData.companyName) ||
      isEmpty(currentData.companySocialName) ||
      isEmpty(currentData.cnpj) ||
      isEmpty(currentData.companyStartDate) ||
      isEmpty(currentData.companyZipcode) ||
      isEmpty(currentData.companyStreetType) ||
      isEmpty(currentData.companyAddress) ||
      isEmpty(currentData.companyNumber) ||
      isEmpty(currentData.companyNeighborhood) ||
      isEmpty(currentData.companyCityStateId) ||
      isEmpty(currentData.companyCityId) ||
      isEmpty(currentData.companyEmail) ||
      isEmpty(currentData.companyPhone) ||
      isEmpty(currentData.companyInscricaoEstadual)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'inCashPerson') {
    if (
      isEmpty(currentData.name) ||
      isEmpty(currentData.email) ||
      isEmpty(currentData.phone) ||
      isEmpty(currentData.birthDate) ||
      isEmpty(currentData.cpf) ||
      isEmpty(currentData.nationality) ||
      isEmpty(currentData.gender) ||
      isEmpty(currentData.civilStatus) ||
      isEmpty(currentData.rgNumber) ||
      isEmpty(currentData.rgIssuedState) ||
      isEmpty(currentData.careerId) ||
      isEmpty(currentData.addressType) ||
      isEmpty(currentData.zipcode) ||
      isEmpty(currentData.streetType) ||
      isEmpty(currentData.address) ||
      isEmpty(currentData.number) ||
      isEmpty(currentData.neighborhood) ||
      isEmpty(currentData.cityStateId) ||
      isEmpty(currentData.cityId) ||
      isEmpty(currentData.schoolingType)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'employmentFormOpen') {
    if (
      isEmpty(currentData.companyName) ||
      isEmpty(currentData.companySocialName) ||
      isEmpty(currentData.cnpj) ||
      isEmpty(currentData.companyStartDate) ||
      isEmpty(currentData.accountantName) ||
      isEmpty(currentData.accountantPhone) ||
      isEmpty(currentData.companyZipcode) ||
      isEmpty(currentData.companyStreetType) ||
      isEmpty(currentData.companyAddress) ||
      isEmpty(currentData.companyNumber) ||
      isEmpty(currentData.companyNeighborhood) ||
      isEmpty(currentData.companyCityStateId) ||
      isEmpty(currentData.companyCityId) ||
      isEmpty(currentData.companyEmail) ||
      isEmpty(currentData.companyPhone) ||
      isEmpty(currentData.companyInscricaoEstadual)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'clientFormOpen') {
    if (
      isEmpty(currentData.name) ||
      isEmpty(currentData.birthDate) ||
      isEmpty(currentData.cpf) ||
      isEmpty(currentData.motherName) ||
      isEmpty(currentData.nationality) ||
      isEmpty(currentData.email) ||
      isEmpty(currentData.gender) ||
      isEmpty(currentData.phone) ||
      isEmpty(currentData.rgNumber) ||
      isEmpty(currentData.rgIssuer) ||
      isEmpty(currentData.rgIssuedState) ||
      isEmpty(currentData.rgIssuedAt) ||
      isEmpty(currentData.schoolingType)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'guarantorFormOpen') {
    if (
      isEmpty(currentData.guarantorName) ||
      isEmpty(currentData.guarantorBirthDate) ||
      isEmpty(currentData.guarantorCpf) ||
      isEmpty(currentData.guarantorMotherName) ||
      isEmpty(currentData.guarantorNationality) ||
      isEmpty(currentData.guarantorEmail) ||
      isEmpty(currentData.guarantorGender) ||
      isEmpty(currentData.guarantorPhone) ||
      isEmpty(currentData.guarantorRgNumber) ||
      isEmpty(currentData.guarantorRgIssuer) ||
      isEmpty(currentData.guarantorRgIssuedState) ||
      isEmpty(currentData.guarantorRgIssuedAt) ||
      isEmpty(currentData.guarantorEscolarityType)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'addressFormOpen') {
    if (
      isEmpty(currentData.addressType) ||
      isEmpty(currentData.zipcode) ||
      isEmpty(currentData.streetType) ||
      isEmpty(currentData.address) ||
      isEmpty(currentData.number) ||
      isEmpty(currentData.neighborhood) ||
      isEmpty(currentData.cityStateId) ||
      isEmpty(currentData.cityId)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'guarantorAddressFormOpen') {
    if (
      isEmpty(currentData.guarantorAddressType) ||
      isEmpty(currentData.guarantorZipcode) ||
      isEmpty(currentData.guarantorStreetType) ||
      isEmpty(currentData.guarantorAddress) ||
      isEmpty(currentData.guarantorNumber) ||
      isEmpty(currentData.guarantorNeighborhood) ||
      isEmpty(currentData.guarantorCityStateId) ||
      isEmpty(currentData.guarantorCityId)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'referencesFormOpen') {
    if (
      isEmpty(currentData.referenceBankId) ||
      isEmpty(currentData.referenceBankAccountType) ||
      isEmpty(currentData.referenceBankAgency) ||
      isEmpty(currentData.referenceBankAccount) ||
      isEmpty(currentData.referenceBankAgencyDigit) ||
      isEmpty(currentData.referenceName) ||
      isEmpty(currentData.referencePhone) ||
      isEmpty(currentData.referenceRelationship)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'guarantorReferencesFormOpen') {
    if (
      isEmpty(currentData.guarantorReferenceBankId) ||
      isEmpty(currentData.guarantorReferenceBankAccountType) ||
      isEmpty(currentData.guarantorReferenceBankAgency) ||
      isEmpty(currentData.guarantorReferenceBankAccount) ||
      isEmpty(currentData.guarantorReferenceBankAgencyDigit) ||
      isEmpty(currentData.guarantorReferenceName) ||
      isEmpty(currentData.guarantorReferencePhone) ||
      isEmpty(currentData.guarantorReferenceRelationship)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'occupationFormOpen') {
    if (
      (currentData.employmentId === 4 || currentData.employmentId === 8) &&
      !isEmpty(currentData.careerId)
    ) {
      return false;
    }
    if (
      isEmpty(currentData.employmentId) ||
      isEmpty(currentData.careerId) ||
      isEmpty(currentData.employmentSocialName) ||
      isEmpty(currentData.employmentStartDate) ||
      isEmpty(currentData.monthlyIncome) ||
      isEmpty(currentData.employmentZipcode) ||
      isEmpty(currentData.employmentStreetType) ||
      isEmpty(currentData.employmentNumber) ||
      isEmpty(currentData.employmentNeighborhood) ||
      isEmpty(currentData.employmentCityStateId) ||
      isEmpty(currentData.employerCompanyPhone) ||
      isEmpty(currentData.employmentCityId)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'maritalInfosFormOpen') {
    if (currentData.civilStatus !== 'MARRIED') {
      return false;
    }
    if (
      isEmpty(currentData.maritalPartnerName) ||
      isEmpty(currentData.maritalPartnerBirthDate) ||
      isEmpty(currentData.maritalPartnerRgNumber) ||
      isEmpty(currentData.maritalPartnerRgNumber) ||
      isEmpty(currentData.maritalPartnerRgIssuedState) ||
      isEmpty(currentData.maritalPartnerRgIssuedAt) ||
      isEmpty(currentData.maritalPartnerCivilStatus) ||
      isEmpty(currentData.maritalPartnerNationality) ||
      isEmpty(currentData.maritalRgIssuedState) ||
      isEmpty(currentData.maritalPartnerFatherName) ||
      isEmpty(currentData.maritalPartnerMotherName) ||
      isEmpty(currentData.maritalPartnerCompanyName) ||
      isEmpty(currentData.maritalPartnerJobTitle) ||
      isEmpty(currentData.maritalPartnerMonthlyIncome) ||
      isEmpty(currentData.maritalPartnerEmploymentId) ||
      isEmpty(currentData.maritalPartnerEmploymentStartDate) ||
      isEmpty(currentData.maritalPartnerZipcode) ||
      isEmpty(currentData.maritalPartnerAddress) ||
      isEmpty(currentData.maritalPartnerAddressNumber) ||
      isEmpty(currentData.maritalPartnerNeighborhood) ||
      isEmpty(currentData.maritalPartnerCityStateId) ||
      isEmpty(currentData.maritalPartnerCityId)
    ) {
      return true;
    }
    return false;
  }
  if (currentForm === 'guarantorOccupationFormOpen') {
    if (
      currentData.guarantorEmploymentId === 4 ||
      !isEmpty(currentData.guarantorCareerId)
    ) {
      return false;
    }
    if (
      isEmpty(currentData.guarantorEmploymentId) ||
      isEmpty(currentData.guarantorCareerId) ||
      isEmpty(currentData.guarantorEmploymentSocialName) ||
      isEmpty(currentData.guarantorEmploymentStartDate) ||
      isEmpty(currentData.guarantorMonthlyIncome) ||
      isEmpty(currentData.guarantorEmploymentZipcode) ||
      isEmpty(currentData.guarantorEmploymentStreetType) ||
      isEmpty(currentData.guarantorEmploymentNumber) ||
      isEmpty(currentData.guarantorEmploymentNeighborhood) ||
      isEmpty(currentData.guarantorEmploymentCityStateId) ||
      isEmpty(currentData.guarantorEmploymentCityId)
    ) {
      return true;
    }
    return false;
  }
  return true;
};

export default checkClientFormData;
