import React, { useEffect } from 'react';

import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import TextInput from 'modules/financing/components/atoms/TextField';
import DateFilter from 'components/atoms/DateFilter';
import ColumnFilter from './components/ColumnFilter';
import ColumnFilterCallCenter from 'pages/callCenter/tabs/Kanban/components/ProposalsFilter/ColumnFilter';
import NewProposalsButton from './components/NewProposalsButton';

import useGroupDepartments from './hooks/useGroupDepartments';
import useGroupTags from './hooks/useGroupTags';
import useGroupOrigins from './hooks/useGroupOrigins';
import useGroupChannels from './hooks/useGroupChannels';
import useGroupSalesAssistant from './hooks/useGroupSalesAssistant';
import useGroupDealers from './hooks/useGroupDealers';
import PopoverFilters from '../../../PopoverFilters';

const CHANNELS_API_PROPERTY = 'providers';
const DEPARTMENT_API_PROPERTY = 'departmentCode';
const ORIGINS_API_PROPERTY = 'origin';
const SALES_ASSISTANT_API_PROPERTY = 'salesAssistantId';
const TAGS_API_PROPERTY = 'tags';
const DEALERS_API_PROPERTY = 'dealerId';

export default function ProposalFiltersComponent(props) {
  const {
    periodInit,
    periodFinal,
    tags,
    origin,
    search,
    channels,
    departmentCode,
    hasNewProposals,
    someUnreadMessages,
    setSomeUnreadMessages,
    handleOnClickToSearch,
    handleOnChangePeriod,
    handleChangeSearch,
    handleChangeDepartment,
    handleChangeChannel,
    handleChangeOrigin,
    handleChangeTags,
    handleChangeSalesAssistant,
    handleClickGetNewProposals,
    handleClearAllFilters,
    userStore,
    tasks,
    salesAssistantId,
    dealers,
    handleChangeDealers,
    isCallCenter,
    periodId,
  } = props;

  const {
    getGroupDealers,
    options: dealersOptions,
    selectedOptions: selectedDealers,
  } = useGroupDealers({
    apiProperty: DEALERS_API_PROPERTY,
    selectedsValues: dealers || [],
  });

  const {
    getGroupChannels,
    options: channelOptions,
    selectedOptions: selectedChannels,
  } = useGroupChannels({
    apiProperty: CHANNELS_API_PROPERTY,
    selectedsValues: channels || [],
  });

  const {
    getGroupDepartment,
    options: departmentOptions,
  } = useGroupDepartments({
    apiProperty: DEPARTMENT_API_PROPERTY,
    selectedValue: departmentCode,
  });

  const {
    getGroupOrigins,
    options: originsOptions,
    selectedOptions: selectedOrigins,
  } = useGroupOrigins({
    apiProperty: ORIGINS_API_PROPERTY,
    selectedsValues: origin || [],
  });

  const {
    getGroupSalesAssistant,
    options: salesAssistantOptions,
    selectedOption: selectedSalesAssistant,
  } = useGroupSalesAssistant({
    apiProperty: SALES_ASSISTANT_API_PROPERTY,
    selectedValue: salesAssistantId,
  });

  const {
    getGroupTags,
    options: tagsOptions,
    selectedOptions: selectedTags,
  } = useGroupTags({
    apiProperty: TAGS_API_PROPERTY,
    selectedsValues: tags || [],
  });

  const triggerActions = filters => {
    if (!filters?.length) return handleClearAllFilters();

    return filters.forEach(filter => {
      const formatted = filter?.values?.join(',');
      switch (filter?.filterGroupId) {
        case DEALERS_API_PROPERTY:
          return handleChangeDealers(filter?.values);
        case CHANNELS_API_PROPERTY:
          return handleChangeChannel(filter?.values);
        case DEPARTMENT_API_PROPERTY:
          return handleChangeDepartment(formatted);
        case ORIGINS_API_PROPERTY:
          return handleChangeOrigin(filter?.values);
        case SALES_ASSISTANT_API_PROPERTY:
          return handleChangeSalesAssistant(formatted);
        case TAGS_API_PROPERTY:
          return handleChangeTags(filter?.values);
        default:
          return;
      }
    });
  };

  useEffect(() => {
    getGroupTags({});
    getGroupChannels({});
    getGroupDepartment({});
    getGroupOrigins({});
    getGroupSalesAssistant({});
    getGroupDealers({});
  }, []);

  const AllFilters = [
    {
      type: 'checkbox',
      title: 'Lojas',
      apiProperty: DEALERS_API_PROPERTY,
      options: dealersOptions,
      selectedOptions: selectedDealers || null,
    },
    {
      type: 'radio',
      title: 'Departamento',
      apiProperty: DEPARTMENT_API_PROPERTY,
      options: departmentOptions,
      selectedOptions:
        [departmentOptions.find(option => option.value === departmentCode)] ||
        null,
      disabled: Boolean(userStore?.department?.code),
    },
    {
      type: 'checkbox',
      title: 'Tags',
      apiProperty: TAGS_API_PROPERTY,
      options: tagsOptions,
      selectedOptions: selectedTags || null,
    },
    {
      type: 'checkbox',
      title: 'Origem',
      apiProperty: ORIGINS_API_PROPERTY,
      options: originsOptions,
      selectedOptions: selectedOrigins || null,
    },
    {
      type: 'checkbox',
      title: 'Canal',
      apiProperty: CHANNELS_API_PROPERTY,
      options: channelOptions,
      selectedOptions: selectedChannels || null,
    },
    {
      type: 'radio',
      title: 'Assistente de Vendas',
      apiProperty: SALES_ASSISTANT_API_PROPERTY,
      options: salesAssistantOptions,
      selectedOptions: selectedSalesAssistant ? [selectedSalesAssistant] : null,
    },
  ];

  return (
    <PopoverFilters
      popoverId="proposals-kanban"
      onSelect={filters => triggerActions(filters)}
      filters={AllFilters}
      isSimple={props?.isSimple}
      otherElements={
        props?.otherElements ?? (
          <>
            <TextInput
              mini
              name="search"
              label="Busca"
              value={search}
              placeholder="nome, telefone ou placa"
              onChange={e =>
                handleChangeSearch({
                  target: { name: 'search', value: e.target.value },
                })
              }
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  handleOnClickToSearch();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Clique para buscar"
                      onClick={handleOnClickToSearch}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {hasNewProposals && (
              <NewProposalsButton onClick={handleClickGetNewProposals} />
            )}
            {isCallCenter ? (
              <ColumnFilterCallCenter
                someUnreadMessages={someUnreadMessages}
                setSomeUnreadMessages={setSomeUnreadMessages}
                tasks={tasks}
              />
            ) : (
              <ColumnFilter
                someUnreadMessages={someUnreadMessages}
                setSomeUnreadMessages={setSomeUnreadMessages}
                tasks={tasks}
              />
            )}
            <DateFilter
              currentFilterSelected={periodId}
              currentStartDate={periodInit}
              currentEndDate={periodFinal}
              handleClickFilter={dates =>
                handleOnChangePeriod({
                  periodId: dates.filterType,
                  initialDate: dates.initialDateTimeStemp,
                  finalDate: dates.finalDateTimeStemp,
                })
              }
            />
          </>
        )
      }
    />
  );
}
