import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionCreators from 'modules/resale/store/reducers/leads/actionCreators';
import ProposalFiltersComponent from './ProposalFiltersComponent';

const ProposalFiltersContainer = props => {
  const dispatch = useDispatch();

  const {
    origin,
    tags,
    channels,
    userStore,
    filterByDates,
    departmentCode,
    sellerId,
  } = useSelector(state => ({
    origin: state.leads.origin,
    tags: state.leads.tags,
    userStore: state.userStore,
    channels: state.leads.channel,
    filterByDates: state.leads.filterByDates,
    departmentCode: state.leads.departmentCode,
    sellerId: state.leads.sellerId,
  }));

  const [state, setState] = useState({
    overviewFilter: null,
  });

  const getQueryFilter = () => {
    let queryFilter = props.history?.location?.search
      ? props.history.location.search.split('&')
      : null;

    if (!queryFilter) return null;

    queryFilter.shift();

    if (!queryFilter?.length) return null;

    queryFilter = queryFilter?.[0]?.split('=');

    const filter = {
      filterByNewLeadDelayed: 'Leads Novos Atrasados',
      filterByInactiveLead: 'Leads inativos em negociação',
      filterByScheduledDelayed: 'Agendamentos atrasados',
      filterByCall: 'Total de Ligações',
      filterByLeadWithSold: 'Vendas',
    };

    setState(prevState => ({
      ...prevState,
      overviewFilter: {
        name: filter?.[queryFilter[0]],
        filterName: queryFilter[0],
      },
    }));

    return queryFilter;
  };

  const deleteQuery = () => {
    const history = props.history;
    const queryParams = new URLSearchParams(history.location?.search);

    queryParams.delete(state.overviewFilter?.filterName);

    history.replace({ search: 'tab=1' });

    setState(prevState => ({
      ...prevState,
      overviewFilter: null,
    }));

    props.getProposals();
  };

  const handleOnChangePeriod = newDates => {
    dispatch(ActionCreators.setFilterDate(newDates));
  };

  const handleChangeDepartment = newValue => {
    dispatch(ActionCreators.setDepartment(newValue));
  };

  const handleChangeChannel = chanelArrValue => {
    dispatch(ActionCreators.setChannel(chanelArrValue));
  };

  const handleChangeOrigin = originArrValue => {
    dispatch(ActionCreators.setOrigin(originArrValue));
  };

  const handleChangeTags = tagArrValue => {
    dispatch(ActionCreators.setTags(tagArrValue));
  };

  const handleChangeSellerId = sellerId => {
    dispatch(ActionCreators.setSellerId(sellerId));
  };

  const handleOnClickToSearch = () => {
    props.getProposals(props.filters.search);
  };

  const handleClickGetNewProposals = () => {
    props.getProposals();
  };

  const handleClearAllFilters = () => {
    props.getProposals(null, false, true);
  };

  useEffect(() => {
    getQueryFilter();
  }, []);

  const viewComponentProps = {
    channels,
    deleteQuery,
    departmentCode,
    handleChangeChannel,
    handleChangeDepartment,
    handleChangeFilter: props.handleChangeFilter,
    handleChangeOrigin,
    handleChangeSellerId,
    handleChangeTags,
    handleClickGetNewProposals,
    handleOnChangePeriod,
    handleOnClickToSearch,
    handleClearAllFilters,
    hasNewProposals: props.hasNewProposals,
    open: state.open,
    origin: origin,
    overviewFilter: state.overviewFilter,
    periodFinal: filterByDates.finalDate,
    periodInit: filterByDates.initialDate,
    periodId: filterByDates.periodId,
    search: props.filters.search,
    sellerId,
    setSomeUnreadMessages: props.setSomeUnreadMessages,
    someUnreadMessages: props.someUnreadMessages,
    tags,
    tasks: props.tasks,
    userStore,
    isSimple: props?.isSimple,
    otherElements: props?.otherElements,
  };

  return <ProposalFiltersComponent {...viewComponentProps} />;
};

export default React.memo(ProposalFiltersContainer);
