/* eslint-disable @typescript-eslint/interface-name-prefix */
import React from 'react';
import ModalEvaluationStoryInventoryComponent from './ModalEvaluationStoryInventoryComponent';

import routesPath from 'config/routesPath';
import { useFormik } from 'formik';
import { plateFormat } from 'helpers/plateFormat';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InventoryServices from 'services/inventoryServices';
import * as Yup from 'yup';

export interface IEvaluation {
  evaluationStatus?: string;
  id: number;
  stock: boolean;
  isEvaluation: boolean;
  showroom: boolean;
  resale: boolean;
}

const ModalEvaluationStoryInventoryContainer = ({
  open,
  onClose,
  dealTypeSelect = null,
}) => {
  const {
    currentDealer: { id },
  } = useSelector(state => state?.dealerStore);

  const history = useHistory();

  const plateRegex = /[a-zA-z]{3}[0-9][0-9A-Z][0-9]{2}/;
  const vinRegex = /(?=.*\d|=.*[A-Z])(?=.*[A-Z])[A-Z0-9]{17}/;

  const EVALUATION_STATUS = {
    PENDING: 'PENDING',
    EVALUATED: 'EVALUATED',
    FINISHED: 'FINISHED',
  };

  const EVALUATION_MESSAGE = {
    PENDING:
      'Esse veículo já possui uma avaliação pendente no sistema. Aguarde a avaliação ser feita para seguir com a inclusão.',
    EVALUATED: 'Esse veículo já possui uma avaliação em andamento no sistema.',
    FINISHED:
      'O veículo possui Informações de cadastro pendentes, continue para complementar os dados.',
  };

  const DEAL_MESSAGE = {
    SHOWROOM: 'O veículo já pertence ao seu Showroom.',
    REPASSE: 'O veículo já pertence ao seu Repasse.',
  };

  const schema = Yup.object().shape(
    {
      plateOrVin: Yup.string()
        .when('plateOrVin', {
          is: value => {
            return plateRegex.test(value);
          },
          then: Yup.string().matches(plateRegex, 'Placa ou Chassi inválido.'),
          otherwise: Yup.string().matches(
            vinRegex,
            'Placa ou Chassi Inválido.'
          ),
        })
        .required('É necessário informar a placa ou Chassi'),
    },
    [['plateOrVin', 'plateOrVin']]
  );

  const [evaluationStatus, setEvaluation] = React.useState<IEvaluation>();
  const [evaluationSuccess, setEvaluationSuccess] = React.useState<boolean>(
    true
  );

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleEvaluationStatus = async (plate: string) => {
    try {
      setIsLoading(true);
      const {
        success,
        data,
      }: any = await InventoryServices.getInventorySituation(id, plate);
      if (success) {
        setEvaluation(data);
        setEvaluationSuccess(success);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { plateOrVin: '' },
    validationSchema: schema,
    onSubmit: values => {
      handleEvaluationStatus(values.plateOrVin);
    },
  });

  const handlePushToScreen = () => {
    onClose();

    if (dealTypeSelect?.value == 'showroom') {
      history.push(
        `${routesPath.getRecursivePath(routesPath.dealForm)}?plate=${
          formik?.values?.plateOrVin
        }`
      );
      return;
    }

    if (dealTypeSelect?.value == 'resale') {
      history.push(
        `${routesPath.getRecursivePath(routesPath.resaleDealForm)}?plate=${
          formik?.values?.plateOrVin
        }`
      );
      return;
    }

    if (plateRegex.test(formik.values.plateOrVin)) {
      history.push(
        `${routesPath.getRecursivePath(
          routesPath.mobiOneStoreInvetoryAddVehicle
        )}?plate=${formik?.values?.plateOrVin}`
      );
      return;
    }

    history.push(
      `${routesPath.getRecursivePath(
        routesPath.mobiOneStoreInvetoryAddVehicle
      )}`
    );
  };

  const handlePushToScreenEditVehicle = () => {
    if (dealTypeSelect?.value == 'showroom') {
      history.push(
        `${routesPath.getRecursivePath(routesPath.updateDeal, {
          id: evaluationStatus.id,
        })}`
      );
      return;
    }

    if (dealTypeSelect?.value == 'resale') {
      history.push(
        `${routesPath.getRecursivePath(routesPath.resaleDeaFormEdit, {
          dealId: evaluationStatus.id,
        })}`
      );
      return;
    }

    history.push(
      `${routesPath.getRecursivePath(
        routesPath.mobiOneStoreInvetoryEditVehicle,
        { id: evaluationStatus.id }
      )}`
    );
  };

  const onChange = (plateOrVin: string, value: string) => {
    formik.setFieldValue('plateOrVin', plateFormat(value));
  };

  const onBlur = () => {
    formik.handleBlur('plateOrVin');
  };

  const handleCloseModal = () => {
    onClose();
    formik.resetForm();
    setEvaluation(null);
  };

  React.useEffect(() => {
    if (
      plateRegex.test(formik.values.plateOrVin) ||
      vinRegex.test(formik.values.plateOrVin)
    ) {
      handleEvaluationStatus(formik.values.plateOrVin);
    }
  }, [formik.values.plateOrVin]);

  return (
    <ModalEvaluationStoryInventoryComponent
      open={open}
      onClose={handleCloseModal}
      evaluationStatus={evaluationStatus}
      isLoading={isLoading}
      values={formik.values}
      touched={formik.touched}
      errors={formik.errors}
      onChange={onChange}
      handleSubmit={formik.handleSubmit}
      onBlur={onBlur}
      EVALUATION_STATUS={EVALUATION_STATUS}
      EVALUATION_MESSAGE={EVALUATION_MESSAGE}
      handlePushToScreen={handlePushToScreen}
      handlePushToScreenEditVehicle={handlePushToScreenEditVehicle}
      evaluationSuccess={evaluationSuccess}
      DEAL_MESSAGE={DEAL_MESSAGE}
      dealTypeSelect={dealTypeSelect}
    />
  );
};

export default ModalEvaluationStoryInventoryContainer;
