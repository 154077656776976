import Colors from 'bit/mobiauto.web-ui-components.components.colors/colors';

export const typeColors = {
  REFUSED: {
    color: Colors.dangerous.medium,
    backgroundColor: '#FEECEB',
    label: 'Recusado',
    type: 'low',
    value: 'REFUSED',
  },
  APPROVED: {
    color: Colors.primary.medium,
    backgroundColor: '#E0F3F1',
    label: 'Aprovado',
    type: 'high',
    value: 'APPROVED',
  },
  IN_ANALYSE: {
    color: Colors.warning.medium,
    backgroundColor: '#FFF5E5',
    label: 'Em Análise',
    type: 'medium',
    value: 'IN_ANALYSE',
  },
  NOT_STARTED: {
    color: '#BDBDBD',
    backgroundColor: '#F5F5F5',
    label: 'Não enviado para aprovação',
    type: 'default',
    value: 'NOT_STARTED',
  },
};
