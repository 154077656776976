/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import AutoComplete from 'components/atoms/AutoComplete';
import { Option } from 'react-select';
import NumberFormat from 'react-number-format';
import { FieldType, AutocompleteType } from '../PricingVehicleTypes';
import { Tooltip, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { QueryObserverResult } from 'react-query';
import * as S from '../styles';
import useFormHelpers from 'hooks/useFormHelpers';
import { plateFormat } from 'helpers/plateFormat';
import { usePricingVehicleContext } from '../PricingVehicleContext';
import formatModelYearsToSelect from 'helpers/formatModelYearsToSelect';
import { useGetPricingVehicleStaticOptions } from '../hooks/useGetPricingVehicleStaticOptions';
import { useGetPricingVehicleDynamicOptions } from '../hooks/useGetPricingVehicleDynamicOptions';
import { Skeleton } from '@material-ui/lab';

const doors = [
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
];

interface FormData {
  loading: boolean;
  dealerBelongsToSagaGroup: boolean;
  handleOnChangeValue: ({
    field,
    newValue,
  }: {
    field: FieldType;
    newValue: any;
  }) => void;
}

const Form = ({
  loading,
  dealerBelongsToSagaGroup,
  handleOnChangeValue,
}: FormData) => {
  const { deal, pricingVehicleFormData } = usePricingVehicleContext();

  const [
    { data: colors, isLoading: isLoadingGetColors },
    { data: fuels, isLoading: isLoadingGetFuels },
    { data: transmissions, isLoading: isLoadingGetTransmissions },
  ] = useGetPricingVehicleStaticOptions();

  const {
    values,
    touched,
    errors,
    setValues,
    handleBlur,
  } = pricingVehicleFormData;

  const [modelYears, setModelYears] = React.useState<AutocompleteType[]>([]);

  const { handleError, handleHelper } = useFormHelpers(values, touched, errors);
  const [
    { data: makes },
    { data: plateData, refetch: refetchGetVehicleByPlate },
    { data: models, isLoading: isLoadingGetModels },
    { data: productionYears, isLoading: isLoadingGetProductionYears },
    { data: trims, isLoading: isLoadingGetTrims },
  ]: QueryObserverResult<any>[] = useGetPricingVehicleDynamicOptions();

  const handleChangePlate = (target: EventTarget & HTMLInputElement) => {
    setValues({
      ...values,
      plate: plateFormat(target?.value),
    });
  };

  const getModelYears = React.useCallback(() => {
    if (values?.productionYear) {
      const newModelYears: AutocompleteType[] = formatModelYearsToSelect(
        productionYears,
        values?.productionYear?.value
      ).map(modelYear => ({
        value: modelYear.value as number,
        label: modelYear.label as string,
      }));

      const selectedModelYear = newModelYears.find(
        newModelYear => newModelYear.value === plateData?.data?.modelYear
      );

      setModelYears(newModelYears);

      setValues({
        ...values,
        modelYear: selectedModelYear || null,
      });
    }
  }, [values?.productionYear]);

  React.useEffect(() => {
    getModelYears();
  }, [getModelYears]);

  return (
    <Grid container spacing={2}>
      {loading ? (
        <>
          {Array.from({ length: 8 }, (_, i) => (
            <Grid item xs={6} key={i}>
              <Skeleton width="100%" height="48px" variant="rect" />
            </Grid>
          ))}
        </>
      ) : (
        <>
          {!deal?.deal0km && (
            <Grid item xs={6}>
              <Tooltip title="Coloque a placa do veículo e preencha automaticamente as informações abaixo">
                <S.CustomTextField
                  mini
                  data-test="deal-plate-search"
                  label="Placa*"
                  name="plate"
                  value={values?.plate}
                  error={handleError('plate')}
                  helperText={handleHelper('plate')}
                  onChange={({ target }) => handleChangePlate(target)}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          type="button"
                          aria-label="Click para buscar"
                          onClick={() => refetchGetVehicleByPlate()}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 7,
                  }}
                />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={6}>
            <AutoComplete
              name="make"
              label="Marca*"
              options={makes}
              isClearable={false}
              disabled={!makes?.length || loading}
              value={values?.make}
              error={handleError('make')}
              helperText={handleHelper('make')}
              onChange={newValue => {
                handleOnChangeValue({
                  field: 'make',
                  newValue,
                });
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoComplete
              name="model"
              label="Modelo*"
              options={models}
              isClearable={false}
              disabled={!models?.length || isLoadingGetModels}
              value={values?.model}
              error={handleError('model')}
              helperText={handleHelper('model')}
              onChange={(newValue: Option | null) => {
                handleOnChangeValue({
                  field: 'model',
                  newValue,
                });
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoComplete
              name="productionYear"
              label="Ano de fabricação*"
              options={productionYears}
              disabled={!productionYears?.length || isLoadingGetProductionYears}
              value={values?.productionYear}
              error={handleError('productionYear')}
              helperText={handleHelper('productionYear')}
              onChange={(newValue: Option | null) => {
                handleOnChangeValue({
                  field: 'productionYear',
                  newValue,
                });
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoComplete
              name="modelYear"
              label="Ano de modelo*"
              options={modelYears}
              disabled={!modelYears?.length || loading}
              value={values?.modelYear}
              error={handleError('modelYear')}
              helperText={handleHelper('modelYear')}
              onChange={(newValue: Option | null) => {
                handleOnChangeValue({
                  field: 'modelYear',
                  newValue,
                });
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={6}>
            <AutoComplete
              name="trim"
              label="Versão*"
              options={trims}
              disabled={!trims?.length || isLoadingGetTrims}
              value={values?.trim}
              error={handleError('trim')}
              helperText={handleHelper('trim')}
              onChange={(newValue: Option | null) =>
                handleOnChangeValue({
                  field: 'trim',
                  newValue,
                })
              }
            />
          </Grid>
          {dealerBelongsToSagaGroup ? null : (
            <>
              <Grid item xs={6}>
                <AutoComplete
                  name="doors"
                  label="Portas"
                  options={doors}
                  disabled={!doors?.length}
                  value={values?.doors}
                  onChange={(newValue: Option | null) => {
                    handleOnChangeValue({
                      field: 'doors',
                      newValue,
                    });
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <AutoComplete
                  label="Cor"
                  name="color"
                  options={colors}
                  disabled={!colors?.length || isLoadingGetColors}
                  value={values?.color}
                  onChange={(newValue: Option | null) => {
                    handleOnChangeValue({
                      field: 'color',
                      newValue,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <AutoComplete
                  name="transmission"
                  label="Transmissão"
                  options={transmissions}
                  disabled={!transmissions?.length || isLoadingGetTransmissions}
                  value={values?.transmission}
                  onChange={(newValue: Option | null) => {
                    handleOnChangeValue({
                      field: 'transmission',
                      newValue,
                    });
                  }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <AutoComplete
              name="fuel"
              label="Combustível"
              options={fuels}
              disabled={!fuels?.length || isLoadingGetFuels}
              value={values.fuel}
              onChange={(newValue: Option | null) => {
                handleOnChangeValue({
                  field: 'fuel',
                  newValue,
                });
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <NumberFormat
              label="Quilometragem*"
              value={values.km}
              name="km"
              error={handleError('km')}
              helperText={handleHelper('km')}
              onValueChange={values => {
                handleOnChangeValue({
                  field: 'km',
                  newValue: values.floatValue,
                });
              }}
              thousandSeparator="."
              decimalSeparator=","
              allowNegative={false}
              placeholder="Quilometragem"
              customInput={S.KmField}
              disabled={loading}
            />
          </Grid>
          {dealerBelongsToSagaGroup && (
            <>
              <Grid item xs={6}>
                <S.CustomTextField
                  mini
                  data-test="deal-plate-search"
                  label="Chassi*"
                  name="vin"
                  value={values?.vin}
                  error={handleError('vin')}
                  helperText={handleHelper('vin')}
                  onChange={e => {
                    setValues({
                      ...values,
                      vin: e?.target?.value,
                    });
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={6}>
                <S.CustomTextField
                  mini
                  label="Renavam"
                  name="renavam"
                  value={values?.renavam}
                  onChange={e => {
                    setValues({
                      ...values,
                      renavam: e?.target?.value,
                    });
                  }}
                  onBlur={handleBlur}
                />
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default Form;
