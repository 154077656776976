import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import CallCenterServices from 'services/callCenterServices';
import ActionTypes from './actionTypes';
import ActionCreators from './actionCreators';
import { DealerFromStore } from 'components/withDealer';
import SearchAndRescueServices from 'services/searchAndRescueCenterServices';

function* getAllProposalsByGroup(action: any) {
  try {
    yield put(ActionCreators.setAllProposalsbyGroupLoading(true));

    let currentGroupId = yield select(
      select => select.searchAndRescueCenter.currentGroup.id
    );

    if (!currentGroupId) {
      currentGroupId = DealerFromStore.getDealerGroupFromLocalStorage()?.id;
    }

    if (!currentGroupId) {
      const groupList = yield call(CallCenterServices.getDealerGroups, {
        page: 0,
        size: 1,
        q: null,
      });
      currentGroupId = groupList.data.content[0].id;
    }

    const {
      periodInit,
      periodFinal,
      query,
      deal0km,
      salesAssistantId,
      dealerId,
      departmentCode,
      origin,
      providerId,
      tags,
    } = yield select(select => select.callCenter.filters);

    const response = yield call(
      SearchAndRescueServices.getAllProposalsByGroup,
      currentGroupId,
      {
        deal0km,
        dateInitial: periodInit,
        dateFinal: periodFinal,
        salesAssistantId: salesAssistantId ? salesAssistantId : null,
        query,
        dealers: dealerId?.length ? String(dealerId.map(item => item)) : null,
        departmentCode: departmentCode ? departmentCode : null,
        origins: origin?.length
          ? String(origin.map(item => item?.value))
          : null,
        providers: providerId?.length
          ? String(providerId.map(item => item?.value))
          : null,
        tags: tags?.length ? String(tags.map(item => item)) : null,
      }
    );

    if (response.success) {
      yield put(ActionCreators.setAllProposalsbyGroup(response.data));
      yield put(ActionCreators.setAllProposalsbyGroupLoading(false));
      return;
    } else {
      yield put(ActionCreators.setAllProposalsbyGroupError(true));
      yield put(ActionCreators.setAllProposalsbyGroupLoading(false));
    }
  } catch (e) {
    yield put(ActionCreators.setAllProposalsbyGroupError(true));
    yield put(ActionCreators.setAllProposalsbyGroupLoading(false));
    console.log(e);
  }
}

export default all([
  takeLatest(ActionTypes.GET_ALL_PROPOSALS_BY_GROUP, getAllProposalsByGroup),
]);
