import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import envVars from 'helpers/envVars';
import request from 'helpers/request';
import buildHTTPResponseHandler from 'helpers/buildHTTPResponseHandler';
import UIActionCreators from 'modules/resale/store/reducers/ui/actionCreators';

import { FilterOption } from '../interfaces';
import useCustomMutation from './useCustomMutation';

interface Props {
  apiProperty: string;
  selectedValue?: string;
}

const domain = envVars('API_PAINEL_DIGITAL_BASE_URL');
const serviceName = 'DealerServices';

export default function useSellers({ apiProperty, selectedValue }: Props) {
  const [options, setOptions] = useState<FilterOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<FilterOption | null>(
    null
  );

  const dispatch = useDispatch();
  const { currentDealer } = useSelector(state => ({
    currentDealer: state.dealerStore.currentDealer,
  }));

  const dealerId = currentDealer.id;

  function useGetSellers() {
    return useCustomMutation({
      key: 'getSellers',
      handleReturn: {
        onError: () => {
          dispatch(
            UIActionCreators.snackbarManagement(
              'error',
              'Ocorreu um erro ao consultar "Vendedor".'
            )
          );
        },
      },
      request: async ({ proposalId }: { proposalId?: string }) => {
        const response = await request
          .get(
            `${domain}/dealer/v1.0/${dealerId}/sellers${
              proposalId ? `?proposalId=${proposalId}` : ''
            }`
          )
          .then(response => {
            buildHTTPResponseHandler(`${serviceName}.getSellers()`);
            return response?.data as any;
          });
        return response;
      },
    });
  }

  const { data, isLoading, mutate: getSellers } = useGetSellers();

  useEffect(() => {
    if (options?.length) {
      const selected = options.find(option => option.value === selectedValue);
      setSelectedOption(selected);
    }
  }, [options, selectedValue]);

  useEffect(() => {
    if (data) {
      const formatted: FilterOption[] = data?.map(el => ({
        filterGroupId: apiProperty,
        id: el?.userSite?.id,
        label: el?.userSite?.name,
        value: String(el?.userSite?.id),
      }));
      setOptions(formatted);
    }
  }, [data]);

  return {
    options,
    selectedOption,
    getSellers,
    isLoading,
  };
}
