import ProposalsListServices from 'modules/resale/services/ProposalsListServices';
import ProposalListCorporativeSaleServices from 'modules/resale/services/ProposalListCorporativeSaleServices';
import NegotiationServices from 'modules/resale/services/NegotiationServices';
import NegotiationCorporativeSaleServices from 'modules/resale/services/NegotiationCorporativeSaleServices';

export const PORTAL_COLABORADOR_BANCOPAN_DEALER_ID = 83561;

export function ProposalServicesToggleEndpoints(dealerId) {
  return Number(dealerId) === PORTAL_COLABORADOR_BANCOPAN_DEALER_ID
    ? ProposalListCorporativeSaleServices
    : ProposalsListServices;
}

export function NegotiationServicesToggleEndpoints(dealerId) {
  return Number(dealerId) === PORTAL_COLABORADOR_BANCOPAN_DEALER_ID
    ? NegotiationCorporativeSaleServices
    : NegotiationServices;
}
